import { Ether, Token, WETH9 } from "@uniswap/sdk-core";
import { WrappedTokenInfo } from "state/lists/wrappedTokenInfo";

import { UNI_ADDRESS } from "./addresses";
import { SupportedChainId } from "./chains";

const createToken = (
	chainId: number,
	address: string,
	decimals: number,
	symbol: string,
	name: string,
	iconUrl: string
): Token => {
	return new WrappedTokenInfo(
		{
			chainId,
			address,
			decimals,
			symbol,
			name,
			logoURI: iconUrl,
		},
		{
			name: "",
			timestamp: Date.now().toString(),
			tokens: [],
			version: {
				major: 1,
				minor: 0,
				patch: 0,
			},
		}
	).wrapped;
};

export const AMPL = new Token(
	SupportedChainId.MAINNET,
	"0xD46bA6D942050d489DBd938a2C909A5d5039A161",
	9,
	"AMPL",
	"Ampleforth"
);
export const DAI = new Token(
	SupportedChainId.MAINNET,
	"0x6B175474E89094C44Da98b954EedeAC495271d0F",
	18,
	"DAI",
	"Dai Stablecoin"
);
export const DAI_ARBITRUM_ONE = new Token(
	SupportedChainId.ARBITRUM_ONE,
	"0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1",
	18,
	"DAI",
	"Dai stable coin"
);
export const DAI_OPTIMISM = createToken(
	SupportedChainId.OPTIMISM,
	"0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1",
	18,
	"DAI",
	"Dai stable coin",
	"https://cryptologos.cc/logos/multi-collateral-dai-dai-logo.svg?v=010"
);
export const USDC = new Token(
	SupportedChainId.MAINNET,
	"0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
	6,
	"USDC",
	"USD//C"
);
export const USDC_ARBITRUM = new Token(
	SupportedChainId.ARBITRUM_ONE,
	"0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8",
	6,
	"USDC",
	"USD//C"
);
export const USDC_OPTIMISM = createToken(
	SupportedChainId.OPTIMISM,
	"0x7F5c764cBc14f9669B88837ca1490cCa17c31607",
	6,
	"USDC",
	"USD//C",
	"https://ethereum-optimism.github.io/logos/USDC.svg"
);
export const USDT = new Token(
	SupportedChainId.MAINNET,
	"0xdAC17F958D2ee523a2206206994597C13D831ec7",
	6,
	"USDT",
	"Tether USD"
);
export const USDT_ARBITRUM_ONE = new Token(
	SupportedChainId.ARBITRUM_ONE,
	"0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",
	6,
	"USDT",
	"Tether USD"
);
export const USDT_OPTIMISM = new Token(
	SupportedChainId.OPTIMISM,
	"0x94b008aA00579c1307B0EF2c499aD98a8ce58e58",
	6,
	"USDT",
	"Tether USD"
);
export const WBTC = new Token(
	SupportedChainId.MAINNET,
	"0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599",
	8,
	"WBTC",
	"Wrapped BTC"
);
export const WBTC_ARBITRUM_ONE = new Token(
	SupportedChainId.ARBITRUM_ONE,
	"0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f",
	8,
	"WBTC",
	"Wrapped BTC"
);
export const WBTC_OPTIMISM = createToken(
	SupportedChainId.OPTIMISM,
	"0x68f180fcCe6836688e9084f035309E29Bf0A2095",
	8,
	"WBTC",
	"Wrapped BTC",
	"https://ethereum-optimism.github.io/logos/WBTC.svg"
);
export const FEI = new Token(
	SupportedChainId.MAINNET,
	"0x956F47F50A910163D8BF957Cf5846D573E7f87CA",
	18,
	"FEI",
	"Fei USD"
);
export const TRIBE = new Token(
	SupportedChainId.MAINNET,
	"0xc7283b66Eb1EB5FB86327f08e1B5816b0720212B",
	18,
	"TRIBE",
	"Tribe"
);
export const FRAX = new Token(
	SupportedChainId.MAINNET,
	"0x853d955aCEf822Db058eb8505911ED77F175b99e",
	18,
	"FRAX",
	"Frax"
);
export const FXS = new Token(
	SupportedChainId.MAINNET,
	"0x3432B6A60D23Ca0dFCa7761B7ab56459D9C964D0",
	18,
	"FXS",
	"Frax Share"
);
export const renBTC = new Token(
	SupportedChainId.MAINNET,
	"0xEB4C2781e4ebA804CE9a9803C67d0893436bB27D",
	8,
	"renBTC",
	"renBTC"
);
export const ETH2X_FLI = new Token(
	SupportedChainId.MAINNET,
	"0xAa6E8127831c9DE45ae56bB1b0d4D4Da6e5665BD",
	18,
	"ETH2x-FLI",
	"ETH 2x Flexible Leverage Index"
);

export const NYAN: { [chainId: number]: Token } = {
	[SupportedChainId.ARBITRUM_ONE]: createToken(
		SupportedChainId.ARBITRUM_ONE,
		"0xeD3fB761414DA74b74F33e5c5a1f78104b188DfC",
		18,
		"NYAN",
		"ArbiNYAN",
		"https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/arbitrum/assets/0xeD3fB761414DA74b74F33e5c5a1f78104b188DfC/logo.png"
	),
	[SupportedChainId.OPTIMISM]: createToken(
		SupportedChainId.OPTIMISM,
		"0xb54fa166d4b88B0478f299D5854aD9B94b3fefF3",
		18,
		"NYAN",
		"ArbiNYAN",
		"https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/arbitrum/assets/0xeD3fB761414DA74b74F33e5c5a1f78104b188DfC/logo.png"
	),
};
export const ZIP: { [chainId: number]: Token } = {
	[SupportedChainId.OPTIMISM]: createToken(
		SupportedChainId.OPTIMISM,
		"0xFA436399d0458Dbe8aB890c3441256E3E09022a8",
		18,
		"ZIP",
		"Zip Token",
		"https://gist.githubusercontent.com/NoNameFits/a53cb0c7b328440886cc89088bfb547a/raw/6edd2abb129fb5169c042d27f968c24470827275/favicon.png"
	),
};

export const WETH9_EXTENDED: { [chainId: number]: Token } = {
	[SupportedChainId.OPTIMISM]: createToken(
		SupportedChainId.OPTIMISM,
		"0x4200000000000000000000000000000000000006",
		18,
		"WETH",
		"Wrapped Ether",
		"https://ethereum-optimism.github.io/logos/WETH.png"
	),
	[SupportedChainId.ARBITRUM_ONE]: createToken(
		SupportedChainId.ARBITRUM_ONE,
		"0x82af49447d8a07e3bd95bd0d56f35241523fbab1",
		18,
		"WETH",
		"Wrapped Ether",
		"https://ethereum-optimism.github.io/logos/WETH.png"
	),
	[SupportedChainId.OPTIMISTIC_KOVAN]: createToken(
		SupportedChainId.OPTIMISTIC_KOVAN,
		"0x4200000000000000000000000000000000000006",
		18,
		"WETH",
		"Wrapped Ether",
		"https://ethereum-optimism.github.io/logos/WETH.png"
	),
	[SupportedChainId.ARBITRUM_RINKEBY]: createToken(
		SupportedChainId.ARBITRUM_RINKEBY,
		"0xB47e6A5f8b33b3F17603C83a0535A9dcD7E32681",
		18,
		"WETH",
		"Wrapped Ether",
		"https://ethereum-optimism.github.io/logos/WETH.png"
	),
};

export const GOHM_OPTIMISM = createToken(
	SupportedChainId.OPTIMISM,
	"0x0b5740c6b4a97f90eF2F0220651Cca420B868FfB",
	18,
	"gOHM",
	"Governance OHM",
	"https://data.zipswap.fi/logos/gOHM.jpg"
);

export const PEGA_OPTIMISM = createToken(
	SupportedChainId.OPTIMISM,
	"0x18607aDc70d68E196D12019d49b0607b99312853",
	18,
	"PEGA",
	"Pegasus Token",
	"https://data.zipswap.fi/logos/PEGA.jpg"
);

export const OP_OPTIMISM = createToken(
	SupportedChainId.OPTIMISM,
	"0x4200000000000000000000000000000000000042",
	18,
	"OP",
	"Optimism",
	"https://static.optimism.io/logos/OP.png"
);

export const EXTRA_TOKENS: { [chainId: number]: Token[] } = {
	[SupportedChainId.OPTIMISM]: [WETH9_EXTENDED[SupportedChainId.OPTIMISM]],
};

export class ExtendedEther extends Ether {
	public get wrapped(): Token {
		if (this.chainId in WETH9_EXTENDED) return WETH9_EXTENDED[this.chainId];
		return {} as any;
	}

	private static _cachedEther: { [chainId: number]: ExtendedEther } = {};

	public static onChain(chainId: number): ExtendedEther {
		return (
			this._cachedEther[chainId] ??
			(this._cachedEther[chainId] = new ExtendedEther(chainId))
		);
	}
}
