import { Trans } from "lib/trans";
import { useContext } from "react";
import { ArrowUpCircle } from "react-feather";
import styled, { ThemeContext } from "styled-components/macro";

import Circle from "../../assets/images/blue-loader.svg";
import { useActiveWeb3React } from "../../hooks/web3";
import { CloseIcon, CustomLightSpinner, TYPE } from "../../theme";
import { ExternalLink } from "../../theme/components";
import { ExplorerDataType, getExplorerLink } from "../../utils/getExplorerLink";
import { AutoColumn, ColumnCenter } from "../Column";
import { RowBetween } from "../Row";

const ConfirmOrLoadingWrapper = styled.div`
	width: 100%;
	padding: 24px;
`;

const ConfirmedIcon = styled(ColumnCenter)`
	padding: 60px 0;
`;

export function LoadingView({
	children,
	onDismiss,
}: {
	children: any;
	onDismiss: () => void;
}) {
	return (
		<ConfirmOrLoadingWrapper>
			<RowBetween>
				<div />
				<CloseIcon onClick={onDismiss} />
			</RowBetween>
			<ConfirmedIcon>
				<CustomLightSpinner src={Circle} alt="loader" size={"90px"} />
			</ConfirmedIcon>
			<AutoColumn gap="100px" justify={"center"}>
				{children}
				<TYPE.subHeader>
					<Trans>Confirm this transaction in your wallet</Trans>
				</TYPE.subHeader>
			</AutoColumn>
		</ConfirmOrLoadingWrapper>
	);
}

export function SubmittedView({
	children,
	onDismiss,
	hash,
}: {
	children: any;
	onDismiss: () => void;
	hash: string | undefined;
}) {
	const theme = useContext(ThemeContext);
	const { chainId } = useActiveWeb3React();

	return (
		<ConfirmOrLoadingWrapper>
			<RowBetween>
				<div />
				<CloseIcon onClick={onDismiss} />
			</RowBetween>
			<ConfirmedIcon>
				<ArrowUpCircle strokeWidth={0.5} size={90} color={theme.primary1} />
			</ConfirmedIcon>
			<AutoColumn gap="100px" justify={"center"}>
				{children}
				{chainId && hash && (
					<ExternalLink
						href={getExplorerLink(chainId, hash, ExplorerDataType.TRANSACTION)}
						style={{ marginLeft: "4px" }}
					>
						<TYPE.subHeader>
							<Trans>View transaction on Explorer</Trans>
						</TYPE.subHeader>
					</ExternalLink>
				)}
			</AutoColumn>
		</ConfirmOrLoadingWrapper>
	);
}
