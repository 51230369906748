import { Trans } from "lib/trans";
import { CurrencyAmount, Token } from "@uniswap/sdk-core";
import { Pair } from "@uniswap/v2-sdk";
import { SupportedChainId } from "constants/chains";
import useCurrentBlockTimestamp from "hooks/useCurrentBlockTimestamp";
import { useRewardsContract } from "hooks/useContract";
import JSBI from "jsbi";
import { ReactNode, useMemo } from "react";
import { abi as IERC20Abi } from "abis/swap/IERC20.json";
import { abi as RewardsAbi } from "abis/farm/ZipRewards.json";
import { abi as IRewarderAbi } from "abis/farm/IRewarder.json";

import {
	DAI_OPTIMISM,
	ZIP,
	USDC_OPTIMISM,
	WBTC_OPTIMISM,
	WETH9_EXTENDED,
	NYAN,
	GOHM_OPTIMISM,
	PEGA_OPTIMISM,
	OP_OPTIMISM,
} from "../../constants/tokens";
import { useActiveWeb3React } from "../../hooks/web3";
import {
	CallState,
	useMultipleContractMultipleData,
	useMultipleContractSingleData,
	useSingleContractMultipleData,
} from "../multicall/hooks";
import { tryParseAmount } from "../swap/hooks";
import { BigNumber, utils } from "ethers";
import { useContractValue } from "pages/ILO/tools";
import { ZERO_ADDRESS } from "constants/misc";
import _ from "lodash";

const IERC20Interface = new utils.Interface(IERC20Abi);
const RewardsInterface = new utils.Interface(RewardsAbi);
const IRewarderInterface = new utils.Interface(IRewarderAbi);

export const STAKING_GENESIS = 1641058841;

export const REWARDS_DURATION_DAYS = 60;

// export const STAKING_REWARDS = "0x1e2F8e5f94f366eF5Dc041233c0738b1c1C2Cb0c";

export const ACC_REWARD_TOKEN_PRECISION = BigNumber.from(10).pow(12);

export type Reward = {
	tokens: Token[];
	rewarder: string;
};

export type StakingRewardsInfo = {
	pid: number;
	lp: Token;
	masterchef: string;
	tokens?: [Token, Token];
	doubleReward?: Reward;
	ended?: boolean;
};

export const STAKING_REWARDS_INFO: {
	[chainId: number]: StakingRewardsInfo[];
} = {
	[SupportedChainId.OPTIMISM]: [
		{
			lp: new Token(
				SupportedChainId.OPTIMISM,
				"0x1a981daa7967c66c3356ad044979bc82e4a478b9",
				18,
				"WETH-USDC-ZS",
				"ZipSwap LP"
			),
			tokens: [WETH9_EXTENDED[SupportedChainId.OPTIMISM], USDC_OPTIMISM],
			masterchef: "0x1e2F8e5f94f366eF5Dc041233c0738b1c1C2Cb0c",
			pid: 0,
		},
		{
			lp: new Token(
				SupportedChainId.OPTIMISM,
				"0x53790b6c7023786659d11ed82ee03079f3bd6976",
				18,
				"WETH-DAI-ZS",
				"ZipSwap LP"
			),
			tokens: [WETH9_EXTENDED[SupportedChainId.OPTIMISM], DAI_OPTIMISM],
			masterchef: "0x1e2F8e5f94f366eF5Dc041233c0738b1c1C2Cb0c",
			pid: 1,
		},
		{
			lp: new Token(
				SupportedChainId.OPTIMISM,
				"0x251de0f0368c472bba2e1c8f5db5ac7582b5f847",
				18,
				"WETH-WBTC-ZS",
				"ZipSwap LP"
			),
			tokens: [WETH9_EXTENDED[SupportedChainId.OPTIMISM], WBTC_OPTIMISM],
			masterchef: "0x1e2F8e5f94f366eF5Dc041233c0738b1c1C2Cb0c",
			pid: 2,
		},
		{
			lp: new Token(
				SupportedChainId.OPTIMISM,
				"0xD7F6ECF4371eddBd60C1080BfAEc3d1d60D415d0",
				18,
				"WETH-ZIP-ZS",
				"ZipSwap LP"
			),
			tokens: [
				WETH9_EXTENDED[SupportedChainId.OPTIMISM],
				ZIP[SupportedChainId.OPTIMISM],
			],
			masterchef: "0x1e2F8e5f94f366eF5Dc041233c0738b1c1C2Cb0c",
			pid: 3,
		},
		{
			lp: new Token(
				SupportedChainId.OPTIMISM,
				"0x3f6da9334142477718bE2ecC3577d1A28dceAAe1",
				18,
				"gOHM-WETH-ZS",
				"ZipSwap LP"
			),
			tokens: [WETH9_EXTENDED[SupportedChainId.OPTIMISM], GOHM_OPTIMISM],
			masterchef: "0x1e2F8e5f94f366eF5Dc041233c0738b1c1C2Cb0c",
			pid: 5,
			doubleReward: {
				rewarder: "0x10400eB8f8A44630cb703cd3A6DF4Ba0aFCB6E60",
				tokens: [GOHM_OPTIMISM],
			},
		},
		{
			lp: new Token(
				SupportedChainId.OPTIMISM,
				"0x738876C7204eB9c9f718DB1119634997E7E322f6",
				18,
				"WETH-PEGA-ZS",
				"ZipSwap LP"
			),
			tokens: [WETH9_EXTENDED[SupportedChainId.OPTIMISM], PEGA_OPTIMISM],
			masterchef: "0x1e2F8e5f94f366eF5Dc041233c0738b1c1C2Cb0c",
			pid: 6,
		},
		{
			lp: new Token(
				SupportedChainId.OPTIMISM,
				"0x167dc49c498729223D1565dF3207771B4Ee19853",
				18,
				"WETH-OP-ZS",
				"ZipSwap LP"
			),
			tokens: [WETH9_EXTENDED[SupportedChainId.OPTIMISM], OP_OPTIMISM],
			masterchef: "0x1e2F8e5f94f366eF5Dc041233c0738b1c1C2Cb0c",
			pid: 7,
		},
		{
			lp: NYAN[SupportedChainId.OPTIMISM],
			masterchef: "0xd28A905f6a252F84957fcb8983072d414D6de84A",
			pid: 0,
			ended: true,
		},
	],
};

export interface StakingInfo {
	// the id of the pool
	pid: number;
	// the tokens involved in this tokens
	tokens?: [Token, Token];
	// the amount of token currently staked, or undefined if no account
	stakedAmount: CurrencyAmount<Token>;
	// the amount of reward token earned by the active account, or undefined if no account
	earnedAmounts: CurrencyAmount<Token>[];
	// the total amount of token staked in the contract
	totalStakedAmount: CurrencyAmount<Token>;
	// the amount of token distributed per second to all LPs, constant
	totalRewardRates: CurrencyAmount<Token>[];
	// the current amount of token distributed to the active account per second.
	// equivalent to percent of total supply * reward rate
	rewardRates: CurrencyAmount<Token>[];
	// rewards
	doubleReward?: Reward;
	// when the period ends
	periodFinish: Date | undefined;
	// if pool is active
	active: boolean;
	// lp token
	lp: Token;
	// masterchef
	masterchef: string;
	// calculates a hypothetical amount of token distributed to the active account per second.
	getHypotheticalRewardRate: (
		stakedAmount: CurrencyAmount<Token>,
		totalStakedAmount: CurrencyAmount<Token>,
		totalRewardRate: CurrencyAmount<Token>
	) => CurrencyAmount<Token>;
}

// gets the staking info from the network for the active chain id
export function useStakingInfo(tokenFilter?: string | null): StakingInfo[] {
	const { chainId, account } = useActiveWeb3React();

	const zip = chainId ? ZIP[chainId] : undefined;

	const stakingRewards = useMemo(
		() =>
			!chainId
				? []
				: (STAKING_REWARDS_INFO[chainId] ?? []).filter(({ lp }) =>
						tokenFilter ? lp.address === tokenFilter : true
				  ),
		[chainId, tokenFilter]
	);

	const infoArgs = useMemo<
		[string[], [number, string | undefined][], [number][], string[], [string][]]
	>(
		() => [
			stakingRewards.map(({ masterchef }) => masterchef),
			stakingRewards.map(({ pid }) => [pid, account ?? undefined]),
			stakingRewards.map(({ pid }) => [pid]),
			stakingRewards.map(({ lp }) => lp.address),
			stakingRewards.map(({ masterchef }) => [masterchef]),
		],
		[account, stakingRewards]
	);

	// get all the info from the staking rewards contracts
	const userInfo = useMultipleContractMultipleData(
		infoArgs[0],
		RewardsInterface,
		"userInfo",
		infoArgs[1]
	);

	const poolInfo = useMultipleContractMultipleData(
		infoArgs[0],
		RewardsInterface,
		"poolInfo",
		infoArgs[2]
	);

	const totalDepositedInfo = useMultipleContractMultipleData(
		infoArgs[3],
		IERC20Interface,
		"balanceOf",
		infoArgs[4]
	);

	const pendingRewardInfo = useMultipleContractMultipleData(
		infoArgs[0],
		RewardsInterface,
		"pendingReward",
		infoArgs[1]
	);

	const totalAllocPointInfo = useMultipleContractSingleData(
		infoArgs[0],
		RewardsInterface,
		"totalAllocPoint"
	);

	const zipPerSecondInfo = useMultipleContractSingleData(
		infoArgs[0],
		RewardsInterface,
		"zipPerSecond"
	);

	const extraRewardArgs = useMemo<[(string | undefined)[]]>(
		() => [stakingRewards.map(({ doubleReward }) => doubleReward?.rewarder)],
		[stakingRewards]
	);

	const rewardRatesInfo = useMultipleContractSingleData(
		extraRewardArgs[0],
		IRewarderInterface,
		"zipPerSecond"
	);

	const extraRewardsEnd = useMultipleContractSingleData(
		extraRewardArgs[0],
		IRewarderInterface,
		"zipRewardsSpentTime");

	const pendingExtraReward = useMultipleContractSingleData(
		extraRewardArgs[0],
		IRewarderInterface,
		"pendingTokens",
		[account ?? ZERO_ADDRESS]
	);

	return useMemo(() => {
		if (!chainId || !zip || chainId !== SupportedChainId.OPTIMISM) return [];

		return stakingRewards.reduce<StakingInfo[]>(
			(memo, { lp, tokens, pid, masterchef, doubleReward, ended }, index) => {
				// these two are dependent on account
				const user = userInfo[index];
				const pool = poolInfo[index];
				const deposited = totalDepositedInfo[index];
				const pending = pendingRewardInfo[index];
				const totalAlloc = totalAllocPointInfo[index];
				const zipPer = zipPerSecondInfo[index];

				const hasExtra = doubleReward?.rewarder !== undefined;
				const rewardRates = rewardRatesInfo[index];
				const extraReward = pendingExtraReward[index];
				const extraRewardEnds = extraRewardsEnd[index];

				if (
					// these may be undefined if not logged in
					!user.loading &&
					!pool.loading &&
					!deposited.loading &&
					!pending.loading &&
					!totalAlloc.loading &&
					!zipPer.loading &&
					(!hasExtra || !rewardRates.loading) &&
					(!hasExtra || !extraReward.loading)
				) {
					if (
						user.error ||
						pool.error ||
						deposited.error ||
						pending.error ||
						totalAlloc.error ||
						zipPer.error ||
						(hasExtra && rewardRates.error) ||
						(hasExtra && extraReward.error)
					) {
						console.error("Failed to load staking rewards info");
						return memo;
					}

					if (
						!user.result ||
						!pool.result ||
						!deposited.result ||
						!pending.result ||
						!totalAlloc.result ||
						!zipPer.result ||
						(hasExtra && !rewardRates.result) ||
						(hasExtra && !extraReward.result)
					) {
						console.error("Failed to load staking rewards info");
						return memo;
					}

					const userData = user.result as unknown as {
						amount: BigNumber;
						rewardDebt: BigNumber;
					};

					const poolData = pool.result as unknown as {
						accRewardTokenPerShare: BigNumber;
						allocPoint: BigNumber;
						lastRewardTime: BigNumber;
					};

					const depositedData = deposited.result[0] as unknown as BigNumber;
					const pendingData = pending.result[0] as unknown as BigNumber;
					const totalAllocPointData = totalAlloc
						.result[0] as unknown as BigNumber;
					const zipPerSecondData = zipPer.result[0] as unknown as BigNumber;
					const extraRewardData = !hasExtra
						? BigNumber.from(0)
						: (extraReward.result![1][0] as unknown as BigNumber);

					// check for account, if no account set to 0
					const stakedAmount = CurrencyAmount.fromRawAmount(
						lp,
						JSBI.BigInt(userData.amount.toHexString())
					);

					const totalStakedAmount = CurrencyAmount.fromRawAmount(
						lp,
						JSBI.BigInt(depositedData.toHexString())
					);

					const totalRewardRate = CurrencyAmount.fromRawAmount(
						zip,
						JSBI.BigInt(
							poolData.allocPoint
								.mul(zipPerSecondData)
								.div(totalAllocPointData)
								.toHexString()
						)
					);

					const extraRewards =
						hasExtra && doubleReward?.tokens
							? doubleReward.tokens.map((token, i) =>
									CurrencyAmount.fromRawAmount(
										token,
										JSBI.BigInt(
											(
												(BigNumber.from(Date.now()) < BigNumber.from(extraRewardEnds.result![0] as unknown as BigNumber) ? rewardRates.result![0] : BigNumber.from(0)) as unknown as BigNumber
											).toHexString()
										)
									)
							  )
							: [];

					const getHypotheticalRewardRate = (
						stakedAmount: CurrencyAmount<Token>,
						totalStakedAmount: CurrencyAmount<Token>,
						totalRewardRate: CurrencyAmount<Token>
					): CurrencyAmount<Token> => {
						return CurrencyAmount.fromRawAmount(
							zip,
							JSBI.greaterThan(totalStakedAmount.quotient, JSBI.BigInt(0))
								? JSBI.divide(
										JSBI.multiply(
											totalRewardRate.quotient,
											stakedAmount.quotient
										),
										totalStakedAmount.quotient
								  )
								: JSBI.BigInt(0)
						);
					};

					const periodFinishSeconds = 1646156700;
					const periodFinishMs = periodFinishSeconds * 1000;

					// compare period end timestamp vs current block timestamp (in seconds)
					// const active = poolData.allocPoint.gt(0);
					const active = !ended;

					const totalRewardRates = [totalRewardRate, ...extraRewards];

					const earnedAmounts = [
						[zip, pendingData],
						...(hasExtra ? [[doubleReward.tokens[0], extraRewardData]] : []),
					].map(([token, data]) =>
						CurrencyAmount.fromRawAmount(
							token as Token,
							JSBI.BigInt((data as BigNumber).toHexString())
						)
					);

					memo.push({
						pid,
						tokens,
						periodFinish:
							periodFinishMs > 0 ? new Date(periodFinishMs) : undefined,
						earnedAmounts,
						rewardRates: totalRewardRates.map((rate) =>
							getHypotheticalRewardRate(stakedAmount, totalStakedAmount, rate)
						),
						totalRewardRates,
						stakedAmount,
						totalStakedAmount,
						getHypotheticalRewardRate,
						active,
						lp,
						masterchef,
						doubleReward,
					});
				}

				return memo;
			},
			[]
		);
	}, [
		userInfo,
		poolInfo,
		totalDepositedInfo,
		totalAllocPointInfo,
		zipPerSecondInfo,
		chainId,
		zip,
		stakingRewards,
		pendingRewardInfo,
		rewardRatesInfo,
	]);
}

export function useTotalUniEarned(): CurrencyAmount<Token> | undefined {
	const { chainId } = useActiveWeb3React();
	const uni = chainId ? ZIP[chainId] : undefined;
	const stakingInfos = useStakingInfo();

	return useMemo(() => {
		if (!uni) return undefined;
		return (
			stakingInfos?.reduce(
				(accumulator, stakingInfo) =>
					accumulator.add(stakingInfo.earnedAmounts[0]),
				CurrencyAmount.fromRawAmount(uni, "0")
			) ?? CurrencyAmount.fromRawAmount(uni, "0")
		);
	}, [stakingInfos, uni]);
}

// based on typed value
export function useDerivedStakeInfo(
	typedValue: string,
	stakingToken: Token | undefined,
	userLiquidityUnstaked: CurrencyAmount<Token> | undefined
): {
	parsedAmount?: CurrencyAmount<Token>;
	error?: ReactNode;
} {
	const { account } = useActiveWeb3React();

	const parsedInput: CurrencyAmount<Token> | undefined = tryParseAmount(
		typedValue,
		stakingToken
	);

	const parsedAmount =
		parsedInput &&
		userLiquidityUnstaked &&
		JSBI.lessThanOrEqual(parsedInput.quotient, userLiquidityUnstaked.quotient)
			? parsedInput
			: undefined;

	let error: ReactNode | undefined;
	if (!account) {
		error = <Trans>Connect Wallet</Trans>;
	}
	if (!parsedAmount) {
		error = error ?? <Trans>Enter an amount</Trans>;
	}

	return {
		parsedAmount,
		error,
	};
}
