import { Trans } from "lib/trans";
import CurrencyInputPanel from "components/CurrencyInputPanel";
import { SupportedChainId } from "constants/chains";
import { ExtendedEther, ZIP } from "constants/tokens";
import { utils } from "ethers";
import useTheme from "hooks/useTheme";
import { useActiveWeb3React } from "hooks/web3";
import { ArrowDown } from "react-feather";
import { useMemo, useState } from "react";
import { Flex, Text } from "rebass";
import { useCurrencyBalances } from "state/wallet/hooks";
import styled from "styled-components/macro";
import { TYPE } from "theme";
import { maxAmountSpend } from "utils/maxAmountSpend";
import { css } from "styled-components";
import Modal from "components/Modal";

import Card, { BlueCard, LightCard } from "../../components/Card";
import { AutoColumn } from "../../components/Column";
import Row, { RowBetween } from "../../components/Row";
import { ButtonLight, ButtonPrimary } from "components/Button";
import { tryParseAmount } from "state/swap/hooks";
import CurrencyLogo from "components/CurrencyLogo";
import { darken } from "polished";
import { useILOContract } from "hooks/useContract";
import { BigNumber } from "@ethersproject/bignumber";
import { formatEther, parseEther } from "@ethersproject/units";
import { toCommaNumber, useContractValue } from "./tools";
import { useTransactionAdder } from "state/transactions/hooks";
import { TransactionType } from "state/transactions/actions";
import { useDifferenceClock } from "hooks/useClock";
import { extraGas } from "utils/gas";
import { CardBGImage, CardSection, DataCard } from "components/earn/styled";
import { Link, useHistory } from "react-router-dom";
import { Wrapper } from "pages/Pool/styleds";
import { useStoredBoolean } from "utils/useStoredBoolean";
import { useRequireOptimism } from "components/Optimism/RequireOptimism";
import { useCoinGecko } from "hooks/useCoingecko";

const PageWrapper = styled(AutoColumn)`
	max-width: 480px;
	width: 100%;
`;

const StyledCard = styled(LightCard)`
	border: none;
	background: ${({ theme }) => theme.bg0};
	border: 2px solid ${({ theme }) => theme.bg2};
	position: relative;
	overflow: hidden;
	width: 100%;
`;

const HeaderLinks = styled(Row)`
	justify-self: center;
	background-color: ${({ theme }) => theme.bg0};
	width: fit-content;
	padding: 4px;
	border-radius: 16px;
	display: grid;
	grid-auto-flow: column;
	grid-gap: 10px;
	overflow: auto;
	align-items: center;
	${({ theme }) => theme.mediaWidth.upToMedium`
    justify-self: center;
  `};
`;

const StyledNavLink = styled(Text)<{ active?: boolean }>`
	${({ theme }) => theme.flexRowNoWrap}
	align-items: left;
	border-radius: 3rem;
	outline: none;
	cursor: pointer;
	text-decoration: none;
	color: ${({ theme }) => theme.text2};
	font-size: 1rem;
	font-weight: 500;
	padding: 8px 12px;
	word-break: break-word;
	overflow: hidden;
	white-space: nowrap;

	${({ active }) =>
		active &&
		css`
			border-radius: 12px;
			font-weight: 600;
			justify-content: center;
			color: ${({ theme }) => theme.text1};
			background-color: ${({ theme }) => theme.bg2};
		`}

	:hover,
	:focus {
		color: ${({ theme }) => darken(0.1, theme.text1)};
	}
`;

const TopSection = styled(AutoColumn)`
	width: 100%;
`;

// const currency = WETH9_EXTENDED[SupportedChainId.OPTIMISM];
const currencyIn = ExtendedEther.onChain(SupportedChainId.OPTIMISM);
const currencyOut = ZIP[SupportedChainId.OPTIMISM];

export default function ILO() {
	const theme = useTheme();
	const history = useHistory();
	const { account } = useActiveWeb3React();

	const [legalAccepted, setLegalAccepted] = useStoredBoolean(
		"legal_accepted",
		false
	);

	const [attemptingTxn, setAttemptingTxn] = useState<string | null>(null);
	const [value, setValue] = useState("");
	const [page, setPage] = useState(0);

	const [balance] = useCurrencyBalances(account ?? undefined, [currencyIn]);

	const addTransaction = useTransactionAdder();

	const maxAmount = maxAmountSpend(balance);

	const safeValue = useMemo(
		() =>
			(value && (isNaN(parseFloat(value)) ? undefined : value)) || undefined,
		[value]
	);

	const atMaxAmount = maxAmount?.equalTo(
		utils.parseEther(safeValue || "0").toString()
	);

	const inputAmount = useMemo(
		() => tryParseAmount(safeValue, currencyIn ?? undefined),
		[safeValue]
	);

	const hasInputAmount = Boolean(inputAmount?.greaterThan("0"));

	const sufficientBalance =
		inputAmount && balance && !balance.lessThan(inputAmount);

	const ilo = useILOContract();

	const { data: etherPrice } = useCoinGecko("/simple/price", {
		ids: "ethereum",
		vs_currencies: "usd",
	});

	const minimumEthAmount = useContractValue<BigNumber>(ilo, "minimumEthAmount");
	const totalProvidedEth = useContractValue<BigNumber>(ilo, "totalProvidedEth");
	const totalEffectiveEth = useContractValue<BigNumber>(
		ilo,
		"totalEffectiveEth"
	);
	const rewardBalance = useContractValue<BigNumber>(ilo, "rewardBalance");
	const baseBonus = useContractValue<BigNumber>(ilo, "getEffectiveEth", [
		parseEther("1"),
	]);
	const bonusEth = useContractValue<BigNumber>(ilo, "getEffectiveEth", [
		parseEther(safeValue || "0"),
	]);

	const acc = [account ?? "0x0000000000000000000000000000000000000000"];
	const depositedEth = useContractValue<BigNumber>(ilo, "providedEth", acc);
	const effectiveEth = useContractValue<BigNumber>(ilo, "effectiveEth", acc);

	const startTimeRaw = useContractValue<BigNumber>(ilo, "startTime");
	const endTimeRaw = useContractValue<BigNumber>(ilo, "endTime");

	const startTime = useMemo(
		() => (startTimeRaw ? startTimeRaw.toNumber() * 1000 : undefined),
		[startTimeRaw]
	);

	const endTime = useMemo(
		() => (endTimeRaw ? endTimeRaw.toNumber() * 1000 : undefined),
		[endTimeRaw]
	);

	const [time, starting, ended] = useDifferenceClock(startTime, endTime);

	const trueBonus = useMemo(
		() =>
			(bonusEth &&
				(parseFloat(formatEther(bonusEth)) - parseFloat(safeValue || "0")) /
					parseFloat(formatEther(bonusEth))) ||
			undefined,
		[bonusEth, safeValue]
	);

	const totalEth = useMemo(
		() =>
			(totalProvidedEth && bonusEth && totalProvidedEth.add(bonusEth)) ||
			undefined,
		[totalProvidedEth, bonusEth]
	);

	const zipPortion = useMemo(
		() =>
			(rewardBalance &&
				bonusEth &&
				totalEth &&
				formatEther(
					totalEth.eq(0)
						? rewardBalance
						: rewardBalance.mul(bonusEth).div(totalEth)
				)) ||
			undefined,
		[rewardBalance, bonusEth, totalEth]
	);

	const entitledZip = useMemo(
		() =>
			(effectiveEth &&
				totalEffectiveEth &&
				rewardBalance &&
				effectiveEth.gt(0) &&
				totalEffectiveEth.gt(0) &&
				rewardBalance.mul(effectiveEth).div(totalEffectiveEth)) ||
			undefined,
		[effectiveEth, totalEffectiveEth, rewardBalance]
	);

	const depositEth = () => {
		if (!account || !ilo || !safeValue || !trueBonus) return;

		setAttemptingTxn("Depositing...");

		ilo.estimateGas
			.pay({
				from: account,
				value: parseEther(safeValue),
			})
			.then((gas) => {
				ilo
					.pay({
						value: parseEther(safeValue),
						gasLimit: extraGas(gas),
					})
					.then((response: any) => {
						setAttemptingTxn(null);

						addTransaction(response, {
							type: TransactionType.DEPOSIT_ILO,
							baseAmountRaw: parseEther(safeValue).toString(),
						});
					})
					.catch((e: any) => {
						setAttemptingTxn(null);
						// we only care if the error is something _other_ than the user rejected the tx
						if (e?.code !== 4001) {
							console.error(e);
						}
					});
			})
			.catch((e: any) => {
				setAttemptingTxn(null);
				// we only care if the error is something _other_ than the user rejected the tx
				if (e?.code !== 4001) {
					console.error(e);
				}
			});
	};

	const withdrawZip = () => {
		if (!account || !ilo || !entitledZip) return;

		setAttemptingTxn("Withdrawing...");

		ilo.estimateGas
			.claim({
				from: account,
			})
			.then((gas) => {
				ilo
					.claim({
						gasLimit: extraGas(gas),
					})
					.then((response: any) => {
						setAttemptingTxn(null);

						addTransaction(response, {
							type: TransactionType.WITHDRAW_ILO,
							rewardAmountRaw: entitledZip.toString(),
						});
					})
					.catch((e: any) => {
						setAttemptingTxn(null);
						// we only care if the error is something _other_ than the user rejected the tx
						if (e?.code !== 4001) {
							console.error(e);
						}
					});
			})
			.catch((e: any) => {
				setAttemptingTxn(null);
				// we only care if the error is something _other_ than the user rejected the tx
				if (e?.code !== 4001) {
					console.error(e);
				}
			});
	};

	const inputError =
		attemptingTxn ?? !zipPortion
			? "Loading..."
			: sufficientBalance
			? undefined
			: hasInputAmount
			? "Insufficient ETH balance"
			: "Enter ETH amount";

	const required = useRequireOptimism("Initial Liquidity Offering");

	if (required) {
		return required;
	}

	return (
		<>
			<Modal isOpen={!legalAccepted} onDismiss={() => {}}>
				<Wrapper style={{ width: "100%" }}>
					<Text fontWeight={600} fontSize={20}>
						Acknowledge ILO terms
					</Text>

					{/* Thanks dYdX for the awesome legal text, love you guys <3 */}

					<Card backgroundColor={theme.bg2} marginTop="1rem">
						{[
							"You are not a person or an entity that resides in, is a citizen of, is incorporated in, or has a registered office in the United States of America.",
							"It's legal for you to participate in the Initial Liquidity Offering under the laws of the jurisdiction in which you reside.",
						].map((item, i) => (
							<Text
								fontWeight={500}
								fontSize={15}
								display="flex"
								marginTop={i === 0 ? "0" : "0.75rem"}
							>
								<div style={{ marginRight: "0.5rem" }}>•</div>
								{item}
							</Text>
						))}
					</Card>

					<Flex marginTop="1rem">
						<ButtonLight
							marginRight="0.75rem"
							height="3rem"
							onClick={() => {
								history.push("/swap");
							}}
						>
							Cancel
						</ButtonLight>

						<ButtonPrimary
							height="3rem"
							onClick={() => {
								setLegalAccepted(true);
							}}
						>
							Confirm
						</ButtonPrimary>
					</Flex>
				</Wrapper>
			</Modal>

			<PageWrapper gap="lg" justify="center">
				<TopSection gap="md">
					<DataCard>
						{/* <CardBGImage /> */}
						<CardSection>
							<AutoColumn gap="md">
								<RowBetween>
									<Text fontWeight={600}>Initial Liquidity Offering</Text>
								</RowBetween>
								<RowBetween>
									<Text fontSize={14}>
										Deposit ETH into the ILO to receive a portion of the sale's
										ZIP, the ZipSwap protocol governance token. All acquired ETH
										will be used to fund the ZIP-ETH liquidity pool.
									</Text>
								</RowBetween>{" "}
								<Link
									style={{ color: theme.text1, textDecoration: "underline" }}
									to="/whitepaper"
								>
									<Text fontSize={14}>Read more about ZIP</Text>
								</Link>
							</AutoColumn>
						</CardSection>
						{/* <CardBGImage /> */}
					</DataCard>
				</TopSection>

				<StyledCard>
					<Text fontSize={14} fontWeight={500} color={theme.text3}>
						Total Offering
					</Text>

					<div
						style={{
							height: "0.4rem",
						}}
					/>

					<Text fontSize={30} fontWeight={600} color={theme.text1}>
						<CurrencyLogo currency={currencyOut} size="22px" />{" "}
						{rewardBalance
							? toCommaNumber(parseFloat(formatEther(rewardBalance)).toFixed(0))
							: "..."}{" "}
						ZIP
					</Text>

					<div
						style={{
							height: "1rem",
						}}
					/>

					<Text fontSize={14} fontWeight={500} color={theme.text3}>
						Market capitalization (100M ZIP, ETH=
						{`$${etherPrice?.ethereum?.usd.toFixed(0)}` || "..."})
					</Text>

					<div
						style={{
							height: "0.4rem",
						}}
					/>

					<Text fontSize={30} fontWeight={600} color={theme.text1}>
						$
						{totalProvidedEth && etherPrice?.ethereum?.usd
							? toCommaNumber(
									(
										(parseFloat(formatEther(totalProvidedEth)) / 55) *
										100 *
										etherPrice?.ethereum?.usd
									).toFixed(0)
							  )
							: "..."}{" "}
					</Text>

					<div
						style={{
							height: "1rem",
						}}
					/>

					<RowBetween>
						<Text fontSize={14} fontWeight={500} color={theme.text3}>
							Total Deposited
						</Text>

						<Text fontSize={14} fontWeight={500} color={theme.text3}>
							Minimum total ETH
						</Text>
					</RowBetween>

					<div
						style={{
							height: "0.4rem",
						}}
					/>

					<RowBetween>
						<Text fontSize={30} fontWeight={600} color={theme.text1}>
							<CurrencyLogo currency={currencyIn} size="22px" />{" "}
							{totalProvidedEth
								? toCommaNumber(
										parseFloat(formatEther(totalProvidedEth)).toFixed(2)
								  )
								: "..."}{" "}
							ETH
						</Text>

						<Text fontSize={30} fontWeight={600} color={theme.text1}>
							<CurrencyLogo currency={currencyIn} size="22px" />{" "}
							{minimumEthAmount
								? toCommaNumber(
										parseFloat(formatEther(minimumEthAmount)).toFixed(2)
								  )
								: "..."}{" "}
							ETH
						</Text>
					</RowBetween>

					<div
						style={{
							height: "1rem",
						}}
					/>

					<RowBetween>
						{!ended && !starting && (
							<Text fontSize={14} fontWeight={500} color={theme.text3}>
								Current Deposit Bonus
							</Text>
						)}

						<Text fontSize={14} fontWeight={500} color={theme.text3}>
							{starting ? "Starting in" : "Time Remaining"}
						</Text>
					</RowBetween>

					<div
						style={{
							height: "0.4rem",
						}}
					/>

					<RowBetween>
						{!ended && !starting && (
							<Text fontSize={30} fontWeight={600} color={theme.text1}>
								+{" "}
								{baseBonus
									? toCommaNumber(
											((parseFloat(formatEther(baseBonus)) - 1) * 100).toFixed(
												4
											)
									  )
									: "..."}{" "}
								%
							</Text>
						)}

						<Text fontSize={30} fontWeight={600} color={theme.text1}>
							{time}
						</Text>
					</RowBetween>
				</StyledCard>

				<StyledCard>
					<Row
						style={{
							justifyContent: "center",
						}}
					>
						<HeaderLinks>
							<StyledNavLink active={page === 0} onClick={() => setPage(0)}>
								Deposit ETH
							</StyledNavLink>

							<StyledNavLink active={page === 1} onClick={() => setPage(1)}>
								Withdraw ZIP
							</StyledNavLink>
						</HeaderLinks>
					</Row>

					<div
						style={{
							height: "1rem",
						}}
					/>
					<Text fontSize={14} fontWeight={500} color={theme.text3}>
						Your Deposited ETH
					</Text>

					<div
						style={{
							height: "0.4rem",
						}}
					/>

					<Text fontSize={30} fontWeight={600} color={theme.text1}>
						<CurrencyLogo currency={currencyIn} size="22px" />{" "}
						{depositedEth
							? toCommaNumber(parseFloat(formatEther(depositedEth)).toFixed(6))
							: "0"}{" "}
						ETH
					</Text>

					<div
						style={{
							height: "1rem",
						}}
					/>

					<Text fontSize={14} fontWeight={500} color={theme.text3}>
						Your ZIP token share at the current total deposited ETH
					</Text>

					<div
						style={{
							height: "0.4rem",
						}}
					/>

					<Text fontSize={30} fontWeight={600} color={theme.text1}>
						<CurrencyLogo currency={currencyOut} size="22px" />{" "}
						{entitledZip
							? toCommaNumber(parseFloat(formatEther(entitledZip)).toFixed(6))
							: 0}{" "}
						ZIP
					</Text>

					<div
						style={{
							height: "1rem",
						}}
					/>

					{page === 1 && (
						<>
							<ButtonPrimary
								disabled={
									Boolean(attemptingTxn) ||
									!ended ||
									!entitledZip ||
									entitledZip.eq(0)
								}
								onClick={() => {
									if (
										Boolean(attemptingTxn) ||
										!ended ||
										!entitledZip ||
										entitledZip.eq(0)
									)
										return;

									withdrawZip();
								}}
							>
								{attemptingTxn ??
									(!ended
										? "Withdrawals not available yet"
										: (entitledZip && entitledZip.gt(0) && "Withdraw") ||
										  "No ZIP to withdraw")}
							</ButtonPrimary>
						</>
					)}

					{page === 0 && !ended && !starting && (
						<>
							<Text fontSize={22} fontWeight={600} marginBottom="0.75rem">
								Deposit
							</Text>

							<CurrencyInputPanel
								value={value}
								onUserInput={setValue}
								onMax={() => {
									setValue(maxAmount?.toExact() ?? "");
								}}
								onCurrencySelect={() => {}}
								showMaxButton={!atMaxAmount}
								currency={currencyIn}
								id="deposit-ilo-amount"
								showCommonBases
								allowSelect={false}
							/>

							<Row
								style={{
									justifyContent: "center",
									marginTop: "0.5rem",
								}}
							>
								<ArrowDown size={20} color={theme.text3} />
							</Row>

							<div
								style={{
									padding: "0 0.5rem",
									marginTop: "0.5rem",
								}}
							>
								<Text fontSize={14} fontWeight={500} color={theme.text3}>
									Your ZIP token share at the current total deposited ETH
								</Text>

								<div
									style={{
										height: "0.4rem",
									}}
								/>

								<Text fontSize={30} fontWeight={600} color={theme.text1}>
									<CurrencyLogo currency={currencyOut} size="22px" /> ~
									{zipPortion && safeValue && parseFloat(safeValue) !== 0
										? toCommaNumber(parseFloat(zipPortion).toFixed(6))
										: "..."}{" "}
									ZIP
								</Text>

								<RowBetween
									style={{
										marginTop: "16px",
									}}
								>
									<Text fontSize={14} fontWeight={500}>
										Base Tokens
									</Text>

									<Text fontSize={14} fontWeight={500}>
										~
										{zipPortion && trueBonus
											? toCommaNumber(
													(parseFloat(zipPortion) * (1 - trueBonus)).toFixed(10)
											  )
											: "..."}{" "}
										ZIP
									</Text>
								</RowBetween>

								<RowBetween
									style={{
										paddingTop: "4px",
										paddingBottom: "16px",
									}}
								>
									<Text fontSize={14} fontWeight={500}>
										Bonus Tokens (+
										{baseBonus && trueBonus
											? toCommaNumber(
													(
														(parseFloat(formatEther(baseBonus)) - 1) *
														100
													).toFixed(4)
											  )
											: "..."}
										%)
									</Text>

									<Text fontSize={14} fontWeight={500}>
										~
										{zipPortion && trueBonus
											? toCommaNumber(
													(parseFloat(zipPortion) * trueBonus).toFixed(10)
											  )
											: "..."}{" "}
										ZIP
									</Text>
								</RowBetween>
							</div>

							<ButtonPrimary
								disabled={Boolean(inputError)}
								onClick={() => {
									if (Boolean(inputError)) return;
									depositEth();
								}}
							>
								{inputError ?? "Deposit"}
							</ButtonPrimary>
						</>
					)}

					{page === 0 && ended && !starting && (
						<>
							<BlueCard marginBottom="16px">
								<AutoColumn gap="10px">
									<TYPE.link fontWeight={400} color={"primaryText1"}>
										The <b>Initial Liquidity Offering</b> for ZipSwap has ended,
										please head over to the{" "}
										<b
											style={{ cursor: "pointer" }}
											onClick={() => {
												setPage(1);
											}}
										>
											Withdrawal
										</b>{" "}
										page.
									</TYPE.link>
								</AutoColumn>
							</BlueCard>

							<ButtonPrimary disabled={true}>
								Deposits not available anymore
							</ButtonPrimary>
						</>
					)}

					{page === 0 && !ended && starting && (
						<>
							<BlueCard marginBottom="16px">
								<AutoColumn gap="10px">
									<TYPE.link fontWeight={400} color={"primaryText1"}>
										The <b>Initial Liquidity Offering</b> for ZipSwap has not
										started yet. It will start in <b>{time}</b>.
									</TYPE.link>
								</AutoColumn>
							</BlueCard>

							<ButtonPrimary disabled={true}>
								Deposits not available yet
							</ButtonPrimary>
						</>
					)}
				</StyledCard>

				<div>
					<Text fontSize={16} fontWeight={400} textAlign="center">
						Start date: 11 January 2022 10:00 EST (15:00 UTC)
					</Text>

					<Text
						fontSize={16}
						fontWeight={400}
						textAlign="center"
						marginTop="0.25rem"
					>
						End date: 18 January 2022 10:00 EST (15:00 UTC)
					</Text>
				</div>
			</PageWrapper>
		</>
	);
}
