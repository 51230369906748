import { Trans } from "lib/trans";
import { CurrencyAmount, Token } from "@uniswap/sdk-core";
import { useRequireOptimism } from "components/Optimism/RequireOptimism";
import { useCoinGecko } from "hooks/useCoingecko";
import useTheme from "hooks/useTheme";
import { useTotalSupplies } from "hooks/useTotalSupply";
import { useV2Pairs } from "hooks/useV2Pairs";
import JSBI from "jsbi";
import { useEffect, useMemo, useState } from "react";
import { Text } from "rebass";
import styled from "styled-components/macro";
import { unwrappedToken } from "utils/unwrappedToken";

import { LightCard, OutlineCard } from "../../components/Card";
import { AutoColumn } from "../../components/Column";
import PoolCard from "../../components/earn/PoolCard";
import Loader from "../../components/Loader";
import { useActiveWeb3React } from "../../hooks/web3";
import { STAKING_REWARDS_INFO, useStakingInfo } from "../../state/stake/hooks";
import { toCommaNumber } from "../ILO/tools";
import CurrencyLogo from "components/CurrencyLogo";
import { WETH9_EXTENDED, ZIP } from "constants/tokens";
import { SupportedChainId } from "constants/chains";
import { BIG_INT_SECONDS_IN_WEEK } from "constants/misc";
import { getBulkPairData } from "constants/queries";

const optiLP = "WETH-OP-ZS";

export type PairData = {
	id: string;
	oneDayVolumeUSD: number | string;
	reserveUSD: number | string;
};

const PageWrapper = styled(AutoColumn)`
	max-width: 500px;
	width: 100%;
`;

const PoolSection = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	column-gap: 10px;
	row-gap: 10px;
	width: 100%;
	justify-self: center;

	@media (max-width: 900px) {
		grid-template-columns: 1fr;
	}
`;

const StyledCard = styled(LightCard)`
	border: none;
	background: ${({ theme }) => theme.bg0};
	border: 2px solid ${({ theme }) => theme.bg2};
	position: relative;
	overflow: hidden;
	width: 100%;
`;

const Input = styled.input`
	border-radius: 1rem;
	border: 2px solid ${({ theme }) => theme.bg2};
	color: ${({ theme }) => theme.text1};
	background: ${({ theme }) => theme.bg0};
	padding: 1rem;
	font-weight: 500;
	font-size: 16px;

	&:focus,
	&:active {
		outline: none;
		border: 2px solid ${({ theme }) => theme.primary1};
	}
`;

export default function Earn() {
	const { chainId } = useActiveWeb3React();

	const theme = useTheme();

	// staking info for connected account
	const stakingInfos = useStakingInfo();

	/**
	 * only show staking cards with balance
	 * @todo only account for this if rewards are inactive
	 */
	const stakingInfosWithBalance = stakingInfos;

	// toggle copy if rewards are inactive
	const stakingRewardsExist = Boolean(
		typeof chainId === "number" &&
			(STAKING_REWARDS_INFO[chainId]?.length ?? 0) > 0
	);

	const [pairData, setPairData] = useState<PairData[]>([]);

	useEffect(() => {
		(async () => {
			const info = STAKING_REWARDS_INFO[SupportedChainId.OPTIMISM]!;
			const tokens = info
				.filter(({ tokens }) => Boolean(tokens))
				.map(({ lp }) => lp.address.toLocaleLowerCase());

			const data = await getBulkPairData(tokens);

			setPairData(data as any);
		})();
	}, []);

	const totalZip = useMemo(
		() =>
			stakingInfos.reduce(
				(memo, cur) =>
					(cur.active
						? parseFloat(
								cur.totalRewardRates[0]
									.multiply(BIG_INT_SECONDS_IN_WEEK)
									.toFixed(12)
						  )
						: 0) + memo,
				0
			),
		[stakingInfos]
	);

	const { data: etherPrice } = useCoinGecko("/simple/price", {
		ids: "ethereum",
		vs_currencies: "usd",
	});

	const pairTokens = useMemo(
		() =>
			stakingInfos.map<[Token, Token]>(
				({ tokens, lp }) =>
					tokens ?? [lp, WETH9_EXTENDED[SupportedChainId.OPTIMISM]]
			),
		[stakingInfos]
	);

	const pairs = useV2Pairs(pairTokens);

	const currencies = useMemo(
		() => stakingInfos.map((stakingInfo) => stakingInfo.stakedAmount.currency),
		[stakingInfos]
	);

	const totalSupplyOfStakingTokens = useTotalSupplies(currencies);

	const tvl = useMemo(() => {
		if (!etherPrice?.ethereum?.usd) return 0;

		return stakingInfos
			.map(({ totalStakedAmount, tokens, lp }, i) => {
				const hasLP = Boolean(tokens);

				const [token0, token1] = tokens ?? [
					lp,
					WETH9_EXTENDED[SupportedChainId.OPTIMISM],
				];
				const [, stakingTokenPair] = pairs[i];

				const currency0 = unwrappedToken(token0);

				// get the color of the token
				const WETH = currency0.isNative ? token0 : token1;

				// let returnOverMonth: Percent = new Percent('0')
				let valueOfTotalStakedAmountInWETH: CurrencyAmount<Token> | undefined;

				const supply = totalSupplyOfStakingTokens[i];

				if (supply && stakingTokenPair) {
					// take the total amount of LP tokens staked, multiply by ETH value of all LP tokens, divide by all LP tokens
					valueOfTotalStakedAmountInWETH = CurrencyAmount.fromRawAmount(
						WETH,
						JSBI.divide(
							JSBI.multiply(
								JSBI.multiply(
									totalStakedAmount.quotient,
									stakingTokenPair.reserveOf(WETH).quotient
								),
								JSBI.BigInt(hasLP ? 2 : 1) // this is b/c the value of LP shares are ~double the value of the WETH they entitle owner to
							),
							supply.quotient
						)
					);
				}

				return (
					parseFloat(
						valueOfTotalStakedAmountInWETH
							? valueOfTotalStakedAmountInWETH.toFixed(10)
							: "0"
					) * etherPrice?.ethereum?.usd
				);
			})
			.reduce((a, b) => a + b, 0);
	}, [stakingInfos, etherPrice, pairs, totalSupplyOfStakingTokens]);

	const [search, setSearch] = useState("");

	const required = useRequireOptimism("Farm");

	const opSI = stakingInfosWithBalance.find((si) => si.lp.symbol === optiLP);
	const opPD =
		opSI && pairData.find(({ id }) => id === opSI.lp.address.toLowerCase());

	if (required) {
		return required;
	}

	return (
		<PageWrapper
			gap="lg"
			justify="center"
			style={{ width: "100%", maxWidth: "800px" }}
		>
			<AutoColumn gap="lg" style={{ width: "100%", maxWidth: "800px" }}>
				<StyledCard>
					<Text fontSize={20} fontWeight={600} marginBottom="0.75rem">
						Farming Info
					</Text>

					<Text fontSize={14} fontWeight={500} color={theme.text3}>
						Total Value Locked
					</Text>

					<div
						style={{
							height: "0.4rem",
						}}
					/>

					<Text fontSize={30} fontWeight={600} color={theme.text1}>
						${toCommaNumber(tvl.toFixed(2))}
					</Text>

					<div
						style={{
							height: "1rem",
						}}
					/>

					<Text fontSize={14} fontWeight={500} color={theme.text3}>
						Total ZIP Per Week
					</Text>

					<div
						style={{
							height: "0.4rem",
						}}
					/>

					<Text fontSize={30} fontWeight={600} color={theme.text1}>
						<CurrencyLogo
							currency={ZIP[SupportedChainId.OPTIMISM]}
							size="22px"
						/>{" "}
						{totalZip !== 0
							? toCommaNumber(Math.round(totalZip).toFixed(0))
							: "-"}{" "}
						ZIP
					</Text>
				</StyledCard>

				{opSI && (
					<>
						<div />

						<Text fontSize={28} fontWeight={600}>
							ETH-OP Liquidity Mining
						</Text>

						<div>
							<PoolCard
								stakingInfo={opSI}
								pairData={opPD}
								borderColor={"#FF4343"}
							/>
						</div>

						<div />
					</>
				)}

				<Input
					placeholder="Search for farms (Token name, symbol, address)"
					value={search}
					onChange={(e) => setSearch(e.target.value)}
				/>

				<PoolSection>
					{stakingRewardsExist && stakingInfos?.length === 0 ? (
						<Loader style={{ margin: "auto" }} />
					) : !stakingRewardsExist ? (
						<OutlineCard>
							<Trans>No active pools</Trans>
						</OutlineCard>
					) : stakingInfos?.length !== 0 &&
					  stakingInfosWithBalance.length === 0 ? (
						<OutlineCard>
							<Trans>No active pools</Trans>
						</OutlineCard>
					) : (
						stakingInfosWithBalance
							?.filter(
								({ lp, tokens }) =>
									lp.symbol !== optiLP &&
									(search === "" ||
										[
											lp.address,
											lp.name,
											lp.symbol,
											...(tokens
												? tokens.map((token) => [
														token.address,
														token.name,
														token.symbol,
												  ])
												: []
											).flat(2),
										]
											.filter((s) => Boolean(s))
											.map((s) => s!.toLowerCase())
											.some((s) => s.includes(search.toLocaleLowerCase())))
							)
							.map((stakingInfo) => {
								const pd = pairData.find(
									({ id }) => id === stakingInfo.lp.address.toLowerCase()
								);

								// need to sort by added liquidity here
								return (
									<div>
										<PoolCard
											key={stakingInfo.lp.address}
											stakingInfo={stakingInfo}
											pairData={pd}
										/>
									</div>
								);
							})
					)}
				</PoolSection>
			</AutoColumn>
		</PageWrapper>
	);
}
