import Modal from "components/Modal";
import useTheme from "hooks/useTheme";
import { Dispatch, SetStateAction } from "react";
import { ArrowUpRight, Clipboard } from "react-feather";
import { Flex, Text } from "rebass";
import styled from "styled-components";
import { ExternalLink } from "theme";
import { showILO } from "utils/env";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ReactComponent as Close } from "assets/images/x.svg";

const contracts: [string, string, string[][]][] = [
	[
		"Optimism",
		"optimistic.etherscan.io",
		[
			["Factory", "0x8BCeDD62DD46F1A76F8A1633d4f5B76e0CDa521E"],
			["UniswapV2Router02", "0xE6Df0BB08e5A97b40B21950a0A51b94c4DbA0Ff6"],

			...(showILO
				? [
						["ZipToken", "0xFA436399d0458Dbe8aB890c3441256E3E09022a8"],
						["ILO", "0x5386C2af1C5901cE4a91C7EF2C8B07be931127B0"],
						["LiquidityManager", "0x3Ec5aE5F0d38933893E53bedf376Cd9A0386f4F4"],
				  ]
				: []),

			["ZipRewards", "0x1e2F8e5f94f366eF5Dc041233c0738b1c1C2Cb0c"],
		],
	],
	[
		"Arbitrum",
		"arbiscan.io",
		[
			["Factory", "0x9e343Bea27a12B23523ad88333a1B0f68cc1F05E"],
			["UniswapV2Router02", "0x4D70D768f5E1e6a7062973aFB0c7FBDa9bBb42b3"],
		],
	],
];

const ContentWrapper = styled.div`
	display: grid;
	padding: 1.25rem;
	padding-top: 0.5rem;
	grid-template-columns: 1fr auto;
	gap: 1rem;
	width: 100%;
	box-sizing: border-box;
`;

const Vertical = styled.div`
	display: flex;
	height: 100%;
	width: 100%;
	flex-direction: column;
	justify-content: center;
	flex-grow: 1;
`;

const IconButton = styled.button`
	background: transparent;
	cursor: pointer;

	width: 2.5rem;
	height: 2.5rem;

	border: none;
	outline: none;
	border-radius: 0.75rem;
	padding: calc((2.5rem / 2) - (18px / 2));

	&:hover {
		background: ${({ theme }) => theme.bg2};

		border: none;
		outline: none;
	}
`;

const CloseIcon = styled.div`
	position: absolute;
	right: 1.5rem;
	top: 16px;

	&:hover {
		cursor: pointer;
		opacity: 0.6;
	}
`;

const CloseColor = styled(Close)`
	path {
		stroke: ${({ theme }) => theme.text4};
	}
`;

export const ContractsModal = ({
	state: [open, setOpen],
}: {
	state: [boolean, Dispatch<SetStateAction<boolean>>];
}) => {
	const theme = useTheme();

	return (
		<Modal isOpen={open} onDismiss={() => setOpen(false)}>
			<div>
				<div style={{ position: "relative" }}>
					<CloseIcon onClick={() => setOpen(false)}>
						<CloseColor />
					</CloseIcon>
				</div>

				<div style={{ height: "1.25rem" }} />

				{contracts.map(([chain, scanner, contracts]) => (
					<div key={chain}>
						<div style={{ padding: "0 1.25rem" }}>
							<Text fontSize={18} fontWeight={500}>
								Contracts ({chain})
							</Text>
						</div>

						<ContentWrapper>
							{contracts
								.map(([name, contract]) => [
									<div style={{ overflow: "hidden" }} key={name}>
										<Text
											fontWeight={500}
											fontSize={16}
											marginBottom="0.125rem"
										>
											{name}
										</Text>

										<Flex>
											<Text
												fontWeight={500}
												fontSize={14}
												color={theme.primary3}
												overflow="hidden"
											>
												{contract}
											</Text>

											<Text
												fontWeight={500}
												fontSize={14}
												color={theme.primary3}
												marginLeft="0.25rem"
											>
												...
											</Text>
										</Flex>
									</div>,

									<Vertical id={`link-${name}`}>
										<Flex style={{ gap: "0.25rem" }}>
											<CopyToClipboard text={contract}>
												<IconButton>
													<Clipboard
														size={18}
														color={theme.text1}
														strokeWidth={2}
													/>
												</IconButton>
											</CopyToClipboard>

											<IconButton
												as={ExternalLink}
												href={`https://${scanner}/address/${contract}#code`}
											>
												<ArrowUpRight
													size={18}
													color={theme.text1}
													strokeWidth={2}
												/>
											</IconButton>
										</Flex>
									</Vertical>,
								])
								.flat(2)}
						</ContentWrapper>
					</div>
				))}
			</div>
		</Modal>
	);
};
