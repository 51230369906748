import ethereumLogoUrl from "assets/images/ethereum-logo.png";
import arbitrumLogoUrl from "assets/svg/arbitrum_logo.svg";
import optimismLogoUrl from "assets/svg/optimistic_ethereum.svg";
import ms from "ms.macro";

import { ARBITRUM_LIST, OPTIMISM_LIST } from "./lists";

export enum SupportedChainId {
	MAINNET = 1,
	ROPSTEN = 3,
	RINKEBY = 4,
	GOERLI = 5,
	KOVAN = 42,

	ARBITRUM_ONE = 42161,
	ARBITRUM_RINKEBY = 421611,
	OPTIMISM = 10,
	OPTIMISTIC_KOVAN = 69,
}

export const ALL_SUPPORTED_CHAIN_IDS: SupportedChainId[] = [
	// SupportedChainId.MAINNET,
	// SupportedChainId.ROPSTEN,
	// SupportedChainId.RINKEBY,
	// SupportedChainId.GOERLI,
	// SupportedChainId.KOVAN,

	SupportedChainId.ARBITRUM_ONE,
	// SupportedChainId.ARBITRUM_RINKEBY,
	...(process.env.REACT_APP_SHOW_OPT === "true"
		? [SupportedChainId.OPTIMISM]
		: []),
	//SupportedChainId.OPTIMISTIC_KOVAN,
];

export const L1_CHAIN_IDS = [
	SupportedChainId.MAINNET,
	SupportedChainId.ROPSTEN,
	SupportedChainId.RINKEBY,
	SupportedChainId.GOERLI,
	SupportedChainId.KOVAN,
	SupportedChainId.OPTIMISM,
	SupportedChainId.ARBITRUM_ONE,
] as const;

export type SupportedL1ChainId = typeof L1_CHAIN_IDS[number];

export const L2_CHAIN_IDS = [
	SupportedChainId.ARBITRUM_RINKEBY,
	SupportedChainId.OPTIMISTIC_KOVAN,
] as const;

export type SupportedL2ChainId =
	| typeof L2_CHAIN_IDS[number]
	| SupportedChainId.OPTIMISM
	| SupportedChainId.ARBITRUM_ONE;

export interface L1ChainInfo {
	readonly blockWaitMsBeforeWarning?: number;
	readonly docs: string;
	readonly explorer: string;
	readonly infoLink: string;
	readonly label: string;
	readonly logoUrl?: string;
	readonly rpcUrls?: string[];
	readonly nativeCurrency: {
		name: string; // 'Goerli ETH',
		symbol: string; // 'gorETH',
		decimals: number; //18,
	};
}
export interface L2ChainInfo extends L1ChainInfo {
	readonly bridge: string;
	readonly logoUrl: string;
	readonly statusPage?: string;
	readonly defaultListUrl: string;
}

export type ChainInfo = {
	readonly [chainId: number]: L1ChainInfo | L2ChainInfo;
} & {
	readonly [chainId in SupportedL2ChainId]: L2ChainInfo;
} &
	{ readonly [chainId in SupportedL1ChainId]: L1ChainInfo };

export const CHAIN_INFO: ChainInfo = {
	[SupportedChainId.ARBITRUM_ONE]: {
		blockWaitMsBeforeWarning: ms`10m`,
		bridge: "https://bridge.arbitrum.io/",
		defaultListUrl: ARBITRUM_LIST,
		docs: "https://offchainlabs.com/",
		explorer: "https://arbiscan.io/",
		infoLink: "https://info.zipswap.fi/#/arbitrum/",
		label: "Arbitrum",
		logoUrl: arbitrumLogoUrl,
		nativeCurrency: { name: "ETH", symbol: "ETH", decimals: 18 },
		rpcUrls: ["https://arb1.arbitrum.io/rpc"],
	},
	[SupportedChainId.ARBITRUM_RINKEBY]: {
		blockWaitMsBeforeWarning: ms`10m`,
		bridge: "https://bridge.arbitrum.io/",
		defaultListUrl: ARBITRUM_LIST,
		docs: "https://offchainlabs.com/",
		explorer: "https://rinkeby-explorer.arbitrum.io/",
		infoLink: "https://info.zipswap.fi/#/arbitrum/",
		label: "Arbitrum Rinkeby",
		logoUrl: arbitrumLogoUrl,
		nativeCurrency: {
			name: "Rinkeby ArbETH",
			symbol: "rinkArbETH",
			decimals: 18,
		},
		rpcUrls: ["https://rinkeby.arbitrum.io/rpc"],
	},
	[SupportedChainId.MAINNET]: {
		docs: "https://docs.zipswap.fi/",
		explorer: "https://etherscan.io/",
		infoLink: "https://info.zipswap.fi/#/",
		label: "Ethereum",
		logoUrl: ethereumLogoUrl,
		nativeCurrency: { name: "ETH", symbol: "ETH", decimals: 18 },
	},
	[SupportedChainId.RINKEBY]: {
		docs: "https://docs.zipswap.fi/",
		explorer: "https://rinkeby.etherscan.io/",
		infoLink: "https://info.zipswap.fi/#/",
		label: "Rinkeby",
		nativeCurrency: { name: "Rinkeby ETH", symbol: "rinkETH", decimals: 18 },
	},
	[SupportedChainId.ROPSTEN]: {
		docs: "https://docs.zipswap.fi/",
		explorer: "https://ropsten.etherscan.io/",
		infoLink: "https://info.zipswap.fi/#/",
		label: "Ropsten",
		nativeCurrency: { name: "Ropsten ETH", symbol: "ropETH", decimals: 18 },
	},
	[SupportedChainId.KOVAN]: {
		docs: "https://docs.zipswap.fi/",
		explorer: "https://kovan.etherscan.io/",
		infoLink: "https://info.zipswap.fi/#/",
		label: "Kovan",
		nativeCurrency: { name: "Kovan ETH", symbol: "kovETH", decimals: 18 },
	},
	[SupportedChainId.GOERLI]: {
		docs: "https://docs.zipswap.fi/",
		explorer: "https://goerli.etherscan.io/",
		infoLink: "https://info.zipswap.fi/#/",
		label: "Görli",
		nativeCurrency: { name: "Görli ETH", symbol: "görETH", decimals: 18 },
	},
	[SupportedChainId.OPTIMISM]: {
		blockWaitMsBeforeWarning: ms`30m`,
		bridge: "https://gateway.optimism.io/",
		defaultListUrl: OPTIMISM_LIST,
		docs: "https://optimism.io/",
		explorer: "https://optimistic.etherscan.io/",
		infoLink: "https://info.zipswap.fi/#/optimism/",
		label: "OΞ",
		logoUrl: optimismLogoUrl,
		nativeCurrency: { name: "Optimistic ETH", symbol: "ETH", decimals: 18 },
		rpcUrls: ["https://mainnet.optimism.io"],
	},
	[SupportedChainId.OPTIMISTIC_KOVAN]: {
		blockWaitMsBeforeWarning: ms`15m`,
		bridge: "https://gateway.optimism.io/",
		defaultListUrl: OPTIMISM_LIST,
		docs: "https://optimism.io/",
		explorer: "https://optimistic.etherscan.io/",
		infoLink: "https://info.zipswap.fi/#/optimism/",
		label: "Optimistic Kovan",
		rpcUrls: ["https://kovan.optimism.io"],
		logoUrl: optimismLogoUrl,
		nativeCurrency: {
			name: "Optimistic kovETH",
			symbol: "kovOpETH",
			decimals: 18,
		},
	},
};

export const ARBITRUM_HELP_CENTER_LINK =
	"https://help.zipswap.fi/en/collections/3137787-zipswap-on-arbitrum";
export const OPTIMISM_HELP_CENTER_LINK =
	"https://help.zipswap.fi/en/collections/3137778-zipswap-on-optimistic-ethereum-oξ";
