import { Trans } from "lib/trans";
import { Currency, CurrencyAmount, Fraction, Percent } from "@uniswap/sdk-core";
import { Text } from "rebass";

import { ButtonPrimary } from "../../components/Button";
import CurrencyLogo from "../../components/CurrencyLogo";
import { RowBetween, RowFixed } from "../../components/Row";
import { Field } from "../../state/mint/actions";
import { TYPE } from "../../theme";
import { LightCard } from "components/Card";

export function ConfirmAddModalBottom({
	noLiquidity,
	price,
	currencies,
	parsedAmounts,
	poolTokenPercentage,
	onAdd,
}: {
	noLiquidity?: boolean;
	price?: Fraction;
	currencies: { [field in Field]?: Currency };
	parsedAmounts: { [field in Field]?: CurrencyAmount<Currency> };
	poolTokenPercentage?: Percent;
	onAdd: () => void;
}) {
	return (
		<>
			<div style={{ height: "0.25rem" }} />
			<LightCard
				style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}
			>
				<RowBetween>
					<TYPE.body>
						<Trans>{currencies[Field.CURRENCY_A]?.symbol} Deposited</Trans>
					</TYPE.body>
					<RowFixed>
						<CurrencyLogo
							currency={currencies[Field.CURRENCY_A]}
							style={{ marginRight: "8px" }}
						/>
						<TYPE.body>
							{parsedAmounts[Field.CURRENCY_A]?.toSignificant(6)}
						</TYPE.body>
					</RowFixed>
				</RowBetween>
				<RowBetween>
					<TYPE.body>
						<Trans>{currencies[Field.CURRENCY_B]?.symbol} Deposited</Trans>
					</TYPE.body>
					<RowFixed>
						<CurrencyLogo
							currency={currencies[Field.CURRENCY_B]}
							style={{ marginRight: "8px" }}
						/>
						<TYPE.body>
							{parsedAmounts[Field.CURRENCY_B]?.toSignificant(6)}
						</TYPE.body>
					</RowFixed>
				</RowBetween>
				<RowBetween>
					<TYPE.body>
						<Trans>Rates</Trans>
					</TYPE.body>
					<TYPE.body>
						{`1 ${
							currencies[Field.CURRENCY_A]?.symbol
						} = ${price?.toSignificant(4)} ${
							currencies[Field.CURRENCY_B]?.symbol
						}`}
					</TYPE.body>
				</RowBetween>
				<RowBetween style={{ justifyContent: "flex-end" }}>
					<TYPE.body>
						{`1 ${currencies[Field.CURRENCY_B]?.symbol} = ${price
							?.invert()
							.toSignificant(4)} ${currencies[Field.CURRENCY_A]?.symbol}`}
					</TYPE.body>
				</RowBetween>
				<RowBetween>
					<TYPE.body>
						<Trans>Share of Pool:</Trans>
					</TYPE.body>
					<TYPE.body>
						<Trans>
							{noLiquidity ? "100" : poolTokenPercentage?.toSignificant(4)}%
						</Trans>
					</TYPE.body>
				</RowBetween>
			</LightCard>
			<ButtonPrimary style={{ margin: "10px 0 0 0" }} onClick={onAdd}>
				<Text fontWeight={500} fontSize={20}>
					{noLiquidity ? (
						<Trans>Create Pool & Supply</Trans>
					) : (
						<Trans>Confirm Supply</Trans>
					)}
				</Text>
			</ButtonPrimary>
		</>
	);
}
