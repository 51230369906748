import { Trans } from "lib/trans";
import {
	Currency,
	CurrencyAmount,
	Percent,
	Token,
	TradeType,
} from "@uniswap/sdk-core";
import { Trade as V2Trade } from "@uniswap/v2-sdk";
import { Trade as V3Trade } from "@uniswap/v3-sdk";
import { LoadingOpacityContainer } from "components/Loader/styled";
import { NetworkAlert } from "components/NetworkAlert/NetworkAlert";
import { AdvancedSwapDetails } from "components/swap/AdvancedSwapDetails";
import { AutoRouterLogo } from "components/swap/RouterLabel";
import SwapRoute from "components/swap/SwapRoute";
import TradePrice from "components/swap/TradePrice";
import UnsupportedCurrencyFooter from "components/swap/UnsupportedCurrencyFooter";
import Toggle from "components/Toggle";
import { MouseoverTooltip, MouseoverTooltipContent } from "components/Tooltip";
import JSBI from "jsbi";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { ArrowDown, CheckCircle, HelpCircle, Info } from "react-feather";
import { RouteComponentProps } from "react-router-dom";
import { Text } from "rebass";
import { V3TradeState } from "state/routing/types";
import styled, { ThemeContext } from "styled-components/macro";

import AddressInputPanel from "../../components/AddressInputPanel";
import {
	ButtonConfirmed,
	ButtonError,
	ButtonLight,
	ButtonPrimary,
} from "../../components/Button";
import { GreyCard } from "../../components/Card";
import { AutoColumn } from "../../components/Column";
import CurrencyInputPanel from "../../components/CurrencyInputPanel";
import CurrencyLogo from "../../components/CurrencyLogo";
import Loader from "../../components/Loader";
import Row, { AutoRow, RowBetween, RowFixed } from "../../components/Row";
import confirmPriceImpactWithoutFee from "../../components/swap/confirmPriceImpactWithoutFee";
import ConfirmSwapModal from "../../components/swap/ConfirmSwapModal";
import {
	ArrowWrapper,
	Dots,
	ResponsiveTooltipContainer,
	SwapCallbackError,
	Wrapper,
} from "../../components/swap/styleds";
import SwapHeader from "../../components/swap/SwapHeader";
import { SwitchLocaleLink } from "../../components/SwitchLocaleLink";
import TokenWarningModal from "../../components/TokenWarningModal";
import { useAllTokens, useCurrency } from "../../hooks/Tokens";
import {
	ApprovalState,
	useApproveCallbackFromTrade,
} from "../../hooks/useApproveCallback";
import useENSAddress from "../../hooks/useENSAddress";
import {
	useERC20PermitFromTrade,
	UseERC20PermitState,
} from "../../hooks/useERC20Permit";
import useIsArgentWallet from "../../hooks/useIsArgentWallet";
import { useIsSwapUnsupported } from "../../hooks/useIsSwapUnsupported";
import { useSwapCallback } from "../../hooks/useSwapCallback";
import useToggledVersion from "../../hooks/useToggledVersion";
import { useUSDCValue } from "../../hooks/useUSDCPrice";
import useWrapCallback, { WrapType } from "../../hooks/useWrapCallback";
import { useActiveWeb3React } from "../../hooks/web3";
import { useWalletModalToggle } from "../../state/application/hooks";
import { Field } from "../../state/swap/actions";
import {
	useDefaultsFromURLSearch,
	useDerivedSwapInfo,
	useSwapActionHandlers,
	useSwapState,
} from "../../state/swap/hooks";
import { useExpertModeManager } from "../../state/user/hooks";
import { LinkStyledButton, TYPE } from "../../theme";
import { computeFiatValuePriceImpact } from "../../utils/computeFiatValuePriceImpact";
import { getTradeVersion } from "../../utils/getTradeVersion";
import { maxAmountSpend } from "../../utils/maxAmountSpend";
import { warningSeverity } from "../../utils/prices";
import AppBody from "../AppBody";

import zipperSFX from "../../assets/audio/zipper.mp3";
import useSound from "use-sound";
import { useSettings } from "settings";

const StyledInfo = styled(Info)`
	height: 16px;
	width: 16px;
	margin-left: 4px;
	color: ${({ theme }) => theme.text3};
	:hover {
		color: ${({ theme }) => theme.text1};
	}
`;

export default function Swap({ history }: RouteComponentProps) {
	const { account } = useActiveWeb3React();
	const loadedUrlParams = useDefaultsFromURLSearch();

	const [enforceSlippage, setEnforceSlippage] = useState(false);

	// token warning stuff
	const [loadedInputCurrency, loadedOutputCurrency] = [
		useCurrency(loadedUrlParams?.inputCurrencyId),
		useCurrency(loadedUrlParams?.outputCurrencyId),
	];
	const [dismissTokenWarning, setDismissTokenWarning] =
		useState<boolean>(false);
	const urlLoadedTokens: Token[] = useMemo(
		() =>
			[loadedInputCurrency, loadedOutputCurrency]?.filter(
				(c): c is Token => c?.isToken ?? false
			) ?? [],
		[loadedInputCurrency, loadedOutputCurrency]
	);
	const handleConfirmTokenWarning = useCallback(() => {
		setDismissTokenWarning(true);
	}, []);

	// dismiss warning if all imported tokens are in active lists
	const defaultTokens = useAllTokens();
	const importTokensNotInDefault =
		urlLoadedTokens &&
		urlLoadedTokens.filter((token: Token) => {
			return !Boolean(token.address in defaultTokens);
		});

	const theme = useContext(ThemeContext);

	// toggle wallet when disconnected
	const toggleWalletModal = useWalletModalToggle();

	// for expert mode
	const [isExpertMode] = useExpertModeManager();

	// get version from the url
	const toggledVersion = useToggledVersion();

	// swap state
	const { independentField, typedValue, recipient } = useSwapState();
	const {
		v3Trade: { state: v3TradeState },
		bestTrade: trade,
		allowedSlippage,
		currencyBalances,
		parsedAmount,
		currencies,
		inputError: swapInputError,
	} = useDerivedSwapInfo(toggledVersion);

	const {
		wrapType,
		execute: onWrap,
		inputError: wrapInputError,
	} = useWrapCallback(
		currencies[Field.INPUT],
		currencies[Field.OUTPUT],
		typedValue
	);
	const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE;
	const { address: recipientAddress } = useENSAddress(recipient);

	const parsedAmounts = useMemo(
		() =>
			showWrap
				? {
						[Field.INPUT]: parsedAmount,
						[Field.OUTPUT]: parsedAmount,
				  }
				: {
						[Field.INPUT]:
							independentField === Field.INPUT
								? parsedAmount
								: trade?.inputAmount,
						[Field.OUTPUT]:
							independentField === Field.OUTPUT
								? parsedAmount
								: trade?.outputAmount,
				  },
		[independentField, parsedAmount, showWrap, trade]
	);

	const [routeNotFound, routeIsLoading, routeIsSyncing] = useMemo(
		() => [
			trade instanceof V3Trade ? !trade?.swaps : !trade?.route,
			V3TradeState.LOADING === v3TradeState,
			V3TradeState.SYNCING === v3TradeState,
		],
		[trade, v3TradeState]
	);

	const fiatValueInput = useUSDCValue(parsedAmounts[Field.INPUT]);
	const fiatValueOutput = useUSDCValue(parsedAmounts[Field.OUTPUT]);

	const priceImpact = trade?.priceImpact;
	/*routeIsSyncing
		? undefined
		: computeFiatValuePriceImpact(fiatValueInput, fiatValueOutput);*/

	const {
		onSwitchTokens,
		onCurrencySelection,
		onUserInput,
		onChangeRecipient,
	} = useSwapActionHandlers();
	const isValid = !swapInputError;
	const dependentField: Field =
		independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT;

	const handleTypeInput = useCallback(
		(value: string) => {
			onUserInput(Field.INPUT, value);
		},
		[onUserInput]
	);
	const handleTypeOutput = useCallback(
		(value: string) => {
			onUserInput(Field.OUTPUT, value);
		},
		[onUserInput]
	);

	// reset if they close warning without tokens in params
	const handleDismissTokenWarning = useCallback(() => {
		setDismissTokenWarning(true);
		history.push("/swap/");
	}, [history]);

	// modal and loading
	const [
		{ showConfirm, tradeToConfirm, swapErrorMessage, attemptingTxn, txHash },
		setSwapState,
	] = useState<{
		showConfirm: boolean;
		tradeToConfirm:
			| V2Trade<Currency, Currency, TradeType>
			| V3Trade<Currency, Currency, TradeType>
			| undefined;
		attemptingTxn: boolean;
		swapErrorMessage: string | undefined;
		txHash: string | undefined;
	}>({
		showConfirm: false,
		tradeToConfirm: undefined,
		attemptingTxn: false,
		swapErrorMessage: undefined,
		txHash: undefined,
	});

	const formattedAmounts = {
		[independentField]: typedValue,
		[dependentField]: showWrap
			? parsedAmounts[independentField]?.toExact() ?? ""
			: parsedAmounts[dependentField]?.toSignificant(6) ?? "",
	};

	const userHasSpecifiedInputOutput = Boolean(
		currencies[Field.INPUT] &&
			currencies[Field.OUTPUT] &&
			parsedAmounts[independentField]?.greaterThan(JSBI.BigInt(0))
	);

	// check whether the user has approved the router on the input token
	const [approvalState, approveCallback] = useApproveCallbackFromTrade(
		trade,
		allowedSlippage
	);
	const {
		state: signatureState,
		signatureData,
		gatherPermitSignature,
	} = useERC20PermitFromTrade(trade, allowedSlippage);

	const handleApprove = useCallback(async () => {
		if (
			signatureState === UseERC20PermitState.NOT_SIGNED &&
			gatherPermitSignature
		) {
			try {
				await gatherPermitSignature();
			} catch (error) {
				// try to approve if gatherPermitSignature failed for any reason other than the user rejecting it
				if (error?.code !== 4001) {
					await approveCallback();
				}
			}
		} else {
			await approveCallback();
		}
	}, [
		approveCallback,
		gatherPermitSignature,
		signatureState,
		toggledVersion,
		trade?.inputAmount.currency.symbol,
	]);

	const [play] = useSound(zipperSFX, { volume: 0.5 });
	const audio = useSettings(({ audio }) => audio);

	// check if user has gone through approval process, used to show two step buttons, reset on token change
	const [approvalSubmitted, setApprovalSubmitted] = useState<boolean>(false);

	// mark when a user has submitted an approval, reset onTokenSelection for input field
	useEffect(() => {
		if (approvalState === ApprovalState.PENDING) {
			setApprovalSubmitted(true);
		}
	}, [approvalState, approvalSubmitted]);

	const maxInputAmount: CurrencyAmount<Currency> | undefined = maxAmountSpend(
		currencyBalances[Field.INPUT]
	);
	const showMaxButton = Boolean(
		maxInputAmount?.greaterThan(0) &&
			!parsedAmounts[Field.INPUT]?.equalTo(maxInputAmount)
	);

	// the callback to execute the swap
	const { callback: swapCallback, error: swapCallbackError } = useSwapCallback(
		trade,
		allowedSlippage,
		recipient,
		maxInputAmount,
		signatureData,
		enforceSlippage
	);

	const handleSwap = useCallback(() => {
		if (!swapCallback) {
			return;
		}
		if (priceImpact && !confirmPriceImpactWithoutFee(priceImpact)) {
			return;
		}

		setSwapState({
			attemptingTxn: true,
			tradeToConfirm,
			showConfirm,
			swapErrorMessage: undefined,
			txHash: undefined,
		});
		swapCallback()
			.then((hash) => {
				if (audio) {
					play();
				}

				setSwapState({
					attemptingTxn: false,
					tradeToConfirm,
					showConfirm,
					swapErrorMessage: undefined,
					txHash: hash,
				});
			})
			.catch((error) => {
				setSwapState({
					attemptingTxn: false,
					tradeToConfirm,
					showConfirm,
					swapErrorMessage: error.message,
					txHash: undefined,
				});
			});
	}, [
		swapCallback,
		priceImpact,
		tradeToConfirm,
		showConfirm,
		recipient,
		recipientAddress,
		account,
		trade,
	]);

	// errors
	const [showInverted, setShowInverted] = useState<boolean>(false);

	// warnings on the greater of fiat value price impact and execution price impact
	const priceImpactSeverity = useMemo(() => {
		const executionPriceImpact = trade?.priceImpact;
		return warningSeverity(
			executionPriceImpact && priceImpact
				? executionPriceImpact.greaterThan(priceImpact)
					? executionPriceImpact
					: priceImpact
				: executionPriceImpact ?? priceImpact
		);
	}, [priceImpact, trade]);

	const isArgentWallet = useIsArgentWallet();

	// show approve flow when: no error on inputs, not approved or pending, or approved in current session
	// never show if price impact is above threshold in non expert mode
	const showApproveFlow =
		!isArgentWallet &&
		!swapInputError &&
		(approvalState === ApprovalState.NOT_APPROVED ||
			approvalState === ApprovalState.PENDING ||
			(approvalSubmitted && approvalState === ApprovalState.APPROVED)) &&
		!(priceImpactSeverity > 3 && !isExpertMode);

	const handleConfirmDismiss = useCallback(() => {
		setSwapState({
			showConfirm: false,
			tradeToConfirm,
			attemptingTxn,
			swapErrorMessage,
			txHash,
		});
		// if there was a tx hash, we want to clear the input
		if (txHash) {
			onUserInput(Field.INPUT, "");
		}
	}, [attemptingTxn, onUserInput, swapErrorMessage, tradeToConfirm, txHash]);

	const handleAcceptChanges = useCallback(() => {
		setSwapState({
			tradeToConfirm: trade,
			swapErrorMessage,
			txHash,
			attemptingTxn,
			showConfirm,
		});
	}, [attemptingTxn, showConfirm, swapErrorMessage, trade, txHash]);

	const handleInputSelect = useCallback(
		(inputCurrency) => {
			setApprovalSubmitted(false); // reset 2 step UI for approvals
			onCurrencySelection(Field.INPUT, inputCurrency);
		},
		[onCurrencySelection]
	);

	const handleMaxInput = useCallback(() => {
		maxInputAmount && onUserInput(Field.INPUT, maxInputAmount.toExact());
	}, [maxInputAmount, onUserInput]);

	const handleOutputSelect = useCallback(
		(outputCurrency) => onCurrencySelection(Field.OUTPUT, outputCurrency),
		[onCurrencySelection]
	);

	const swapIsUnsupported = useIsSwapUnsupported(
		currencies[Field.INPUT],
		currencies[Field.OUTPUT]
	);

	const priceImpactTooHigh = priceImpactSeverity > 3 && !isExpertMode;

	return (
		<>
			<TokenWarningModal
				isOpen={importTokensNotInDefault.length > 0 && !dismissTokenWarning}
				tokens={importTokensNotInDefault}
				onConfirm={handleConfirmTokenWarning}
				onDismiss={handleDismissTokenWarning}
			/>
			<NetworkAlert />
			<AppBody>
				<SwapHeader allowedSlippage={allowedSlippage} />
				<Wrapper id="swap-page">
					<ConfirmSwapModal
						isOpen={showConfirm}
						trade={trade}
						originalTrade={tradeToConfirm}
						onAcceptChanges={handleAcceptChanges}
						attemptingTxn={attemptingTxn}
						txHash={txHash}
						recipient={recipient}
						allowedSlippage={allowedSlippage}
						onConfirm={handleSwap}
						swapErrorMessage={swapErrorMessage}
						onDismiss={handleConfirmDismiss}
						enforceSlippage={enforceSlippage}
					/>

					<AutoColumn gap={"sm"}>
						<div style={{ display: "relative" }}>
							<CurrencyInputPanel
								label={
									independentField === Field.OUTPUT && !showWrap ? (
										<Trans>From (at most)</Trans>
									) : (
										<Trans>From</Trans>
									)
								}
								value={formattedAmounts[Field.INPUT]}
								showMaxButton={showMaxButton}
								currency={currencies[Field.INPUT]}
								onUserInput={handleTypeInput}
								onMax={handleMaxInput}
								fiatValue={fiatValueInput ?? undefined}
								onCurrencySelect={handleInputSelect}
								otherCurrency={currencies[Field.OUTPUT]}
								showCommonBases={true}
								id="swap-currency-input"
								loading={independentField === Field.OUTPUT && routeIsSyncing}
							/>
							<ArrowWrapper clickable>
								<ArrowDown
									size="16"
									onClick={() => {
										setApprovalSubmitted(false); // reset 2 step UI for approvals
										onSwitchTokens();
									}}
									color={
										currencies[Field.INPUT] && currencies[Field.OUTPUT]
											? theme.text1
											: theme.text3
									}
								/>
							</ArrowWrapper>
							<CurrencyInputPanel
								value={formattedAmounts[Field.OUTPUT]}
								onUserInput={handleTypeOutput}
								label={
									independentField === Field.INPUT && !showWrap ? (
										<Trans>To (at least)</Trans>
									) : (
										<Trans>To</Trans>
									)
								}
								showMaxButton={false}
								hideBalance={false}
								fiatValue={fiatValueOutput ?? undefined}
								priceImpact={priceImpact}
								currency={currencies[Field.OUTPUT]}
								onCurrencySelect={handleOutputSelect}
								otherCurrency={currencies[Field.INPUT]}
								showCommonBases={true}
								id="swap-currency-output"
								loading={independentField === Field.INPUT && routeIsSyncing}
							/>
						</div>

						{recipient !== null && !showWrap ? (
							<>
								<AutoRow justify="space-between" style={{ padding: "0 1rem" }}>
									<ArrowWrapper clickable={false}>
										<ArrowDown size="16" color={theme.text2} />
									</ArrowWrapper>
									<LinkStyledButton
										id="remove-recipient-button"
										onClick={() => onChangeRecipient(null)}
									>
										<Trans>- Remove recipient</Trans>
									</LinkStyledButton>
								</AutoRow>
								<AddressInputPanel
									id="recipient"
									value={recipient}
									onChange={onChangeRecipient}
								/>
							</>
						) : null}

						{!showWrap && trade && (
							<div
								style={{
									marginRight: "0.5rem",
									boxSizing: "border-box",
								}}
							>
								<Row justify={!trade ? "center" : "space-between"}>
									<RowFixed style={{ position: "relative" }}>
										<MouseoverTooltipContent
											wrap={false}
											content={
												<ResponsiveTooltipContainer>
													<SwapRoute trade={trade} syncing={routeIsSyncing} />
												</ResponsiveTooltipContainer>
											}
											placement="bottom"
											onOpen={() => {}}
										>
											<AutoRow gap="4px" width="auto">
												<AutoRouterLogo />
												<LoadingOpacityContainer $loading={routeIsSyncing}>
													{trade instanceof V3Trade &&
														trade.swaps.length > 1 && (
															<TYPE.blue fontSize={14}>
																{trade.swaps.length} routes
															</TYPE.blue>
														)}
												</LoadingOpacityContainer>
											</AutoRow>
										</MouseoverTooltipContent>
									</RowFixed>
									<RowFixed>
										<LoadingOpacityContainer $loading={routeIsSyncing}>
											<TradePrice
												price={trade.executionPrice}
												showInverted={showInverted}
												setShowInverted={setShowInverted}
											/>
										</LoadingOpacityContainer>
										<MouseoverTooltipContent
											wrap={false}
											content={
												<ResponsiveTooltipContainer
													origin="top right"
													width={"295px"}
												>
													<AdvancedSwapDetails
														trade={trade}
														allowedSlippage={allowedSlippage}
														syncing={routeIsSyncing}
														enforceSlippage={enforceSlippage}
													/>
												</ResponsiveTooltipContainer>
											}
											placement="bottom"
											onOpen={() => {}}
										>
											<StyledInfo />
										</MouseoverTooltipContent>
									</RowFixed>
								</Row>
							</div>
						)}

						<div style={{ margin: "0 0.5rem" }}>
							<RowBetween>
								<Text fontSize={14} fontWeight={400}>
									Enforce slippage (~$0.075 Fee)
								</Text>

								<Toggle
									isActive={enforceSlippage}
									toggle={() => setEnforceSlippage(!enforceSlippage)}
								/>
							</RowBetween>
						</div>

						<div>
							{swapIsUnsupported ? (
								<ButtonPrimary disabled={true}>
									<TYPE.main mb="4px">
										<Trans>Unsupported Asset</Trans>
									</TYPE.main>
								</ButtonPrimary>
							) : !account ? (
								<ButtonLight onClick={toggleWalletModal}>
									<Trans>Connect Wallet</Trans>
								</ButtonLight>
							) : showWrap ? (
								<ButtonPrimary
									disabled={Boolean(wrapInputError)}
									onClick={onWrap}
								>
									{wrapInputError ??
										(wrapType === WrapType.WRAP ? (
											<Trans>Wrap</Trans>
										) : wrapType === WrapType.UNWRAP ? (
											<Trans>Unwrap</Trans>
										) : null)}
								</ButtonPrimary>
							) : routeIsSyncing || routeIsLoading ? (
								<GreyCard style={{ textAlign: "center" }}>
									<TYPE.main mb="4px">
										<Dots>
											<Trans>Loading</Trans>
										</Dots>
									</TYPE.main>
								</GreyCard>
							) : routeNotFound && userHasSpecifiedInputOutput ? (
								<GreyCard style={{ textAlign: "center" }}>
									<TYPE.main mb="4px">
										<Trans>Insufficient liquidity for this trade.</Trans>
									</TYPE.main>
								</GreyCard>
							) : showApproveFlow ? (
								<AutoRow style={{ flexWrap: "nowrap", width: "100%" }}>
									<AutoColumn style={{ width: "100%" }} gap="12px">
										<ButtonConfirmed
											onClick={handleApprove}
											disabled={
												approvalState !== ApprovalState.NOT_APPROVED ||
												approvalSubmitted ||
												signatureState === UseERC20PermitState.SIGNED
											}
											width="100%"
											altDisabledStyle={approvalState === ApprovalState.PENDING} // show solid button while waiting
											confirmed={
												approvalState === ApprovalState.APPROVED ||
												signatureState === UseERC20PermitState.SIGNED
											}
										>
											<AutoRow
												justify="space-between"
												style={{ flexWrap: "nowrap" }}
											>
												<span style={{ display: "flex", alignItems: "center" }}>
													<CurrencyLogo
														currency={currencies[Field.INPUT]}
														size={"20px"}
														style={{ marginRight: "8px", flexShrink: 0 }}
													/>
													{/* we need to shorten this string on mobile */}
													{approvalState === ApprovalState.APPROVED ||
													signatureState === UseERC20PermitState.SIGNED ? (
														<Trans>
															You can now trade{" "}
															{currencies[Field.INPUT]?.symbol}
														</Trans>
													) : (
														<Trans>
															Allow the ZipSwap Protocol to use your{" "}
															{currencies[Field.INPUT]?.symbol}
														</Trans>
													)}
												</span>
												{approvalState === ApprovalState.PENDING ? (
													<Loader stroke="white" />
												) : (approvalSubmitted &&
														approvalState === ApprovalState.APPROVED) ||
												  signatureState === UseERC20PermitState.SIGNED ? (
													<CheckCircle size="20" color={theme.green1} />
												) : (
													<MouseoverTooltip
														text={
															<Trans>
																You must give the Uniswap smart contracts
																permission to use your{" "}
																{currencies[Field.INPUT]?.symbol}. You only have
																to do this once per token.
															</Trans>
														}
													>
														<HelpCircle
															size="20"
															color={"white"}
															style={{ marginLeft: "8px" }}
														/>
													</MouseoverTooltip>
												)}
											</AutoRow>
										</ButtonConfirmed>
										<ButtonError
											onClick={() => {
												if (isExpertMode) {
													handleSwap();
												} else {
													setSwapState({
														tradeToConfirm: trade,
														attemptingTxn: false,
														swapErrorMessage: undefined,
														showConfirm: true,
														txHash: undefined,
													});
												}
											}}
											width="100%"
											id="swap-button"
											disabled={
												!isValid ||
												(approvalState !== ApprovalState.APPROVED &&
													signatureState !== UseERC20PermitState.SIGNED) ||
												priceImpactTooHigh
											}
											error={isValid && priceImpactSeverity > 2}
										>
											<Text fontSize={16} fontWeight={500}>
												{priceImpactTooHigh ? (
													<Trans>High Price Impact</Trans>
												) : priceImpactSeverity > 2 ? (
													<Trans>Swap Anyway</Trans>
												) : (
													<Trans>Swap</Trans>
												)}
											</Text>
										</ButtonError>
									</AutoColumn>
								</AutoRow>
							) : (
								<ButtonError
									onClick={() => {
										if (isExpertMode) {
											handleSwap();
										} else {
											setSwapState({
												tradeToConfirm: trade,
												attemptingTxn: false,
												swapErrorMessage: undefined,
												showConfirm: true,
												txHash: undefined,
											});
										}
									}}
									id="swap-button"
									disabled={
										!isValid || priceImpactTooHigh || !!swapCallbackError
									}
									error={
										isValid && priceImpactSeverity > 2 && !swapCallbackError
									}
								>
									<Text fontSize={20} fontWeight={500}>
										{swapInputError ? (
											swapInputError
										) : priceImpactTooHigh ? (
											<Trans>Price Impact Too High</Trans>
										) : priceImpactSeverity > 2 ? (
											<Trans>Swap Anyway</Trans>
										) : (
											<Trans>Swap</Trans>
										)}
									</Text>
								</ButtonError>
							)}
							{isExpertMode && swapErrorMessage ? (
								<SwapCallbackError error={swapErrorMessage} />
							) : null}
						</div>
					</AutoColumn>
				</Wrapper>
			</AppBody>
			<SwitchLocaleLink />
			{!swapIsUnsupported ? null : (
				<UnsupportedCurrencyFooter
					show={swapIsUnsupported}
					currencies={[currencies[Field.INPUT], currencies[Field.OUTPUT]]}
				/>
			)}
		</>
	);
}
