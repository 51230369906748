import { BigNumber } from "@ethersproject/bignumber";
import { Interface } from "@ethersproject/abi";
import { useMemo } from "react";
import {
	CallState,
	useMultipleContractSingleData,
	useSingleCallResult,
	useSingleContractMultipleData,
} from "state/multicall/hooks";
import { abi as pairAbi } from "../abis/swap/UniswapV2Pair.json";
import { abi as tokenAbi } from "../abis/swap/IERC20.json";
import { useFactoryContract } from "./useContract";
import _ from "lodash";
import { useActiveWeb3React } from "./web3";
import { Currency, Token } from "@uniswap/sdk-core";

const PAIR_INTERFACE = new Interface(pairAbi);
const TOKEN_INTERFACE = new Interface(tokenAbi);

export const useFactoryPairs = () => {
	const factory = useFactoryContract();

	const { chainId } = useActiveWeb3React();

	const { result: poolCountResult, valid: poolCountValid } =
		useSingleCallResult(factory, "allPairsLength");

	const poolCount = useMemo(
		() =>
			(poolCountResult &&
				poolCountValid &&
				(poolCountResult[0] as BigNumber)) ||
			undefined,
		[poolCountResult, poolCountValid]
	);

	const pools = useMemo(
		() =>
			(poolCount &&
				[...Array(poolCount.toNumber()).keys()].map((i) => [
					BigNumber.from(i),
				])) ||
			[],
		[poolCount]
	);

	const poolAddressesRaw = useSingleContractMultipleData(
		factory,
		"allPairs",
		pools
	);

	const poolAddresses = useMemo(
		() =>
			poolAddressesRaw
				.filter(({ result, valid }) => valid && result)
				.map(({ result }) => result![0] as string),
		[poolAddressesRaw]
	);

	const poolData = useMultipleContractSingleData(
		poolAddresses,
		PAIR_INTERFACE,
		"tokens"
	);

	const poolPairs = useMemo(
		() =>
			poolData
				.filter(({ result, valid }) => valid && result)
				.map<[string, string]>(({ result }) => [
					result![0] as string,
					result![1] as string,
				]),
		[poolData]
	);

	const tokenAddresses = useMemo(
		() =>
			poolPairs
				.flat()
				.reduce<string[]>((pv, cv) => (pv.includes(cv) ? pv : [...pv, cv]), []),
		[poolPairs]
	);

	const [nameData, symbolData, decimalData] = (
		["name", "symbol", "decimals"] as const
	).map((func) =>
		// eslint-disable-next-line react-hooks/rules-of-hooks
		useMultipleContractSingleData(tokenAddresses, TOKEN_INTERFACE, func)
	);

	const rawTokenData = useMemo(
		() =>
			_.zip(nameData, symbolData, decimalData).map<
				[CallState, CallState, CallState]
			>(([name, symbol, decimal]) => [name!, symbol!, decimal!]),
		[nameData, symbolData, decimalData]
	);

	const tokenData = useMemo(
		() =>
			rawTokenData
				.map<[number, CallState, CallState, CallState]>((values, i) => [
					i,
					...values,
				])
				.filter(
					([_, name, symbol, decimal]) =>
						name.valid &&
						name.result &&
						symbol.valid &&
						symbol.result &&
						decimal.valid &&
						decimal.result
				)
				.map<{
					[key: string]: [string, string, number];
				}>(([index, name, symbol, decimal]) => ({
					[tokenAddresses[index]]: [
						name.result![0] as string,
						symbol.result![0] as string,
						decimal.result![0] as number,
					],
				}))
				.reduce((pv, cv) => ({ ...pv, ...cv }), {}),
		[rawTokenData, tokenAddresses]
	);

	const dataKeys = useMemo(() => Object.keys(tokenData), [tokenData]);

	const pairs = useMemo(
		() =>
			poolPairs
				.filter(
					([tokenA, tokenB]) =>
						dataKeys.includes(tokenA) && dataKeys.includes(tokenB)
				)
				.map<
					[[string, string, string, number], [string, string, string, number]]
				>(([tokenA, tokenB]) => [
					[tokenA, ...tokenData[tokenA]],
					[tokenB, ...tokenData[tokenB]],
				])
				.map(
					([
						[addressA, nameA, symbolA, decimalA],
						[addressB, nameB, symbolB, decimalB],
					]) => [
						new Token(chainId ?? 0, addressA, decimalA, symbolA, nameA),
						new Token(chainId ?? 0, addressB, decimalB, symbolB, nameB),
					]
				)
				.map<[Currency, Currency]>(([tokenA, tokenB]) => [tokenA, tokenB]),
		[tokenData, poolPairs, dataKeys, chainId]
	);

	return pairs;
};
