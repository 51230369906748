import Loader from "components/Loader";
import ApeModeQueryParamReader from "hooks/useApeModeQueryParamReader";
import { lazy, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import styled from "styled-components/macro";

import GoogleAnalyticsReporter from "../components/analytics/GoogleAnalyticsReporter";
import AddressClaimModal from "../components/claim/AddressClaimModal";
import ErrorBoundary from "../components/ErrorBoundary";
import Header from "../components/Header";
import Polling from "../components/Header/Polling";
import Popups from "../components/Popups";
import Web3ReactManager from "../components/Web3ReactManager";
import { useModalOpen, useToggleModal } from "../state/application/hooks";
import { ApplicationModal } from "../state/application/reducer";
import DarkModeQueryParamReader from "../theme/DarkModeQueryParamReader";
import AddLiquidity from "./AddLiquidity";
import { RedirectDuplicateTokenIds } from "./AddLiquidity/redirects";
import { RedirectDuplicateTokenIdsV2 } from "./AddLiquidityV2/redirects";
import { showFarm, showILO, showWhitepaper } from "utils/env";
import Earn from "./Earn";
import ILO from "./ILO";
import Whitepaper from "./Whitepaper";
import Manage from "./Earn/Manage";
import MigrateV2 from "./MigrateV2";
import MigrateV2Pair from "./MigrateV2/MigrateV2Pair";
import Pool from "./Pool";
import { PositionPage } from "./Pool/PositionPage";
import PoolV2 from "./Pool/v2";
import PoolFinder from "./PoolFinder";
import RemoveLiquidity from "./RemoveLiquidity";
import RemoveLiquidityV3 from "./RemoveLiquidity/V3";
import Swap from "./Swap";
import Bridge from "./Bridge";
import Index from "./Index";
import {
	OpenClaimAddressModalAndRedirectToSwap,
	RedirectPathToSwapOnly,
	RedirectToSwap,
} from "./Swap/redirects";
import Version from "components/Header/Version";
import { Flex } from "rebass";
import { Sidebar } from "components/Sidebar/Sidebar";

const Vote = lazy(() => import("./Vote"));

const AppWrapper = styled.div`
	display: flex;
	flex-flow: column;
	align-items: flex-start;
	min-height: 100vh;
	width: 100%;
	background: ${({ theme }) => theme.bg1};
`;

const BodyWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 120px 16px 0px 16px;
	align-items: center;
	flex: 1;
	z-index: 1;

	${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 6rem 16px 16px 16px;
  `};
`;

const HeaderWrapper = styled.div`
	${({ theme }) => theme.flexRowNoWrap}
	width: 100%;
	justify-content: space-between;
	position: fixed;
	top: 0;
	z-index: 2;
`;

const Marginer = styled.div`
	margin-top: 5rem;
`;

function TopLevelModals() {
	const open = useModalOpen(ApplicationModal.ADDRESS_CLAIM);
	const toggle = useToggleModal(ApplicationModal.ADDRESS_CLAIM);
	return <AddressClaimModal isOpen={open} onDismiss={toggle} />;
}

export default function App() {
	return (
		<ErrorBoundary>
			<Route component={GoogleAnalyticsReporter} />
			<Route component={DarkModeQueryParamReader} />
			<Route component={ApeModeQueryParamReader} />
			<Web3ReactManager>
				<>
					{/* <Sidebar /> */}
					<AppWrapper>
						<HeaderWrapper>
							<Header />
						</HeaderWrapper>
						<BodyWrapper>
							<Popups />
							<Polling />
							<Version />
							<TopLevelModals />
							<Suspense fallback={<Loader />}>
								<Switch>
									<Route exact strict path="/" component={Index} />

									{/* <Route strict path="/vote" component={Vote} />
								<Route exact strict path="/create-proposal">
									<Redirect to="/vote/create-proposal" />
								</Route> */}

									{/* <Route
									exact
									strict
									path="/claim"
									component={OpenClaimAddressModalAndRedirectToSwap}
								/> */}

									{showFarm && (
										<Route exact strict path="/farm" component={Earn} />
									)}

									{showFarm && (
										<Route exact strict path="/farm/:lp" component={Manage} />
									)}

									{showILO && (
										<Route exact strict path="/ilo" component={ILO} />
									)}

									<Route
										exact
										strict
										path="/send"
										component={RedirectPathToSwapOnly}
									/>
									<Route
										exact
										strict
										path="/swap/:outputCurrency"
										component={RedirectToSwap}
									/>
									<Route exact strict path="/swap" component={Swap} />

									<Route
										exact
										strict
										path="/pool/v2/find"
										component={PoolFinder}
									/>
									<Route exact strict path="/pool/v2" component={PoolV2} />
									<Route exact strict path="/pool" component={PoolV2} />
									<Route
										exact
										strict
										path="/pool/:tokenId"
										component={PositionPage}
									/>

									<Route
										exact
										strict
										path="/add/v2/:currencyIdA?/:currencyIdB?"
										component={RedirectDuplicateTokenIdsV2}
									/>
									<Route
										exact
										strict
										path="/add/:currencyIdA?/:currencyIdB?/:feeAmount?"
										component={RedirectDuplicateTokenIdsV2}
									/>

									<Route
										exact
										strict
										path="/increase/:currencyIdA?/:currencyIdB?/:feeAmount?/:tokenId?"
										component={AddLiquidity}
									/>

									<Route
										exact
										strict
										path="/remove/v2/:currencyIdA/:currencyIdB"
										component={RemoveLiquidity}
									/>
									<Route
										exact
										strict
										path="/remove/:tokenId"
										component={RemoveLiquidity}
									/>

									{showWhitepaper && (
										<Route
											exact
											strict
											path="/whitepaper"
											component={Whitepaper}
										/>
									)}

									<Route exact strict path="/bridge" component={Bridge} />

									{/* <Route exact strict path="/migrate/v2" component={MigrateV2} />
								<Route
									exact
									strict
									path="/migrate/v2/:address"
									component={MigrateV2Pair}
								/> */}

									<Route component={RedirectPathToSwapOnly} />
								</Switch>
							</Suspense>
							<Marginer />
						</BodyWrapper>
					</AppWrapper>
				</>
			</Web3ReactManager>
		</ErrorBoundary>
	);
}
