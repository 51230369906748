import { parseUnits } from "@ethersproject/units";
import { Trans } from "lib/trans";
import { Currency, CurrencyAmount, Token } from "@uniswap/sdk-core";
import { SupportedChainId } from "constants/chains";
import { NYAN, ZIP } from "constants/tokens";
import {
	useNormalV2RouterContract,
	useV2RouterContract,
} from "hooks/useContract";
import JSBI from "jsbi";
import { useContractValue, useContractValues } from "pages/ILO/tools";
import { useMemo } from "react";
import styled from "styled-components/macro";
import { orderTokens } from "utils/orderTokens";
import { BigNumber } from "@ethersproject/bignumber";
import { utils } from "ethers";

import {
	BIG_INT_SECONDS_IN_WEEK,
	BIG_INT_SECONDS_IN_YEAR,
} from "../../constants/misc";
import { useColor } from "../../hooks/useColor";
import { useTotalSupply } from "../../hooks/useTotalSupply";
import useUSDCPrice from "../../hooks/useUSDCPrice";
import { useV2Pair } from "../../hooks/useV2Pairs";
import { StakingInfo, useDerivedStakeInfo } from "../../state/stake/hooks";
import { StyledInternalLink, TYPE } from "../../theme";
import { currencyId } from "../../utils/currencyId";
import { unwrappedToken } from "../../utils/unwrappedToken";
import { ButtonPrimary } from "../Button";
import { AutoColumn } from "../Column";
import DoubleCurrencyLogo from "../DoubleLogo";
import { RowBetween } from "../Row";
import { Break, CardBGImage } from "./styled";
import { WETH9_EXTENDED } from "constants/tokens";
import { LightCard } from "components/Card";
import CurrencyLogo from "components/CurrencyLogo";
import { Text } from "rebass";
import { PairData } from "pages/Earn";
import { roundCurrency } from "lib/ui";

const StatContainer = styled.div`
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	gap: 12px;
	margin-bottom: 1rem;
	margin-right: 1rem;
	margin-left: 1rem;
	${({ theme }) => theme.mediaWidth.upToSmall`
  display: none;
`};
`;

const Wrapper = styled(AutoColumn)<{
	showBackground: boolean;
	bgColor: any;
	borderColor: any;
}>`
	padding: 0rem 0.25rem;
	border-radius: 12px;
	width: 100%;
	overflow: hidden;
	position: relative;
	opacity: ${({ showBackground }) => (showBackground ? "1" : "1")};
	background: ${({ theme }) => theme.bg0};
	border: 2px solid ${({ theme, borderColor }) => borderColor ?? theme.bg2};
	color: ${({ theme, showBackground }) =>
		showBackground ? theme.text1 : theme.text1} !important;

	/* ${({ showBackground }) =>
		showBackground &&
		`  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);`}; */
`;

const TopSection = styled.div`
	display: grid;
	grid-template-columns: 48px 1fr 120px;
	grid-gap: 0px;
	align-items: center;
	padding: 1rem;
	z-index: 1;
	${({ theme }) => theme.mediaWidth.upToSmall`
    grid-template-columns: 48px 1fr 96px;
  `};
`;

const BottomSection = styled.div<{ showBackground: boolean }>`
	padding: 12px 16px;
	opacity: ${({ showBackground }) => (showBackground ? "1" : "0.4")};
	border-radius: 0 0 12px 12px;
	display: flex;
	flex-direction: row;
	align-items: baseline;
	justify-content: space-between;
	z-index: 1;
`;

const Divider = styled.div`
	width: 100% + 0.7rem;
	height: 2px;
	margin: 0.1rem -0.35rem;
	background: ${({ theme }) => theme.bg2};
`;

const toBN = (amount: CurrencyAmount<Currency>) => {
	const decimals = amount.currency.decimals;
	return parseUnits(amount.toExact(), decimals);
};

const decimal = BigNumber.from(10).pow(18);

const WETH = WETH9_EXTENDED[SupportedChainId.OPTIMISM];

export default function PoolCard({
	stakingInfo,
	pairData,
	borderColor,
}: {
	stakingInfo: StakingInfo;
	pairData?: PairData;
	borderColor?: string;
}) {
	const [token0, token1] = useMemo(
		() => (stakingInfo.tokens ? orderTokens(stakingInfo.tokens) : [null, null]),
		[stakingInfo]
	);

	const currency0 = unwrappedToken(token0 ?? stakingInfo.lp);
	const currency1 = token1 ? unwrappedToken(token1) : null;

	const isStaking = Boolean(stakingInfo.stakedAmount.greaterThan("0"));

	// get the color of the token
	const token = (
		currency1 ? (currency0.isNative ? token1 : token0) : stakingInfo.lp
	)!;

	const backgroundColor = useColor(token);

	const totalSupplyOfStakingToken = useTotalSupply(
		stakingInfo.stakedAmount.currency
	);

	const [, stakingTokenPair] = useV2Pair(
		...(stakingInfo.tokens ? stakingInfo.tokens : [stakingInfo.lp, WETH])
	);

	// let returnOverMonth: Percent = new Percent('0')
	let valueOfTotalStakedAmountInWETH: CurrencyAmount<Token> | undefined;

	if (totalSupplyOfStakingToken && stakingTokenPair) {
		if (stakingInfo.tokens) {
			// take the total amount of LP tokens staked, multiply by ETH value of all LP tokens, divide by all LP tokens
			valueOfTotalStakedAmountInWETH = CurrencyAmount.fromRawAmount(
				WETH,
				JSBI.divide(
					JSBI.multiply(
						JSBI.multiply(
							stakingInfo.totalStakedAmount.quotient,
							stakingTokenPair.reserveOf(WETH).quotient
						),
						JSBI.BigInt(2) // this is b/c the value of LP shares are ~double the value of the WETH they entitle owner to
					),
					totalSupplyOfStakingToken.quotient
				)
			);
		} else {
			// take the total amount of LP tokens staked, multiply by ETH value of all LP tokens, divide by all LP tokens
			valueOfTotalStakedAmountInWETH = CurrencyAmount.fromRawAmount(
				WETH,
				JSBI.divide(
					JSBI.multiply(
						JSBI.multiply(
							stakingInfo.totalStakedAmount.quotient,
							stakingTokenPair.reserveOf(WETH).quotient
						),
						JSBI.BigInt(1) // this is b/c the value of LP shares are ~double the value of the WETH they entitle owner to
					),
					stakingTokenPair.reserveOf(token0 ?? stakingInfo.lp).quotient
				)
			);
		}
	}

	const router = useNormalV2RouterContract();

	const WETHPerZip: BigNumber[] | undefined = useContractValue(
		router,
		"getAmountsOut",
		[decimal, [ZIP[SupportedChainId.OPTIMISM].address, WETH.address]]
	);

	const extraArgs = useMemo(
		() =>
			stakingInfo.doubleReward
				? stakingInfo.doubleReward.tokens.map(({ decimals, address }) => [
						BigNumber.from(10).pow(decimals),
						[address, WETH.address],
				  ])
				: [],
		[stakingInfo]
	);

	const extraPerToken: (BigNumber[] | undefined)[] = useContractValues(
		router,
		"getAmountsOut",
		extraArgs
	);

	const totalPerToken = useMemo(
		() => [WETHPerZip, ...extraPerToken],
		[WETHPerZip, extraPerToken]
	);

	const APR = useMemo(() => {
		if (!stakingInfo.totalRewardRates || !valueOfTotalStakedAmountInWETH)
			return [];

		return totalPerToken.map((perZIP, i) => {
			if (!perZIP) return undefined;

			const rewardsInAYear = toBN(
				stakingInfo.totalRewardRates[i].multiply(BIG_INT_SECONDS_IN_YEAR)
			);

			const stakeValue = toBN(valueOfTotalStakedAmountInWETH!);

			if (stakeValue.eq(0)) {
				return "0";
			}

			const perZip = perZIP[perZIP.length - 1];

			const totalWethEmissions = rewardsInAYear.mul(perZip);

			return (
				parseFloat(utils.formatEther(totalWethEmissions.div(stakeValue))) * 100
			).toFixed(2);
		});
	}, [
		stakingInfo.totalRewardRates,
		valueOfTotalStakedAmountInWETH,
		totalPerToken,
	]);

	const annualizedAPR = useMemo(
		() =>
			pairData
				? Math.abs(
						((parseFloat(pairData.oneDayVolumeUSD.toString()) * 0.003 * 365) /
							parseFloat(pairData.reserveUSD.toString())) *
							100
				  ).toFixed(2)
				: undefined,
		[pairData]
	);

	return (
		<Wrapper showBackground bgColor={backgroundColor} borderColor={borderColor}>
			{/* <CardBGImage desaturate /> */}

			<TopSection>
				<div
					style={{
						marginLeft: currency1 ? "0.825rem" : "",
					}}
				>
					{currency1 ? (
						<DoubleCurrencyLogo
							currency0={currency0}
							currency1={currency1}
							size={24}
						/>
					) : (
						<CurrencyLogo currency={currency0} size="24px" />
					)}
				</div>

				<Text
					fontWeight={600}
					fontSize={24}
					marginLeft={!currency1 ? "-0.825rem" : ""}
				>
					{currency1
						? `${currency0.symbol}-${currency1.symbol}`
						: currency0.symbol}
				</Text>

				<StyledInternalLink
					to={`/farm/${stakingInfo.lp.address}`}
					style={{ width: "100%" }}
				>
					<ButtonPrimary padding="8px" $borderRadius="8px">
						{isStaking ? (
							<Trans>Manage</Trans>
						) : (
							<Trans>{stakingInfo.active ? "Deposit" : "Withdraw"}</Trans>
						)}
					</ButtonPrimary>
				</StyledInternalLink>
			</TopSection>

			{!stakingInfo.active && (
				<StatContainer>
					<Text>
						<Trans>Farming pool ended.</Trans>
					</Text>
				</StatContainer>
			)}

			{stakingInfo.active && (
				<StatContainer>
					{APR[0] && (
						<RowBetween>
							<Text>
								<Trans>ZIP APR</Trans>
							</Text>
							<Text>{APR[0]}%</Text>
						</RowBetween>
					)}
					{APR.slice(1)
						.filter(
							(apr, i) =>
								apr !== undefined &&
								stakingInfo.doubleReward?.tokens[i] !== undefined
						)
						.map((apr, i) => (
							<RowBetween
								key={`${stakingInfo.doubleReward!.tokens[i].symbol}-apr`}
							>
								<Text>
									<Trans>
										{stakingInfo.doubleReward!.tokens[i].symbol} APR
									</Trans>
								</Text>
								<Text>{apr}%</Text>
							</RowBetween>
						))}

					{annualizedAPR && (
						<RowBetween>
							<Text>
								<Trans>Fees Annualized (Pool APR)</Trans>
							</Text>
							<Text>{annualizedAPR}%</Text>
						</RowBetween>
					)}

					<RowBetween>
						<Text>
							<Trans>Total APR</Trans>
						</Text>
						<Text>
							{[...APR, annualizedAPR]
								.reduce((pv, cv) => pv + (cv ? parseFloat(cv) : 0), 0)
								.toFixed(2)}
							%
						</Text>
					</RowBetween>

					<Divider />

					{stakingInfo.lp !== NYAN[SupportedChainId.OPTIMISM] && (
						<RowBetween>
							<Text>
								<Trans>Total deposited</Trans>
							</Text>
							<Text>
								<Trans>
									{valueOfTotalStakedAmountInWETH?.toSignificant(4, {
										groupSeparator: ",",
									}) ?? "-"}{" "}
									ETH
								</Trans>
							</Text>
						</RowBetween>
					)}

					<RowBetween>
						<Text>
							<Trans>Pool rate</Trans>
						</Text>
						<Text>
							{stakingInfo ? (
								stakingInfo.active ? (
									<Trans>
										{roundCurrency(
											stakingInfo.totalRewardRates[0]?.multiply(
												BIG_INT_SECONDS_IN_WEEK
											)
										)}{" "}
										ZIP / week
									</Trans>
								) : (
									<Trans>0 ZIP / week</Trans>
								)
							) : (
								"-"
							)}
						</Text>
					</RowBetween>

					{stakingInfo && stakingInfo.doubleReward && (
						<RowBetween>
							<Text>
								<Trans>{stakingInfo.doubleReward.tokens[0].symbol} rate</Trans>
							</Text>
							<Text>
								{stakingInfo ? (
									stakingInfo.active ? (
										<Trans>
											{roundCurrency(
												stakingInfo.totalRewardRates[1]?.multiply(
													BIG_INT_SECONDS_IN_WEEK
												),
												2
											)}{" "}
											{stakingInfo.doubleReward.tokens[0].symbol} / week
										</Trans>
									) : (
										<Trans>
											0 {stakingInfo.doubleReward.tokens[0].symbol} / week
										</Trans>
									)
								) : (
									"-"
								)}
							</Text>
						</RowBetween>
					)}
				</StatContainer>
			)}

			{isStaking && (
				<>
					<Break />
					<BottomSection showBackground={true}>
						<Text fontWeight={500}>
							<span>
								<Trans>Your rate{stakingInfo.doubleReward ? "s" : ""}</Trans>
							</span>
						</Text>

						<div>
							<Text style={{ textAlign: "right" }} fontWeight={500}>
								<span
									role="img"
									aria-label="wizard-icon"
									style={{ marginRight: "0.5rem" }}
								>
									⚡
								</span>
								{stakingInfo ? (
									stakingInfo.active ? (
										<Trans>
											{stakingInfo.rewardRates[0]
												?.multiply(BIG_INT_SECONDS_IN_WEEK)
												?.toSignificant(4, { groupSeparator: "," })}{" "}
											ZIP / week
										</Trans>
									) : (
										<Trans>0 ZIP / week</Trans>
									)
								) : (
									"-"
								)}
							</Text>

							{stakingInfo.doubleReward && (
								<Text
									style={{ textAlign: "right" }}
									fontWeight={500}
									marginTop="0.45rem"
								>
									<span
										role="img"
										aria-label="wizard-icon"
										style={{ marginRight: "0.5rem" }}
									>
										⚡
									</span>
									{stakingInfo ? (
										stakingInfo.active ? (
											<Trans>
												{stakingInfo.rewardRates[1]
													?.multiply(BIG_INT_SECONDS_IN_WEEK)
													?.toSignificant(4, { groupSeparator: "," })}{" "}
												{stakingInfo.doubleReward.tokens[0].symbol} / week
											</Trans>
										) : (
											<Trans>
												{stakingInfo.doubleReward.tokens[0].symbol} / week
											</Trans>
										)
									) : (
										"-"
									)}
								</Text>
							)}
						</div>
					</BottomSection>
				</>
			)}
		</Wrapper>
	);
}
