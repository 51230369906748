import { Contract } from "@ethersproject/contracts";
import { useMemo } from "react";
import {
	useSingleCallResult,
	useSingleContractMultipleData,
} from "state/multicall/hooks";
import { BigNumber } from "@ethersproject/bignumber";

type MethodArg = string | number | BigNumber;

type OptionalMethodInputs =
	| Array<MethodArg | MethodArg[] | undefined>
	| undefined;

export const useContractValue = <T>(
	contract: Contract | null,
	func: string,
	inputs: OptionalMethodInputs = []
) => {
	const { result, valid } = useSingleCallResult(contract, func, inputs);

	return useMemo(
		() => (result && valid && (result[0] as T)) || undefined,
		[result, valid]
	);
};

export const useContractValues = <T>(
	contract: Contract | null,
	func: string,
	inputs: OptionalMethodInputs[] = []
) => {
	const states = useSingleContractMultipleData(contract, func, inputs);

	return useMemo(
		() =>
			states.map(
				({ result, valid }) =>
					(result && valid && (result[0] as T)) || undefined
			),
		[states]
	);
};

const cleanDecimalZeros = (cur: string) => {
	if (isNaN(parseFloat(cur))) return cur;

	const [left, right] = cur.split(".");

	if (!right) {
		return cur;
	}

	let i = 0;

	for (i = right.length - 1; i >= 0; i--) {
		if (right[i] !== "0") {
			break;
		}
	}

	i += 1;

	return `${left}${i > 0 ? "." : ""}${right.substring(0, i)}`;
};

export const toCommaNumber = (cur: string) => {
	if (isNaN(parseFloat(cur))) return cur;

	const [left, right] = cur.split(".");
	let str = "";

	for (let i = left.length - 1; i >= 0; i--) {
		if ((left.length - 1 - i) % 3 === 0 && i !== left.length - 1) {
			str += ",";
		}

		str += left[i];
	}

	return cleanDecimalZeros(
		`${str.split("").reverse().join("")}${right ? `.${right}` : ""}`
	);
};
