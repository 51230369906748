import { TransactionResponse } from "@ethersproject/providers";
import { Trans } from "lib/trans";
import { ReactNode, useState } from "react";
import styled from "styled-components/macro";
import { extraGas } from "utils/gas";

import { useStakingContract } from "../../hooks/useContract";
import { useActiveWeb3React } from "../../hooks/web3";
import { StakingInfo } from "../../state/stake/hooks";
import { TransactionType } from "../../state/transactions/actions";
import { useTransactionAdder } from "../../state/transactions/hooks";
import { CloseIcon, TYPE } from "../../theme";
import { ButtonError } from "../Button";
import { AutoColumn } from "../Column";
import FormattedCurrencyAmount from "../FormattedCurrencyAmount";
import Modal from "../Modal";
import { LoadingView, SubmittedView } from "../ModalViews";
import { RowBetween } from "../Row";

const ContentWrapper = styled(AutoColumn)`
	width: 100%;
	padding: 1rem;
`;

interface StakingModalProps {
	isOpen: boolean;
	onDismiss: () => void;
	stakingInfo: StakingInfo;
	stakingRewardAddress: string;
}

export default function UnstakingModal({
	isOpen,
	onDismiss,
	stakingInfo,
	stakingRewardAddress,
}: StakingModalProps) {
	const { account } = useActiveWeb3React();

	// monitor call to help UI loading state
	const addTransaction = useTransactionAdder();
	const [hash, setHash] = useState<string | undefined>();
	const [attempting, setAttempting] = useState(false);

	function wrappedOndismiss() {
		setHash(undefined);
		setAttempting(false);
		onDismiss();
	}

	const stakingContract = useStakingContract(stakingRewardAddress);

	async function onWithdraw() {
		if (stakingContract && stakingInfo?.stakedAmount) {
			setAttempting(true);

			type Params = Parameters<typeof stakingContract.withdrawAllAndHarvest>;

			const params: Params = [stakingInfo.pid];

			return stakingContract.estimateGas
				.withdrawAllAndHarvest(...params)
				.then((gas) => {
					stakingContract
						.withdrawAllAndHarvest(
							...([...params, { gasLimit: extraGas(gas) }] as unknown as Params)
						)
						.then((response: TransactionResponse) => {
							addTransaction(response, {
								type: TransactionType.WITHDRAW_LIQUIDITY_STAKING,
								lp: stakingInfo.lp.address,
							});
							setHash(response.hash);
						})
						.catch((error: any) => {
							setAttempting(false);
							console.log(error);
						});
				})
				.catch((error: any) => {
					setAttempting(false);
					console.log(error);
				});
		}
	}

	let error: ReactNode | undefined;
	if (!account) {
		error = <Trans>Connect a wallet</Trans>;
	}
	if (!stakingInfo?.stakedAmount) {
		error = error ?? <Trans>Enter an amount</Trans>;
	}

	return (
		<Modal isOpen={isOpen} onDismiss={wrappedOndismiss} maxHeight={90}>
			{!attempting && !hash && (
				<ContentWrapper gap="lg">
					<RowBetween>
						<TYPE.mediumHeader>
							<Trans>Withdraw</Trans>
						</TYPE.mediumHeader>
						<CloseIcon onClick={wrappedOndismiss} />
					</RowBetween>
					{stakingInfo?.stakedAmount && (
						<AutoColumn justify="center" gap="md">
							<TYPE.body fontWeight={600} fontSize={36}>
								{
									<FormattedCurrencyAmount
										currencyAmount={stakingInfo.stakedAmount}
									/>
								}
							</TYPE.body>
							<TYPE.body>
								<Trans>Deposited liquidity:</Trans>
							</TYPE.body>
						</AutoColumn>
					)}
					{stakingInfo?.earnedAmounts[0] && (
						<AutoColumn justify="center" gap="md">
							<TYPE.body fontWeight={600} fontSize={36}>
								{
									<FormattedCurrencyAmount
										currencyAmount={stakingInfo?.earnedAmounts[0]}
									/>
								}
							</TYPE.body>
							<TYPE.body>
								<Trans>Unclaimed ZIP</Trans>
							</TYPE.body>
						</AutoColumn>
					)}
					{stakingInfo?.earnedAmounts[1] && stakingInfo?.doubleReward && (
						<AutoColumn justify="center" gap="md">
							<TYPE.body fontWeight={600} fontSize={36}>
								{
									<FormattedCurrencyAmount
										currencyAmount={stakingInfo?.earnedAmounts[1]}
									/>
								}
							</TYPE.body>
							<TYPE.body>
								<Trans>
									Unclaimed {stakingInfo.doubleReward.tokens[0].symbol}
								</Trans>
							</TYPE.body>
						</AutoColumn>
					)}
					<TYPE.subHeader style={{ textAlign: "center" }}>
						<Trans>
							When you withdraw, your ZIP is claimed and your liquidity is
							removed from the mining pool.
						</Trans>
					</TYPE.subHeader>
					<ButtonError
						disabled={!!error}
						error={!!error && !!stakingInfo?.stakedAmount}
						onClick={onWithdraw}
					>
						{error ?? <Trans>Withdraw & Claim</Trans>}
					</ButtonError>
				</ContentWrapper>
			)}
			{attempting && !hash && (
				<LoadingView onDismiss={wrappedOndismiss}>
					<AutoColumn gap="12px" justify={"center"}>
						<TYPE.body fontSize={20}>
							<Trans>
								Withdrawing {stakingInfo?.stakedAmount?.toSignificant(4)}{" "}
								{stakingInfo.tokens ? "ZIP-V2" : stakingInfo.lp.symbol}
							</Trans>
						</TYPE.body>
						<TYPE.body fontSize={20}>
							<Trans>
								Claiming {stakingInfo?.earnedAmounts[0]?.toSignificant(4)} ZIP
							</Trans>
						</TYPE.body>
					</AutoColumn>
				</LoadingView>
			)}
			{hash && (
				<SubmittedView onDismiss={wrappedOndismiss} hash={hash}>
					<AutoColumn gap="12px" justify={"center"}>
						<TYPE.largeHeader>
							<Trans>Transaction Submitted</Trans>
						</TYPE.largeHeader>
						<TYPE.body fontSize={20}>
							<Trans>
								Withdrew {stakingInfo.tokens ? "ZIP-V2" : stakingInfo.lp.symbol}
								!
							</Trans>
						</TYPE.body>
						<TYPE.body fontSize={20}>
							<Trans>Claimed ZIP!</Trans>
						</TYPE.body>
						{stakingInfo.doubleReward && (
							<TYPE.body fontSize={20}>
								<Trans>
									Claimed {stakingInfo.doubleReward.tokens[0].symbol}!
								</Trans>
							</TYPE.body>
						)}
					</AutoColumn>
				</SubmittedView>
			)}
		</Modal>
	);
}
