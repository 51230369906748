import { JsonRpcBatchProvider } from "@ethersproject/providers";
import { Currency, CurrencyAmount, Token, TradeType, computePriceImpact} from "@uniswap/sdk-core";
import { computePairAddress, Pair, Route, Trade } from "@uniswap/v2-sdk";
import { BigNumber, ethers } from "ethers";
import { useMemo } from "react";
import {
	useSingleContractMultipleData,
	CallState,
} from "state/multicall/hooks";
import { toV2LiquidityToken, useTrackedTokenPairs } from "state/user/hooks";
import { isTradeBetter } from "utils/isTradeBetter";
import { useKVStore } from "utils/kvstore";

import { BETTER_TRADE_LESS_HOPS_THRESHOLD } from "../constants/misc";
import { useAllCurrencyCombinations } from "./useAllCurrencyCombinations";
import {
	useFactoryContract,
	useNormalV2RouterContract,
	useV2RouterContract,
	useAmountsQuoter,
} from "./useContract";
import { useFactoryPairs } from "./useFactoryPairs";
import { PairState, useV2Pairs } from "./useV2Pairs";

function useAllCommonPairs(currencyA?: Currency, currencyB?: Currency): Pair[] {
	const allCurrencyCombinations = useAllCurrencyCombinations(
		currencyA,
		currencyB
	);

	const allPairs = useV2Pairs(allCurrencyCombinations);

	return useMemo(
		() =>
			Object.values(
				allPairs
					// filter out invalid pairs
					.filter((result): result is [PairState.EXISTS, Pair] =>
						Boolean(result[0] === PairState.EXISTS && result[1])
					)
					.map(([, pair]) => pair)
			),
		[allPairs]
	);
}

const MAX_HOPS = 3;
declare const window: any;

/**
 * Returns the best v2 trade for a desired swap
 * @param tradeType whether the swap is an exact in/out
 * @param amountSpecified the exact amount to swap in/out
 * @param otherCurrency the desired output/payment currency
 */
export function useBestV2Trade(
	tradeType: TradeType.EXACT_INPUT | TradeType.EXACT_OUTPUT,
	amountSpecified?: CurrencyAmount<Currency>,
	otherCurrency?: Currency,
	{ maxHops = MAX_HOPS } = {}
): Trade<
	Currency,
	Currency,
	TradeType.EXACT_INPUT | TradeType.EXACT_OUTPUT
> | null {
	const [currencyIn, currencyOut] = useMemo(
		() =>
			tradeType === TradeType.EXACT_INPUT
				? [amountSpecified?.currency, otherCurrency]
				: [otherCurrency, amountSpecified?.currency],
		[tradeType, amountSpecified, otherCurrency]
	);

	const factory = useFactoryContract();
	const factoryPairs = useFactoryPairs(); //5 ETH-ZIP
	const pairs = useV2Pairs(factoryPairs); 

	/*
		1. find all sensible routes of length up to 3
		2. calculate outputs for all of them using getAmountsOut/getAmountsIn in amountsQuoter
		3. zip-eth pair is returned as a uni2 pair, but when generating the actual swap it's replaced by a call to the BuySupport pool
	*/
	const amountsQuoter = useAmountsQuoter();

	function mapSetPush(map : Map<string, Set<string>>, key : string, what : string) {
		let x = map.get(key);
		if(!x) {
			map.set(key, new Set([what]));
		}
		else {
			x.add(what);
		}
	}

	//[token] => [all tokens it has a uni2 pair with]
	const tokenPairedTokens : Map<string, Set<string>> = useMemo(
		() => {
			let tokenPairedTokens = new Map<string, Set<string>>();
			for(let factoryPair of factoryPairs) {
				let [token0Address, token1Address] = [factoryPair[0].wrapped.address, factoryPair[1].wrapped.address];
				mapSetPush(tokenPairedTokens, token0Address, token1Address);
				mapSetPush(tokenPairedTokens, token1Address, token0Address);
			}
			return tokenPairedTokens;
		}, [factoryPairs]);

	function mapMapSet<T>(map : Map<string, Map<string, T>>, key0 : string, key1 : string, what : T) {
		let x = map.get(key0);
		if(!x) {
			x = new Map();
			map.set(key0, x);
		}
		x.set(key1, what);
	}

	const tokenTokenPairMap = useMemo(
		() => {
			let tokenTokenPairMap : Map<string, Map<string, Pair>> = new Map();
			for(let [pairState, pair] of pairs) {
				if (pairState === PairState.EXISTS && pair) {
					let [token0Address, token1Address] = [pair.token0.address, pair.token1.address];
					mapMapSet(tokenTokenPairMap, token0Address, token1Address, pair);
					mapMapSet(tokenTokenPairMap, token1Address, token0Address, pair);
				}
			}
			return tokenTokenPairMap;
		},
		[pairs]
	);

	//now find all routes from currencyIn to currencyOut up to MAX_HOPS
	//use BFS
	const sensiblePaths = useMemo(() => {
		if (!factory) return [];
		if (!tokenPairedTokens || tokenPairedTokens.size === 0) return [];
		if (!amountSpecified || !currencyIn || !currencyOut) return [];
		const inAddress : string = currencyIn.wrapped.address;
		const outAddress : string = currencyOut.wrapped.address;
		if (!tokenPairedTokens.get(inAddress)) return [];
		if (amountSpecified.equalTo(0)) return [];

		let tokenPaths : Array<Array<string>> = [];
		type SingleLinkedList<T> = [T] | [T, SingleLinkedList<T>];
		let currentLevel : SingleLinkedList<string>[] = [[inAddress]];
		//this is a reversed single linked list to keep memory O(n)
		// [[A, [B, [C]]]] means C -> B -> A swap path
		for(let i = 0; i < MAX_HOPS; i++) {
			let nextLevel : SingleLinkedList<string>[] = [];
			for(let currentPath of currentLevel) {
				const lastCurrentLevelTokenAddress : string = currentPath[0];
				//add a path that ends at the current level with outAddress
				//as because Set always exists
				const tokensPairedWithLast : Set<string> = tokenPairedTokens.get(lastCurrentLevelTokenAddress) as Set<string>;
				if(tokensPairedWithLast.has(outAddress)) {
					tokenPaths.push(<[string]>([outAddress, currentPath].flat(MAX_HOPS)).reverse());
				}
				if(i < MAX_HOPS-1) { //there's next iteration
					//this is needed to prevent creating cycles that return to the same token
					const currentPathVisited : Set<string> = new Set(<[string]>([outAddress, currentPath].flat(MAX_HOPS)));
					for(const accessibleToken of tokensPairedWithLast) {
						if(!currentPathVisited.has(accessibleToken)) {
							nextLevel.push([accessibleToken, currentPath]);
						}
					}
				}
			}
			currentLevel = nextLevel;
		}
		return tokenPaths;
	}, [tokenPairedTokens, amountSpecified, currencyIn, currencyOut, factory]);

	const pathData = useMemo(
		() =>
			!amountSpecified || amountSpecified.equalTo(0)
				? []
				: sensiblePaths.map(path => [ethers.utils.parseUnits(
					amountSpecified.toExact(),
					currencyIn?.decimals ?? 18
				), path]),
		[amountSpecified, sensiblePaths, currencyIn?.decimals]
	);

	const results = useSingleContractMultipleData(
		amountsQuoter,
		tradeType === TradeType.EXACT_INPUT ? "getAmountsOut" : "getAmountsIn",
		pathData
	);

	const possibleResults : [string[], { amounts: BigNumber[]; }][] = useMemo(
		() =>
			results
				.map<[string[], { amounts: BigNumber[] }, CallState]>((state, i) => [
					sensiblePaths[i],
					(state.result ?? {}) as unknown as { amounts: BigNumber[] },
					state,
				])
				.filter(([, , { error, result, valid }]) => !error && result && valid)
				.map(([path, amounts, callstate]) => [path, amounts]),
		[sensiblePaths, results]
	);

	const bestResults = useMemo(
		() =>
			possibleResults.sort(([, { amounts: aa }], [, { amounts: ab }]) => {
				if(tradeType === TradeType.EXACT_INPUT) {
					const amountA = aa[aa.length - 1];
					const amountB = ab[ab.length - 1];

					if (amountA.gt(amountB)) return -1;
					if (amountA.lt(amountB)) return 1;
					return 0;
				}
				else {
					const amountA = aa[0];
					const amountB = ab[0];

					if (amountA.gt(amountB)) return 1;
					if (amountA.lt(amountB)) return -1;
					return 0;
				}
			}),
		[possibleResults]
	);

	/*if(pathData.length !== 0) {
		//console.log("pathData", pathData);
		//console.log("bestResults", bestResults);
		window.bestResults = bestResults;
	}*/

	return useMemo(() => {
		if (bestResults.length === 0) return null;
		if (!currencyIn || !currencyOut || !amountSpecified) return null;

		const tokenPath = bestResults[0][0];
		if(!tokenPath) return null;

		let path: Pair[] = [];

		for (let i = 0; i < tokenPath.length - 1; i++) {
			const curToken = tokenPath[i];
			const nextToken = tokenPath[i + 1];

			const newPair = <Pair>tokenTokenPairMap.get(curToken)?.get(nextToken);
			path.push(newPair);
		}

		if(tradeType === TradeType.EXACT_INPUT) {
			let r = Trade.exactIn(new Route(path, currencyIn, currencyOut), amountSpecified);
			//console.log("trade.outputAmount before", r.outputAmount);
			let amounts = bestResults[0][1].amounts;
			//JSBI.BigInt(amounts[amounts.length-1].toString())
			let amountOut = CurrencyAmount.fromFractionalAmount(r.route.output, amounts[amounts.length-1].toString(), '1');
			(<any>r).outputAmount = amountOut;
			(<any>r).priceImpact = computePriceImpact(r.route.midPrice, r.inputAmount, r.outputAmount);
			return r;
		}
		else {
			let r = Trade.exactOut(new Route(path, currencyIn, currencyOut), amountSpecified);
			let amounts = bestResults[0][1].amounts;
			let amountIn = CurrencyAmount.fromFractionalAmount(r.route.input, amounts[0].toString(), '1');
			(<any>r).inputAmount = amountIn;
			(<any>r).priceImpact = computePriceImpact(r.route.midPrice, r.inputAmount, r.outputAmount);
			return r;
		}
	}, [amountSpecified, bestResults, currencyIn, currencyOut]);
}
