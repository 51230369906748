import { useContext } from "react";
import { AlertCircle, CheckCircle } from "react-feather";
import styled, { ThemeContext } from "styled-components/macro";

import { useActiveWeb3React } from "../../hooks/web3";
import { useTransaction } from "../../state/transactions/hooks";
import { TYPE } from "../../theme";
import { ExternalLink } from "../../theme/components";
import { ExplorerDataType, getExplorerLink } from "../../utils/getExplorerLink";
import { TransactionSummary } from "../AccountDetails/TransactionSummary";
import { AutoColumn } from "../Column";
import { AutoRow } from "../Row";

const RowNoFlex = styled(AutoRow)`
  flex-wrap: nowrap;
`;

export default function TransactionPopup({ hash }: { hash: string }) {
  const { chainId } = useActiveWeb3React();

  const tx = useTransaction(hash);
  const theme = useContext(ThemeContext);

  if (!tx) return null;
  const success = Boolean(tx.receipt && tx.receipt.status === 1);

  return (
    <RowNoFlex>
      <div style={{ paddingRight: 16 }}>
        {success ? (
          <CheckCircle color={theme.green1} size={24} />
        ) : (
          <AlertCircle color={theme.red1} size={24} />
        )}
      </div>
      <AutoColumn gap="8px">
        <TYPE.body fontWeight={500}>
          <TransactionSummary info={tx.info} />
        </TYPE.body>
        {chainId && (
          <ExternalLink
            href={getExplorerLink(chainId, hash, ExplorerDataType.TRANSACTION)}
          >
            View on Explorer
          </ExternalLink>
        )}
      </AutoColumn>
    </RowNoFlex>
  );
}
