import _ from "lodash";
import create from "zustand";

const localStorageKey = "zipswap-site-settings";

export type Settings = {
	audio: boolean;
	setAudio: (newAudio: boolean) => void;
};

export const useSettings = create<Settings>((set) => ({
	audio: true,
	setAudio: (audio) => set({ audio }),
}));

useSettings.subscribe((newSettings) => {
	const realObj: Record<string, any> = {};

	for (const key of _.keys(newSettings)) {
		const val = newSettings[key as keyof typeof newSettings];
		const type = typeof val;

		if (
			type === "number" ||
			type === "boolean" ||
			type === "string" ||
			type === "undefined"
		) {
			realObj[key] = val;
		}
	}

	localStorage.setItem(localStorageKey, JSON.stringify(realObj));
});

try {
	const data = localStorage.getItem(localStorageKey) ?? "{}";
	const json = JSON.parse(data);
	useSettings.setState(json);
} catch (e) {}
