import { BigNumber } from "@ethersproject/bignumber";
import { parseUnits } from "@ethersproject/units";
// eslint-disable-next-line no-restricted-imports
import { t, Trans } from "@lingui/macro";
import {
	Currency,
	CurrencyAmount,
	Percent,
	Token,
	TradeType,
} from "@uniswap/sdk-core";
import { Router, Trade as V2Trade } from "@uniswap/v2-sdk";
import { SwapRouter, Trade as V3Trade } from "@uniswap/v3-sdk";
import { SupportedChainId } from "constants/chains";
import {
	generatePath,
	roundSlippage,
	swapETHForExactTokens,
	swapExactETHForTokens,
	swapExactETHForTokensSupportingFeeOnTransferTokens,
	swapExactTokensForETH,
	swapExactTokensForETHSupportingFeeOnTransferTokens,
	swapExactTokensForTokens,
	swapExactTokensForTokensSupportingFeeOnTransferTokens,
	swapTokensForExactETH,
	swapTokensForExactTokens,
} from "pages/AddLiquidityV2/tools";
import { ReactNode, useMemo } from "react";
import { extraGas } from "utils/gas";
import { useAsyncMemo } from "utils/memo";

import {
	SWAP_ROUTER_ADDRESSES,
	V2_ROUTER_ADDRESS,
} from "../constants/addresses";
import { TransactionType } from "../state/transactions/actions";
import { useTransactionAdder } from "../state/transactions/hooks";
import approveAmountCalldata from "../utils/approveAmountCalldata";
import { calculateGasMargin } from "../utils/calculateGasMargin";
import { currencyId } from "../utils/currencyId";
import isZero from "../utils/isZero";
import { useArgentWalletContract } from "./useArgentWalletContract";
import { useV2RouterContract } from "./useContract";
import useENS from "./useENS";
import { SignatureData } from "./useERC20Permit";
import useTransactionDeadline from "./useTransactionDeadline";
import { useActiveWeb3React } from "./web3";

enum SwapCallbackState {
	INVALID,
	LOADING,
	VALID,
}

interface SwapCall {
	address: string;
	calldata: string;
	value: string;
}

interface SwapCallEstimate {
	call: SwapCall;
}

interface SuccessfulCall extends SwapCallEstimate {
	call: SwapCall;
	gasEstimate: BigNumber;
}

interface FailedCall extends SwapCallEstimate {
	call: SwapCall;
	error: Error;
}

/**
 * This is hacking out the revert reason from the ethers provider thrown error however it can.
 * This object seems to be undocumented by ethers.
 * @param error an error from the ethers provider
 */
function swapErrorToUserReadableMessage(error: any): ReactNode {
	let reason: string | undefined;
	while (Boolean(error)) {
		reason = error.reason ?? error.message ?? reason;
		error = error.error ?? error.data?.originalError;
	}

	if (reason?.indexOf("execution reverted: ") === 0)
		reason = reason.substr("execution reverted: ".length);

	switch (reason) {
		case "UniswapV2Router: EXPIRED":
			return (
				<Trans>
					The transaction could not be sent because the deadline has passed.
					Please check that your transaction deadline is not too low.
				</Trans>
			);
		case "UniswapV2Router: INSUFFICIENT_OUTPUT_AMOUNT":
		case "UniswapV2Router: EXCESSIVE_INPUT_AMOUNT":
			return (
				<Trans>
					This transaction will not succeed either due to price movement or fee
					on transfer. Try increasing your slippage tolerance.
				</Trans>
			);
		case "TransferHelper: TRANSFER_FROM_FAILED":
			return (
				<Trans>
					The input token cannot be transferred. There may be an issue with the
					input token.
				</Trans>
			);
		case "UniswapV2: TRANSFER_FAILED":
			return (
				<Trans>
					The output token cannot be transferred. There may be an issue with the
					output token.
				</Trans>
			);
		case "UniswapV2: K":
			return (
				<Trans>
					The ZipSwap invariant x*y=k was not satisfied by the swap. This
					usually means one of the tokens you are swapping incorporates custom
					behavior on transfer.
				</Trans>
			);
		case "Too little received":
		case "Too much requested":
		case "STF":
			return (
				<Trans>
					This transaction will not succeed due to price movement. Try
					increasing your slippage tolerance. Note: fee on transfer and rebase
					tokens are incompatible with ZipSwap V3.
				</Trans>
			);
		case "TF":
			return (
				<Trans>
					The output token cannot be transferred. There may be an issue with the
					output token. Note: fee on transfer and rebase tokens are incompatible
					with ZipSwap V3.
				</Trans>
			);
		default:
			if (reason?.indexOf("undefined is not an object") !== -1) {
				console.error(error, reason);
				return (
					<Trans>
						An error occurred when trying to execute this swap. You may need to
						increase your slippage tolerance. If that does not work, there may
						be an incompatibility with the token you are trading. Note: fee on
						transfer and rebase tokens are incompatible with ZipSwap V3.
					</Trans>
				);
			}
			return (
				<Trans>
					Unknown error{reason ? `: "${reason}"` : ""}. Try increasing your
					slippage tolerance. Note: fee on transfer and rebase tokens are
					incompatible with ZipSwap V3.
				</Trans>
			);
	}
}

const toBN = (amount: CurrencyAmount<Currency>) => {
	const decimals = amount.currency.decimals;
	return parseUnits(amount.toExact(), decimals);
};

// returns a function that will execute a swap, if the parameters are all valid
// and the user has approved the slippage adjusted input amount for the trade
export function useSwapCallback(
	trade:
		| V2Trade<Currency, Currency, TradeType>
		| V3Trade<Currency, Currency, TradeType>
		| undefined, // trade to execute, required
	allowedSlippage: Percent, // in bips
	recipientAddressOrName: string | null, // the ENS name or address of the recipient of the trade, or null if swap should be returned to sender
	balanceIn: CurrencyAmount<Currency> | undefined,
	signatureData: SignatureData | undefined | null,
	enforceSlippage: boolean
): {
	state: SwapCallbackState;
	callback: null | (() => Promise<string>);
	error: ReactNode | null;
} {
	const { account, chainId, library } = useActiveWeb3React();

	const addTransaction = useTransactionAdder();

	//const { address: recipientAddress } = useENS(recipientAddressOrName);
	const recipient = recipientAddressOrName === null ? account : "0x00";

	return useMemo(() => {
		if (!trade || !library || !account || !chainId || !balanceIn) {
			return {
				state: SwapCallbackState.INVALID,
				callback: null,
				error: <Trans>Missing dependencies</Trans>,
			};
		}

		if (!recipient) {
			if (recipientAddressOrName !== null) {
				return {
					state: SwapCallbackState.INVALID,
					callback: null,
					error: <Trans>Invalid recipient</Trans>,
				};
			} else {
				return {
					state: SwapCallbackState.LOADING,
					callback: null,
					error: null,
				};
			}
		}

		return {
			state: SwapCallbackState.VALID,
			callback: async function onSwap(): Promise<string> {
				// let calldata = "0x00";
				let funcs: ((...params: any[]) => string)[] = [];
				let npath: BigNumber[] | BigNumber = [];
				let params: any[] = [];
				let value = "0x00";

				const path = (trade.route as any).path.map(
					({ address }: { address: string }) => address
				);
				const address = V2_ROUTER_ADDRESS[chainId];

				//replace pool id 5 (zip-eth) with 67 (BuySupport)
				async function getPoolFilter(token0 : string, token1 : string, standardFn : any) {
					let [poolAddress, _poolType, poolId] = await standardFn(token0, token1);
					if(poolId.eq(5)) {
						return ["0x0000000000026766063DA660704bf2d7d6b0f987", BigNumber.from(1), BigNumber.from(67)]
					}
					else return [poolAddress, _poolType, poolId];
				}

				if (trade.tradeType === TradeType.EXACT_INPUT) {
					const rawOut = toBN(trade.outputAmount);
					const minOut = enforceSlippage
						? toBN(trade.minimumAmountOut(allowedSlippage))
						: BigNumber.from(0);
					const use = toBN(trade.inputAmount);
					const max = balanceIn.equalTo(trade.inputAmount);
					const outRounded = enforceSlippage
						? roundSlippage(rawOut, minOut)
						: BigNumber.from(0);

					if (trade.inputAmount.currency.isNative) {
						params = [outRounded];
						funcs = [
							swapExactETHForTokens,
							swapExactETHForTokensSupportingFeeOnTransferTokens,
						];
						value = use.toHexString();
						npath = await generatePath(path, library, true, false, getPoolFilter);
					} else if (trade.outputAmount.currency.isNative) {
						params = [use, outRounded, max];
						funcs = [
							swapExactTokensForETH,
							swapExactTokensForETHSupportingFeeOnTransferTokens,
						];
						npath = await generatePath(path, library, false, true, getPoolFilter);
					} else {
						params = [use, outRounded, max];
						funcs = [
							swapExactTokensForTokens,
							swapExactTokensForTokensSupportingFeeOnTransferTokens,
						];
						npath = await generatePath(path, library, false, false, getPoolFilter);
					}
				} else {
					const maxUse = toBN(trade.maximumAmountIn(allowedSlippage));
					const out = toBN(trade.outputAmount);
					const max = balanceIn.equalTo(trade.maximumAmountIn(allowedSlippage));

					if (trade.inputAmount.currency.isNative) {
						params = [out];
						funcs = [swapETHForExactTokens];
						value = maxUse.toHexString();
						npath = await generatePath(path, library, true, false, getPoolFilter);
					} else if (trade.outputAmount.currency.isNative) {
						params = [out, maxUse, max];
						funcs = [swapTokensForExactETH];
						npath = await generatePath(path, library, false, true, getPoolFilter);
					} else {
						params = [out, maxUse, max];
						funcs = [swapTokensForExactTokens];
						npath = await generatePath(path, library, false, false, getPoolFilter);
					}
				}

				const swapError = (error: any) => {
					// if the user rejected the tx, pass this along
					if (error?.code === 4001) {
						throw new Error(t`Transaction rejected.`);
					} else {
						// otherwise, the error was unexpected and we need to convey that
						console.error(`Swap failed`, error, address, value);

						throw new Error(
							t`Swap failed: ${swapErrorToUserReadableMessage(error)}`
						);
					}
				};

				const signer = library.getSigner();
				const max = BigNumber.from(2).pow(255);

				const callDatas = funcs.map((func) => func(...params, npath));

				const gasEstimates = await Promise.all(
					callDatas.map(
						(calldata) =>
							new Promise<[string, BigNumber]>(async (resolve) => {
								try {
									const gas = await signer.estimateGas({
										from: account,
										to: address,
										data: calldata,
										value,
									});
									resolve([calldata, gas]);
								} catch (e) {
									resolve([calldata, max]);
								}
							})
					)
				);

				gasEstimates.sort(([_, a], [__, b]) => {
					if (a.gt(b)) return 1;
					if (b.gt(a)) return -1;
					return 0;
				});

				const [calldata, gas] = gasEstimates[0];

				if (gas.eq(max)) {
					swapError("No function passed");
					return "";
				}

				return signer
					.sendTransaction({
						from: account,
						to: address,
						data: calldata,
						value,
						gasLimit: extraGas(gas),
					})
					.then((response) => {
						addTransaction(
							response,
							trade.tradeType === TradeType.EXACT_INPUT
								? {
										type: TransactionType.SWAP,
										tradeType: TradeType.EXACT_INPUT,
										inputCurrencyId: currencyId(trade.inputAmount.currency),
										inputCurrencyAmountRaw:
											trade.inputAmount.quotient.toString(),
										expectedOutputCurrencyAmountRaw:
											trade.outputAmount.quotient.toString(),
										outputCurrencyId: currencyId(trade.outputAmount.currency),
										minimumOutputCurrencyAmountRaw: trade
											.minimumAmountOut(allowedSlippage)
											.quotient.toString(),
								  }
								: {
										type: TransactionType.SWAP,
										tradeType: TradeType.EXACT_OUTPUT,
										inputCurrencyId: currencyId(trade.inputAmount.currency),
										maximumInputCurrencyAmountRaw: trade
											.maximumAmountIn(allowedSlippage)
											.quotient.toString(),
										outputCurrencyId: currencyId(trade.outputAmount.currency),
										outputCurrencyAmountRaw:
											trade.outputAmount.quotient.toString(),
										expectedInputCurrencyAmountRaw:
											trade.inputAmount.quotient.toString(),
								  }
						);

						return response.hash;
					})
					.catch(swapError);
			},
			error: null,
		};
	}, [
		trade,
		library,
		account,
		chainId,
		recipient,
		recipientAddressOrName,
		addTransaction,
		allowedSlippage,
		balanceIn,
		enforceSlippage,
	]);
}
