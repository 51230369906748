import { Token } from "@uniswap/sdk-core";
import { BigNumber } from "ethers";

export const orderTokens = (tokens: Token[]) => {
	const toSort = tokens.map<[Token, BigNumber]>((token) => [
		token,
		BigNumber.from(token.address),
	]);
	toSort.sort(([, a], [, b]) => {
		if (a.gt(b)) return 1;
		if (a.lt(b)) return -1;
		return 0;
	});
	return toSort.map(([token]) => token);
};
