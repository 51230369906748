import { BigNumber } from "ethers";
import { useContractValue } from "pages/ILO/tools";
import { useEffect, useMemo, useState } from "react";
import { useBlockTimeContract } from "./useContract";

export const useClock = () => {
	const [time, setTime] = useState(Date.now());

	useEffect(() => {
		setInterval(() => {
			setTime(Date.now());
		}, 990);
	}, []);

	return time;
};

const second = 1000;
const minute = second * 60;
const hour = minute * 60;

export const useDifferenceClock = (
	startTime: number | undefined,
	endTime: number | undefined
) => {
	const [offset, setOffset] = useState<number | null>(null);
	const time = useClock();
	const blockTime = useBlockTimeContract();
	const onChainTime = useContractValue<BigNumber>(blockTime, "getTime");

	useEffect(() => {
		if (offset || !onChainTime) return;

		setOffset(time - onChainTime.toNumber() * 1000);
	}, [onChainTime, time, offset]);

	return useMemo(() => {
		if (!endTime || !startTime || offset === null) return ["...", false, false];

		const realTime = time - offset;

		if (realTime > endTime) {
			return ["Ended", false, true];
		}

		let [base, starting] = [endTime, false, false];

		if (startTime > realTime) {
			[base, starting] = [startTime, true, false];
		}

		const difference = base - realTime;

		return [
			[
				difference / hour,
				(difference % hour) / minute,
				(difference % minute) / second,
			]
				.map(Math.floor)
				.map((v) => v.toString().padStart(2, "0"))
				.join(":"),
			starting,
		];
	}, [time, startTime, endTime, offset]);
};
