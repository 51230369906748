import {
	L1_CHAIN_IDS,
	L2_CHAIN_IDS,
	SupportedChainId,
} from "../constants/chains";

export function constructSameAddressMap<T extends string>(
	address: T,
	additionalNetworks: SupportedChainId[] = []
): { [chainId: number]: T } {
	return (L1_CHAIN_IDS as readonly SupportedChainId[])
		.concat(L2_CHAIN_IDS as readonly SupportedChainId[])
		.concat(additionalNetworks)
		.reduce<{ [chainId: number]: T }>((memo, chainId) => {
			memo[chainId] = address;
			return memo;
		}, {});
}
