import { Trans } from "lib/trans";
import { CurrencyAmount, Token } from "@uniswap/sdk-core";
import CurrencyLogo from "components/CurrencyLogo";
import { useRequireOptimism } from "components/Optimism/RequireOptimism";
import { SupportedChainId } from "constants/chains";
import JSBI from "jsbi";
import { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { RouteComponentProps } from "react-router-dom";
import styled from "styled-components/macro";
import { CountUp } from "use-count-up";

import { ButtonEmpty, ButtonPrimary } from "../../components/Button";
import { AutoColumn } from "../../components/Column";
import DoubleCurrencyLogo from "../../components/DoubleLogo";
import ClaimRewardModal from "../../components/earn/ClaimRewardModal";
import StakingModal from "../../components/earn/StakingModal";
import {
	CardBGImage,
	CardNoise,
	CardSection,
	DataCard,
} from "../../components/earn/styled";
import UnstakingModal from "../../components/earn/UnstakingModal";
import { RowBetween } from "../../components/Row";
import { BIG_INT_SECONDS_IN_WEEK, BIG_INT_ZERO } from "../../constants/misc";
import { useCurrency } from "../../hooks/Tokens";
import { useColor } from "../../hooks/useColor";
import usePrevious from "../../hooks/usePrevious";
import { useTotalSupply } from "../../hooks/useTotalSupply";
import useUSDCPrice from "../../hooks/useUSDCPrice";
import { useV2Pair } from "../../hooks/useV2Pairs";
import { useActiveWeb3React } from "../../hooks/web3";
import { useWalletModalToggle } from "../../state/application/hooks";
import { STAKING_REWARDS_INFO, useStakingInfo } from "../../state/stake/hooks";
import { useTokenBalance } from "../../state/wallet/hooks";
import { TYPE } from "../../theme";
import { currencyId } from "../../utils/currencyId";
import { WETH9_EXTENDED } from "constants/tokens";
import { Text } from "rebass";
import { roundCurrency } from "lib/ui";

const PageWrapper = styled(AutoColumn)`
	max-width: 640px;
	width: 100%;
`;

const PositionInfo = styled(AutoColumn)<{ dim: any }>`
	position: relative;
	max-width: 640px;
	width: 100%;
	opacity: ${({ dim }) => (dim ? 0.6 : 1)};
`;

const BottomSection = styled(AutoColumn)`
	border-radius: 12px;
	width: 100%;
	position: relative;
`;

const StyledDataCard = styled(DataCard)<{
	bgColor?: any;
	showBackground?: any;
}>`
	background: ${({ theme }) => theme.primary1};
	border: none;
	z-index: 2;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
`;

const StyledBottomCard = styled(DataCard)<{ dim: any }>`
	background: ${({ theme }) => theme.bg2};
	opacity: ${({ dim }) => (dim ? 0.4 : 1)};
	margin-top: -40px;
	padding: 0 1.25rem 1rem 1.25rem;
	padding-top: 32px;
	z-index: 1;
`;

const PoolData = styled(DataCard)`
	background: ${({ theme }) => theme.bg0};
	padding: 1rem;
	height: 100%;
	z-index: 1;
`;

const VoteCard = styled(DataCard)`
	background: ${({ theme }) => theme.bg0};
	border: 2px solid ${({ theme }) => theme.bg2};
	overflow: hidden;
`;

const DataRow = styled(RowBetween)`
	justify-content: center;
	gap: 12px;

	${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    gap: 12px;
  `};
`;

const WETH = WETH9_EXTENDED[SupportedChainId.OPTIMISM];

export default function Manage({
	match: {
		params: { lp },
	},
}: RouteComponentProps<{ lp: string }>) {
	const { account } = useActiveWeb3React();

	const info = STAKING_REWARDS_INFO[SupportedChainId.OPTIMISM].find(
		(info) => info.lp.address.toLowerCase() === lp.toLowerCase()
	);

	// get currencies and pair
	const currencyA = useCurrency(info?.tokens?.[0]?.address ?? info?.lp.address);
	const currencyB = useCurrency(info?.tokens?.[1]?.address);

	const tokenA = (currencyA ?? undefined)?.wrapped;
	const tokenB = (currencyB ?? undefined)?.wrapped;

	const stakingInfo = useStakingInfo(lp)?.[0];

	const [, stakingTokenPair] = useV2Pair(
		...(stakingInfo
			? stakingInfo.tokens
				? stakingInfo.tokens
				: [stakingInfo.lp, WETH]
			: [undefined, undefined])
	);

	// detect existing unstaked LP position to show add button if none found
	/* eslint-disable react-hooks/rules-of-hooks */
	const userLiquidityUnstaked = useTokenBalance(
		account ?? undefined,
		stakingInfo?.stakedAmount?.currency
	);

	const showAddLiquidityButton =
		Boolean(
			stakingInfo?.stakedAmount?.equalTo("0") &&
				userLiquidityUnstaked?.equalTo("0")
		) && currencyB;

	// toggle for staking modal and unstaking modal
	const [showStakingModal, setShowStakingModal] = useState(false);
	const [showUnstakingModal, setShowUnstakingModal] = useState(false);
	const [showClaimRewardModal, setShowClaimRewardModal] = useState(false);

	// fade cards if nothing staked or nothing earned yet
	const disableTop =
		!stakingInfo?.stakedAmount ||
		stakingInfo.stakedAmount.equalTo(JSBI.BigInt(0));

	const token = currencyA?.isNative ? tokenB : tokenA;

	const backgroundColor = useColor(token);

	// get WETH value of staked LP tokens
	const totalSupplyOfStakingToken = useTotalSupply(
		stakingInfo?.stakedAmount?.currency
	);

	let valueOfTotalStakedAmountInWETH: CurrencyAmount<Token> | undefined;

	if (totalSupplyOfStakingToken && stakingTokenPair && stakingInfo && tokenA) {
		if (stakingInfo.tokens) {
			// take the total amount of LP tokens staked, multiply by ETH value of all LP tokens, divide by all LP tokens
			valueOfTotalStakedAmountInWETH = CurrencyAmount.fromRawAmount(
				WETH,
				JSBI.divide(
					JSBI.multiply(
						JSBI.multiply(
							stakingInfo.totalStakedAmount.quotient,
							stakingTokenPair.reserveOf(WETH).quotient
						),
						JSBI.BigInt(2) // this is b/c the value of LP shares are ~double the value of the WETH they entitle owner to
					),
					totalSupplyOfStakingToken.quotient
				)
			);
		} else {
			// take the total amount of LP tokens staked, multiply by ETH value of all LP tokens, divide by all LP tokens
			valueOfTotalStakedAmountInWETH = CurrencyAmount.fromRawAmount(
				WETH,
				JSBI.divide(
					JSBI.multiply(
						JSBI.multiply(
							stakingInfo.totalStakedAmount.quotient,
							stakingTokenPair.reserveOf(WETH).quotient
						),
						JSBI.BigInt(1) // this is b/c the value of LP shares are ~double the value of the WETH they entitle owner to
					),
					stakingTokenPair.reserveOf(tokenA).quotient
				)
			);
		}
	}

	const countUpAmount = stakingInfo?.earnedAmounts[0]?.toFixed(6) ?? "0";
	const countUpAmountPrevious = usePrevious(countUpAmount) ?? "0";

	const countUpExtraAmount =
		(stakingInfo || { earnedAmounts: [undefined] }).earnedAmounts[1]?.toFixed(
			6
		) ?? "0";
	const countUpExtraAmountPrevious = usePrevious(countUpExtraAmount) ?? "0";

	// get the USD value of staked WETH
	const USDPrice = useUSDCPrice(WETH);
	const valueOfTotalStakedAmountInUSDC =
		valueOfTotalStakedAmountInWETH &&
		USDPrice?.quote(valueOfTotalStakedAmountInWETH);

	const toggleWalletModal = useWalletModalToggle();

	const handleDepositClick = useCallback(() => {
		if (account) {
			setShowStakingModal(true);
		} else {
			toggleWalletModal();
		}
	}, [account, toggleWalletModal]);

	const required = useRequireOptimism("Farm");

	if (required) {
		return required;
	}

	return (
		<PageWrapper gap="lg" justify="center">
			<RowBetween style={{ gap: "24px" }}>
				<TYPE.mediumHeader style={{ margin: 0 }}>
					<Trans>
						{currencyB
							? `${currencyA?.symbol}-${currencyB?.symbol}`
							: currencyA?.symbol}{" "}
						Liquidity Mining
					</Trans>
				</TYPE.mediumHeader>
				{currencyB ? (
					<DoubleCurrencyLogo
						currency0={currencyA ?? undefined}
						currency1={currencyB ?? undefined}
						size={24}
					/>
				) : (
					<CurrencyLogo currency={currencyA} size="24px" />
				)}
			</RowBetween>

			<DataRow style={{ gap: "24px" }}>
				<PoolData>
					<AutoColumn gap="sm">
						<TYPE.body style={{ margin: 0 }}>
							<Trans>Total deposits</Trans>
						</TYPE.body>
						<TYPE.body fontSize={24} fontWeight={500}>
							{valueOfTotalStakedAmountInUSDC
								? `$${valueOfTotalStakedAmountInUSDC.toFixed(0, {
										groupSeparator: ",",
								  })}`
								: `${
										valueOfTotalStakedAmountInWETH?.toSignificant(4, {
											groupSeparator: ",",
										}) ?? "-"
								  } ETH`}
						</TYPE.body>
					</AutoColumn>
				</PoolData>
				<PoolData>
					{stakingInfo?.active && (
						<AutoColumn gap="sm">
							<TYPE.body style={{ margin: 0 }}>
								<Trans>Pool Rate{stakingInfo?.doubleReward ? "s" : ""}</Trans>
							</TYPE.body>
							<TYPE.body fontSize={24} fontWeight={500}>
								{stakingInfo?.active ? (
									<Trans>
										{roundCurrency(
											stakingInfo.totalRewardRates[0].multiply(
												BIG_INT_SECONDS_IN_WEEK
											)
										)}{" "}
										ZIP / week
									</Trans>
								) : (
									<Trans>0 ZIP / week</Trans>
								)}
							</TYPE.body>

							{stakingInfo?.doubleReward && (
								<TYPE.body fontSize={18} fontWeight={500}>
									<Trans>
										{roundCurrency(
											stakingInfo.totalRewardRates[1]?.multiply(
												BIG_INT_SECONDS_IN_WEEK
											),
											2
										)}{" "}
										{stakingInfo.doubleReward.tokens[0].symbol} / week
									</Trans>
								</TYPE.body>
							)}
						</AutoColumn>
					)}
				</PoolData>
			</DataRow>

			{showAddLiquidityButton && (
				<VoteCard>
					{/* <CardBGImage /> */}

					<CardSection>
						<AutoColumn gap="md">
							<RowBetween>
								<Text fontWeight={600}>
									<Trans>Step 1. Get ZIP-V2 Liquidity tokens</Trans>
								</Text>
							</RowBetween>
							<RowBetween style={{ marginBottom: "1rem" }}>
								<Text fontSize={14}>
									<Trans>
										ZIP-V2 LP tokens are required. Once you&apos;ve added
										liquidity to the {currencyA?.symbol}-{currencyB?.symbol}{" "}
										pool you can stake your liquidity tokens on this page.
									</Trans>
								</Text>
							</RowBetween>
							<ButtonPrimary
								padding="8px"
								$borderRadius="8px"
								width={"fit-content"}
								as={Link}
								to={`/add/${currencyA && currencyId(currencyA)}/${
									currencyB && currencyId(currencyB)
								}`}
							>
								<Trans>
									Add {currencyA?.symbol}-{currencyB?.symbol} liquidity
								</Trans>
							</ButtonPrimary>
						</AutoColumn>
					</CardSection>

					{/* <CardBGImage /> */}
				</VoteCard>
			)}

			{stakingInfo && (
				<>
					<StakingModal
						isOpen={showStakingModal}
						onDismiss={() => setShowStakingModal(false)}
						stakingInfo={stakingInfo}
						userLiquidityUnstaked={userLiquidityUnstaked}
						stakingRewardAddress={stakingInfo.masterchef}
					/>
					<UnstakingModal
						isOpen={showUnstakingModal}
						onDismiss={() => setShowUnstakingModal(false)}
						stakingInfo={stakingInfo}
						stakingRewardAddress={stakingInfo.masterchef}
					/>
					<ClaimRewardModal
						isOpen={showClaimRewardModal}
						onDismiss={() => setShowClaimRewardModal(false)}
						stakingInfo={stakingInfo}
						stakingRewardAddress={stakingInfo.masterchef}
					/>
				</>
			)}

			<PositionInfo gap="lg" justify="center" dim={showAddLiquidityButton}>
				<BottomSection gap="lg" justify="center">
					<StyledDataCard
						disabled={disableTop}
						bgColor={backgroundColor}
						showBackground={!showAddLiquidityButton}
					>
						<CardSection>
							{/* <CardBGImage desaturate /> */}

							<AutoColumn gap="md">
								<RowBetween>
									<TYPE.white fontWeight={600}>
										<Trans>Your liquidity deposits</Trans>
									</TYPE.white>
								</RowBetween>
								<RowBetween style={{ alignItems: "baseline" }}>
									<TYPE.white fontSize={36} fontWeight={600}>
										{stakingInfo?.stakedAmount?.toSignificant(6) ?? "-"}
									</TYPE.white>
									<TYPE.white>
										<Trans>
											{currencyB
												? `${currencyA?.symbol}-${currencyB?.symbol}-ZS`
												: currencyA?.symbol}
										</Trans>
									</TYPE.white>
								</RowBetween>
							</AutoColumn>
						</CardSection>
					</StyledDataCard>

					<StyledBottomCard
						dim={stakingInfo?.stakedAmount?.equalTo(JSBI.BigInt(0))}
					>
						{/* <CardBGImage desaturate /> */}
						<AutoColumn gap="sm">
							<RowBetween>
								<div>
									<TYPE.black>
										<Trans>Your unclaimed ZIP</Trans>
									</TYPE.black>
								</div>
							</RowBetween>
							<RowBetween style={{ alignItems: "baseline" }}>
								<TYPE.largeHeader fontSize={36} fontWeight={600}>
									<CountUp
										key={countUpAmount}
										isCounting
										decimalPlaces={4}
										start={parseFloat(countUpAmountPrevious)}
										end={parseFloat(countUpAmount)}
										thousandsSeparator={","}
										duration={1}
									/>
								</TYPE.largeHeader>

								{stakingInfo?.active && (
									<TYPE.black fontSize={16} fontWeight={500}>
										<span
											role="img"
											aria-label="wizard-icon"
											style={{ marginRight: "8px " }}
										>
											⚡
										</span>

										{stakingInfo?.active ? (
											<Trans>
												{roundCurrency(
													stakingInfo.rewardRates[0].multiply(
														BIG_INT_SECONDS_IN_WEEK
													)
												)}{" "}
												ZIP / week
											</Trans>
										) : (
											<Trans>0 ZIP / week</Trans>
										)}
									</TYPE.black>
								)}
							</RowBetween>

							{stakingInfo && stakingInfo.doubleReward && (
								<>
									<Text fontWeight={500} fontSize={16}>
										Unclaimed {stakingInfo.doubleReward.tokens[0].symbol}
									</Text>

									<RowBetween style={{ alignItems: "baseline" }}>
										<TYPE.largeHeader fontSize={24} fontWeight={600}>
											<CountUp
												key={countUpExtraAmount}
												isCounting
												decimalPlaces={4}
												start={parseFloat(countUpExtraAmountPrevious)}
												end={parseFloat(countUpExtraAmount)}
												thousandsSeparator={","}
												duration={1}
											/>
										</TYPE.largeHeader>

										{stakingInfo?.active && (
											<TYPE.black fontSize={16} fontWeight={500}>
												<span
													role="img"
													aria-label="wizard-icon"
													style={{ marginRight: "8px " }}
												>
													⚡
												</span>

												<Trans>
													{roundCurrency(
														stakingInfo.rewardRates[1].multiply(
															BIG_INT_SECONDS_IN_WEEK
														),
														2
													)}{" "}
													{stakingInfo.doubleReward.tokens[0].symbol} / week
												</Trans>
											</TYPE.black>
										)}
									</RowBetween>
								</>
							)}
						</AutoColumn>
					</StyledBottomCard>
				</BottomSection>
				<TYPE.main style={{ textAlign: "center" }} fontSize={14}>
					<span
						role="img"
						aria-label="wizard-icon"
						style={{ marginRight: "8px" }}
					>
						⭐️
					</span>
					<Trans>
						When you withdraw, the contract will automagically claim ZIP on your
						behalf!
					</Trans>
				</TYPE.main>

				{!showAddLiquidityButton && (
					<DataRow style={{ marginBottom: "1rem" }}>
						{stakingInfo && stakingInfo.active && (
							<ButtonPrimary
								padding="8px"
								$borderRadius="8px"
								width="160px"
								onClick={handleDepositClick}
							>
								{stakingInfo?.stakedAmount?.greaterThan(JSBI.BigInt(0)) ||
								!Boolean(currencyB) ? (
									<Trans>Deposit</Trans>
								) : (
									<Trans>Deposit ZIP-V2 LP Tokens</Trans>
								)}
							</ButtonPrimary>
						)}

						{stakingInfo?.earnedAmounts[0] &&
							JSBI.notEqual(
								BIG_INT_ZERO,
								stakingInfo?.earnedAmounts[0]?.quotient
							) && (
								<ButtonPrimary
									padding="8px"
									$borderRadius="8px"
									width="160px"
									onClick={() => setShowClaimRewardModal(true)}
								>
									<Trans>Claim</Trans>
								</ButtonPrimary>
							)}

						{stakingInfo?.stakedAmount?.greaterThan(JSBI.BigInt(0)) && (
							<>
								<ButtonPrimary
									padding="8px"
									$borderRadius="8px"
									width="160px"
									onClick={() => setShowUnstakingModal(true)}
								>
									<Trans>Withdraw</Trans>
								</ButtonPrimary>
							</>
						)}
					</DataRow>
				)}
				{!userLiquidityUnstaked ? null : userLiquidityUnstaked.equalTo(
						"0"
				  ) ? null : !stakingInfo?.active ? null : (
					<TYPE.main>
						<Trans>
							{userLiquidityUnstaked.toSignificant(6)}{" "}
							{currencyB ? "ZIP-V2 LP" : currencyA?.symbol} tokens available
						</Trans>
					</TYPE.main>
				)}
			</PositionInfo>
		</PageWrapper>
	);
}
