import { arrayify } from "@ethersproject/bytes";
import { parseBytes32String } from "@ethersproject/strings";
import { Currency, Token } from "@uniswap/sdk-core";
import {
	CHAIN_INFO,
	L2_CHAIN_IDS,
	SupportedChainId,
	SupportedL2ChainId,
} from "constants/chains";
import { useMemo } from "react";
import { createObject } from "utils/kvstore";

import { createTokenFilterFunction } from "../components/SearchModal/filtering";
import {
	ExtendedEther,
	EXTRA_TOKENS,
	WETH9_EXTENDED,
} from "../constants/tokens";
import {
	useAllLists,
	useCombinedActiveList,
	useInactiveListUrls,
} from "../state/lists/hooks";
import { WrappedTokenInfo } from "../state/lists/wrappedTokenInfo";
import { NEVER_RELOAD, useSingleCallResult } from "../state/multicall/hooks";
import { useUserAddedTokens } from "../state/user/hooks";
import { isAddress } from "../utils";
import {
	TokenAddressMap,
	useUnsupportedTokenList,
} from "./../state/lists/hooks";
import { useBytes32TokenContract, useTokenContract } from "./useContract";
import { useActiveWeb3React } from "./web3";

// reduce token map into standard address <-> Token mapping, optionally include user added tokens
function useTokensFromMap(
	tokenMap: TokenAddressMap,
	includeUserAdded: boolean
): { [address: string]: Token } {
	const { chainId } = useActiveWeb3React();
	const userAddedTokens = useUserAddedTokens();

	return useMemo(() => {
		if (!chainId) return {};

		// reduce to just tokens
		const mapWithoutUrls = Object.keys(tokenMap[chainId] ?? {}).reduce<{
			[address: string]: Token;
		}>((newMap, address) => {
			newMap[address] = tokenMap[chainId][address].token;
			return newMap;
		}, {});

		if (includeUserAdded) {
			return (
				userAddedTokens
					// reduce into all ALL_TOKENS filtered by the current chain
					.reduce<{ [address: string]: Token }>(
						(tokenMap, token) => {
							tokenMap[token.address] = token;
							return tokenMap;
						},
						// must make a copy because reduce modifies the map, and we do not
						// want to make a copy in every iteration
						{ ...mapWithoutUrls }
					)
			);
		}

		return mapWithoutUrls;
	}, [chainId, userAddedTokens, tokenMap, includeUserAdded]);
}

export function useAllTokens(): { [address: string]: Token } {
	const allTokens = useCombinedActiveList();
	const { chainId } = useActiveWeb3React();

	const extraTokens = useMemo(
		() =>
			Object.keys(EXTRA_TOKENS)
				.filter((id) => parseInt(id) === chainId)
				.map((chainId) => ({
					...EXTRA_TOKENS[parseInt(chainId)]
						.map((token) => createObject(token.address, token))
						.reduce(
							(cv, pv) => ({
								...pv,
								...cv,
							}),
							{}
						),
				}))
				.reduce(
					(cv, pv) => ({
						...pv,
						...cv,
					}),
					{}
				),
		[chainId]
	);

	const tokens = useTokensFromMap(allTokens, true);

	const combined = useMemo(
		() => ({
			...tokens,
			...extraTokens,
		}),
		[tokens, extraTokens]
	);

	return combined;
}

type BridgeInfo = Record<
	SupportedChainId,
	{
		tokenAddress: string;
		originBridgeAddress: string;
		destBridgeAddress: string;
	}
>;

export function useUnsupportedTokens(): { [address: string]: Token } {
	const { chainId } = useActiveWeb3React();
	const listsByUrl = useAllLists();
	const unsupportedTokensMap = useUnsupportedTokenList();
	const unsupportedTokens = useTokensFromMap(unsupportedTokensMap, false);

	// checks the default L2 lists to see if `bridgeInfo` has an L1 address value that is unsupported
	const l2InferredBlockedTokens: typeof unsupportedTokens = useMemo(() => {
		if (!chainId || !L2_CHAIN_IDS.includes(chainId)) {
			return {};
		}

		if (!listsByUrl) {
			return {};
		}

		const listUrl = CHAIN_INFO[chainId as SupportedL2ChainId].defaultListUrl;
		const { current: list } = listsByUrl[listUrl];
		if (!list) {
			return {};
		}

		const unsupportedSet = new Set(Object.keys(unsupportedTokens));

		return list.tokens.reduce((acc, tokenInfo) => {
			const bridgeInfo = tokenInfo.extensions
				?.bridgeInfo as unknown as BridgeInfo;
			if (
				bridgeInfo &&
				bridgeInfo[SupportedChainId.MAINNET] &&
				bridgeInfo[SupportedChainId.MAINNET].tokenAddress &&
				unsupportedSet.has(bridgeInfo[SupportedChainId.MAINNET].tokenAddress)
			) {
				const address = bridgeInfo[SupportedChainId.MAINNET].tokenAddress;
				// don't rely on decimals--it's possible that a token could be bridged w/ different decimals on the L2
				return {
					...acc,
					[address]: new Token(
						SupportedChainId.MAINNET,
						address,
						tokenInfo.decimals
					),
				};
			}
			return acc;
		}, {});
	}, [chainId, listsByUrl, unsupportedTokens]);

	return { ...unsupportedTokens, ...l2InferredBlockedTokens };
}

export function useSearchInactiveTokenLists(
	search: string | undefined,
	minResults = 10
): WrappedTokenInfo[] {
	const lists = useAllLists();
	const inactiveUrls = useInactiveListUrls();
	const { chainId } = useActiveWeb3React();
	const activeTokens = useAllTokens();
	return useMemo(() => {
		if (!search || search.trim().length === 0) return [];
		const tokenFilter = createTokenFilterFunction(search);
		const result: WrappedTokenInfo[] = [];
		const addressSet: { [address: string]: true } = {};
		for (const url of inactiveUrls) {
			const list = lists[url].current;
			if (!list) continue;
			for (const tokenInfo of list.tokens) {
				if (tokenInfo.chainId === chainId && tokenFilter(tokenInfo)) {
					const wrapped: WrappedTokenInfo = new WrappedTokenInfo(
						tokenInfo,
						list
					);
					if (
						!(wrapped.address in activeTokens) &&
						!addressSet[wrapped.address]
					) {
						addressSet[wrapped.address] = true;
						result.push(wrapped);
						if (result.length >= minResults) return result;
					}
				}
			}
		}
		return result;
	}, [activeTokens, chainId, inactiveUrls, lists, minResults, search]);
}

export function useIsTokenActive(token: Token | undefined | null): boolean {
	const activeTokens = useAllTokens();

	if (!activeTokens || !token) {
		return false;
	}

	return !!activeTokens[token.address];
}

// Check if currency is included in custom list from user storage
export function useIsUserAddedToken(
	currency: Currency | undefined | null
): boolean {
	const userAddedTokens = useUserAddedTokens();

	if (!currency) {
		return false;
	}

	return !!userAddedTokens.find((token) => currency.equals(token));
}

// parse a name or symbol from a token response
const BYTES32_REGEX = /^0x[a-fA-F0-9]{64}$/;

function parseStringOrBytes32(
	str: string | undefined,
	bytes32: string | undefined,
	defaultValue: string
): string {
	return str && str.length > 0
		? str
		: // need to check for proper bytes string and valid terminator
		bytes32 && BYTES32_REGEX.test(bytes32) && arrayify(bytes32)[31] === 0
		? parseBytes32String(bytes32)
		: defaultValue;
}

// undefined if invalid or does not exist
// null if loading or null was passed
// otherwise returns the token
export function useToken(
	tokenAddress?: string | null
): Token | undefined | null {
	const { chainId } = useActiveWeb3React();
	const tokens = useAllTokens();

	const address = isAddress(tokenAddress);

	const tokenContract = useTokenContract(address ? address : undefined, false);
	const tokenContractBytes32 = useBytes32TokenContract(
		address ? address : undefined,
		false
	);
	const token: Token | undefined = address ? tokens[address] : undefined;

	const tokenName = useSingleCallResult(
		token ? undefined : tokenContract,
		"name",
		undefined,
		NEVER_RELOAD
	);
	const tokenNameBytes32 = useSingleCallResult(
		token ? undefined : tokenContractBytes32,
		"name",
		undefined,
		NEVER_RELOAD
	);
	const symbol = useSingleCallResult(
		token ? undefined : tokenContract,
		"symbol",
		undefined,
		NEVER_RELOAD
	);
	const symbolBytes32 = useSingleCallResult(
		token ? undefined : tokenContractBytes32,
		"symbol",
		undefined,
		NEVER_RELOAD
	);
	const decimals = useSingleCallResult(
		token ? undefined : tokenContract,
		"decimals",
		undefined,
		NEVER_RELOAD
	);

	return useMemo(() => {
		if (token) return token;
		if (tokenAddress === null) return null;
		if (!chainId || !address) return undefined;
		if (decimals.loading || symbol.loading || tokenName.loading) return null;
		if (decimals.result) {
			return new Token(
				chainId,
				address,
				decimals.result[0],
				parseStringOrBytes32(
					symbol.result?.[0],
					symbolBytes32.result?.[0],
					"UNKNOWN"
				),
				parseStringOrBytes32(
					tokenName.result?.[0],
					tokenNameBytes32.result?.[0],
					"Unknown Token"
				)
			);
		}
		return undefined;
	}, [
		address,
		chainId,
		decimals.loading,
		decimals.result,
		symbol.loading,
		symbol.result,
		symbolBytes32.result,
		token,
		tokenAddress,
		tokenName.loading,
		tokenName.result,
		tokenNameBytes32.result,
	]);
}

export function useCurrency(
	currencyId: string | null | undefined
): Currency | null | undefined {
	const { chainId } = useActiveWeb3React();
	const isETH = currencyId?.toUpperCase() === "ETH";
	const token = useToken(isETH ? undefined : currencyId);
	const extendedEther = useMemo(
		() =>
			chainId
				? ExtendedEther.onChain(chainId)
				: // display mainnet when not connected
				  ExtendedEther.onChain(SupportedChainId.MAINNET),
		[chainId]
	);
	const weth = chainId ? WETH9_EXTENDED[chainId] : undefined;
	if (currencyId === null || currencyId === undefined) return currencyId;
	if (weth?.address?.toUpperCase() === currencyId?.toUpperCase()) return weth;
	return isETH ? extendedEther : token;
}
