import { Trans } from "lib/trans";
import { Currency, CurrencyAmount, Token } from "@uniswap/sdk-core";
import { ButtonGray, ButtonPrimary, ButtonSecondary } from "components/Button";
import CurrencyLogo from "components/CurrencyLogo";
import Modal from "components/Modal";
import { RowBetween, RowFixed } from "components/Row";
import CurrencySearchModal from "components/SearchModal/CurrencySearchModal";
import { darken } from "polished";
import {
	Dispatch,
	SetStateAction,
	useContext,
	useEffect,
	useMemo,
	useState,
} from "react";
import styled, { ThemeContext } from "styled-components";
import { Dots, Wrapper } from "./styleds";
import { ReactComponent as DropDown } from "../../assets/images/dropdown.svg";
import { Text } from "rebass";
import { KVStore, useKVStore } from "utils/kvstore";
import { BigNumber, ethers } from "ethers";
import { V2_FACTORY_ADDRESSES } from "constants/addresses";
import { abi as factoryAbi } from "abis/swap/UniswapV2Factory.json";
import { abi as pairAbi } from "abis/swap/UniswapV2Pair.json";
import { zeroAddress } from "pages/AddLiquidityV2/tools";
import { MinimalPositionCard } from "components/PositionCard";
import { Pair } from "@uniswap/v2-sdk";
import { computePairAddress, sortTokens } from "hooks/useV2Pairs";
import { GreyCard } from "components/Card";
import { toV2LiquidityToken, useTrackedTokenPairs } from "state/user/hooks";
import { PairInfo } from "./v2";
import { useActiveWeb3React } from "hooks/web3";

const CurrencySelect = styled(ButtonGray)<{
	visible: boolean;
	selected: boolean;
	hideInput?: boolean;
}>`
	visibility: ${({ visible }) => (visible ? "visible" : "hidden")};
	align-items: center;
	font-size: 24px;
	font-weight: 500;
	background-color: ${({ selected, theme }) =>
		selected ? theme.bg0 : theme.primary1};
	color: ${({ selected, theme }) => (selected ? theme.text1 : theme.white)};
	border-radius: 16px;
	box-shadow: ${({ selected }) =>
		selected ? "none" : "0px 6px 10px rgba(0, 0, 0, 0.075)"};
	box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
	outline: none;
	cursor: pointer;
	user-select: none;
	border: none;
	height: ${({ hideInput }) => (hideInput ? "2.8rem" : "2.4rem")};
	width: 100%;
	padding: 0 8px;
	justify-content: space-between;
	:focus,
	:hover {
		background-color: ${({ selected, theme }) =>
			selected ? theme.bg2 : darken(0.05, theme.primary1)};
	}
`;

const Aligner = styled.span`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
`;

const StyledTokenName = styled.span<{ active?: boolean }>`
	${({ active }) =>
		active
			? "  margin: 0 0.25rem 0 0.25rem;"
			: "  margin: 0 0.25rem 0 0.25rem;"}
	font-size:  ${({ active }) => (active ? "18px" : "18px")};
`;

const StyledDropDown = styled(DropDown)<{ selected: boolean }>`
	margin: 0 0.25rem 0 0.35rem;
	height: 35%;

	path {
		stroke: ${({ selected, theme }) => (selected ? theme.text1 : theme.white)};
		stroke-width: 1.5px;
	}
`;

const Selector = ({
	currency,
	setModalOpen,
}: {
	currency?: Currency | null;
	setModalOpen: () => void;
}) => (
	<CurrencySelect
		visible={currency !== undefined}
		selected={!!currency}
		hideInput={false}
		className="open-currency-select-button"
		onClick={() => {
			setModalOpen();
		}}
	>
		<Aligner>
			<RowFixed>
				{currency ? (
					<CurrencyLogo
						style={{ marginRight: "0.5rem" }}
						currency={currency}
						size={"24px"}
					/>
				) : null}

				<StyledTokenName
					className="token-symbol-container"
					active={Boolean(currency && currency.symbol)}
				>
					{(currency && currency.symbol && currency.symbol.length > 20
						? currency.symbol.slice(0, 4) +
						  "..." +
						  currency.symbol.slice(
								currency.symbol.length - 5,
								currency.symbol.length
						  )
						: currency?.symbol) || <Trans>Select a token</Trans>}
				</StyledTokenName>
			</RowFixed>
			<StyledDropDown selected={!!currency} />
		</Aligner>
	</CurrencySelect>
);

export const getKey = (
	currencyA: Currency | Token,
	currencyB: Currency | Token
) => {
	const [tokenA, tokenB] = sortTokens(
		BigNumber.from(currencyA.wrapped.address),
		BigNumber.from(currencyB.wrapped.address)
	);
	return `${tokenA.toString()}${tokenB.toString()}`;
};

export type LiquidityState = [
	Currency | null,
	Dispatch<SetStateAction<Currency | null>>
];

export const ImportLiquidity = ({
	setLiquidity,
	importLiquidityOpen,
	setImportLiquidityOpen,
	state,
	localTokens,
}: {
	setLiquidity: (key: string, tokens: [Token, Token]) => void;
	importLiquidityOpen: boolean;
	setImportLiquidityOpen: Dispatch<SetStateAction<boolean>>;
	state: [LiquidityState, LiquidityState];
	localTokens: KVStore<[Token, Token]>;
}) => {
	const theme = useContext(ThemeContext);
	const {
		store,
		set,
		key: hasKey,
	} = useKVStore<[Pair, [Token, Token]] | string | null>();

	const { account, library, chainId } = useActiveWeb3React();

	const [openModal, setOpenModal] = useState(-1);
	const [currencyA, setCurrencyA] = state[0];
	const [currencyB, setCurrencyB] = state[1];

	const key = useMemo(
		() => (currencyA && currencyB && getKey(currencyA, currencyB)) || null,
		[currencyA, currencyB]
	);

	const trackedTokenPairs = useTrackedTokenPairs();

	const tokenPairsWithLiquidityTokens = useMemo(
		() =>
			trackedTokenPairs
				.map((tokens) => ({
					liquidityToken: toV2LiquidityToken(tokens),
					tokens,
				}))
				.concat(
					Object.keys(localTokens)
						.map((key) => localTokens[key])
						.filter(
							(tokens) => tokens && tokens[0].address && tokens[1].address
						)
						.map(([A, B]) => [
							new Token(A.chainId, A.address, A.decimals, A.symbol, A.name),
							new Token(B.chainId, B.address, B.decimals, B.symbol, B.name),
						])
						.map((tokens) => tokens as [Token, Token])
						.map((tokens) => ({
							liquidityToken: toV2LiquidityToken(tokens),
							tokens,
						}))
						.map((val) => val as PairInfo)
				)
				.reduce(
					(pv, cv) =>
						pv.findIndex(
							({ liquidityToken: { address } }) =>
								address === cv.liquidityToken.address
						) === -1
							? [...pv, cv]
							: pv,
					[] as PairInfo[]
				),
		[trackedTokenPairs, localTokens]
	);

	const existingKeys = useMemo(
		() =>
			tokenPairsWithLiquidityTokens.map(({ tokens }) =>
				getKey(tokens[0], tokens[1])
			),
		[tokenPairsWithLiquidityTokens]
	);

	useEffect(() => {
		if (!key) return;
		if (!currencyA) return;
		if (!currencyB) return;
		if (!account) return;
		if (!library) return;
		if (!chainId) return;
		if (hasKey(key)) return;

		set(key, null);

		(async () => {
			let factoryContract = new ethers.Contract(
				V2_FACTORY_ADDRESSES[chainId],
				factoryAbi,
				library
			);

			const tokenA = currencyA.wrapped;
			const tokenB = currencyB.wrapped;

			let [poolAddress] = await factoryContract.getPool(
				tokenA.address,
				tokenB.address
			);

			if (poolAddress === zeroAddress) {
				set(key, "Not found");
				return;
			}

			let pairContract = new ethers.Contract(poolAddress, pairAbi, library);

			const [[reserve0, reserve1]] = await Promise.all([
				pairContract.getReserves(),
			]);

			const pair = new Pair(
				CurrencyAmount.fromRawAmount(tokenA, reserve0.toString()),
				CurrencyAmount.fromRawAmount(tokenB, reserve1.toString())
			);

			const addy = computePairAddress(
				V2_FACTORY_ADDRESSES[tokenA.chainId],
				tokenA,
				tokenB
			);

			(pair as any).liquidityToken.address = addy;

			set(key, [pair, [tokenA, tokenB]]);
		})();
	}, [key, store, currencyA, currencyB, account, library, chainId]);

	const found = key && store[key] && typeof store[key] !== "string";

	return (
		<Modal
			isOpen={importLiquidityOpen}
			onDismiss={() => setImportLiquidityOpen(false)}
		>
			<Wrapper style={{ width: "100%" }}>
				<Text fontWeight={500} fontSize={16}>
					Import Liquidity
				</Text>

				<GreyCard marginTop="24px" border={theme.primary1}>
					<Selector currency={currencyA} setModalOpen={() => setOpenModal(0)} />

					<div style={{ height: "0.5rem" }} />

					<Selector currency={currencyB} setModalOpen={() => setOpenModal(1)} />

					<div style={{ height: "1rem" }} />

					{found ? (
						<MinimalPositionCard
							pair={(store[key] as any)[0]}
							showUnwrapped={false}
							noCard
						/>
					) : key ? (
						<>
							{store[key] === null ? (
								<Text fontSize={16} fontWeight={500}>
									Loading pair info
									<Dots />
								</Text>
							) : (
								<Text fontSize={16} fontWeight={500}>
									No liquidity found!
								</Text>
							)}
						</>
					) : (
						<Text fontSize={16} fontWeight={500}>
							Select a pair
						</Text>
					)}
				</GreyCard>

				<RowBetween marginTop="24px">
					<ButtonSecondary
						padding="8px"
						$borderRadius="8px"
						width="48.75%"
						onClick={() => setImportLiquidityOpen(false)}
					>
						<Trans>Cancel</Trans>
					</ButtonSecondary>
					<ButtonPrimary
						padding="8px"
						$borderRadius="8px"
						width="48.75%"
						disabled={found !== true || (found && existingKeys.includes(key))}
						onClick={() => {
							if (found !== true) return;
							if (!key) return;
							if (typeof store[key]! === "string") return;
							if (existingKeys.includes(key)) return;

							setLiquidity(key, (store[key] as any)[1]);
							setImportLiquidityOpen(false);
						}}
					>
						{found && existingKeys.includes(key) ? (
							"Already Imported"
						) : (
							<Trans>Import Liquidity</Trans>
						)}
					</ButtonPrimary>
				</RowBetween>
			</Wrapper>

			<CurrencySearchModal
				isOpen={openModal === 0}
				onDismiss={() => setOpenModal(-1)}
				onCurrencySelect={(currency) => {
					if (currencyB?.equals(currency) || false) setCurrencyB(currencyA);
					setCurrencyA(currency);
				}}
				selectedCurrency={currencyA}
				otherSelectedCurrency={currencyB}
				showCommonBases={true}
				showCurrencyAmount={false}
				disableNonToken={false}
			/>

			<CurrencySearchModal
				isOpen={openModal === 1}
				onDismiss={() => setOpenModal(-1)}
				onCurrencySelect={(currency) => {
					if (currencyA?.equals(currency) || false) setCurrencyA(currencyB);
					setCurrencyB(currency);
				}}
				selectedCurrency={currencyB}
				otherSelectedCurrency={currencyA}
				showCommonBases={true}
				showCurrencyAmount={false}
				disableNonToken={false}
			/>
		</Modal>
	);
};
