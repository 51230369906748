import { FACTORY_ADDRESS as V2_FACTORY_ADDRESS } from "@uniswap/v2-sdk";
import { FACTORY_ADDRESS as V3_FACTORY_ADDRESS } from "@uniswap/v3-sdk";

import { constructSameAddressMap } from "../utils/constructSameAddressMap";
import { SupportedChainId } from "./chains";

type AddressMap = { [chainId: number]: string };

export const UNI_ADDRESS: AddressMap = constructSameAddressMap(
	"0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984"
);
export const MULTICALL_ADDRESS: AddressMap = {
	...constructSameAddressMap("0x1F98415757620B543A52E61c46B32eB19261F984", [
		SupportedChainId.OPTIMISTIC_KOVAN,
		SupportedChainId.OPTIMISM,
	]),
	// ...constructSameAddressMap("0x2279B7A0a67DB372996a5FaB50D91eAA73d2eBe6", [
	// 	SupportedChainId.OPTIMISTIC_KOVAN,
	// 	SupportedChainId.OPTIMISM,
	// ]),
	[SupportedChainId.ARBITRUM_ONE]: "0xadF885960B47eA2CD9B55E6DAc6B42b7Cb2806dB",
	[SupportedChainId.ARBITRUM_RINKEBY]:
		"0xa501c031958F579dB7676fF1CE78AD305794d579",
};

export const V2_SALTS: AddressMap = {
	[SupportedChainId.OPTIMISM]:
		"0x1a76b7e7272f6187014e23f04d1b2e543eed2fd1f76481149008cc6eacb05c22",
	[SupportedChainId.ARBITRUM_ONE]:
		"0xe7acc5ac59be37798c12cd454547bfb17ddb35af53fcfa4a21342ba40d7de496",
};

export const V2_FACTORY_ADDRESSES: AddressMap = {
	[SupportedChainId.OPTIMISM]: "0x8BCeDD62DD46F1A76F8A1633d4f5B76e0CDa521E",
	[SupportedChainId.ARBITRUM_ONE]: "0x9e343Bea27a12B23523ad88333a1B0f68cc1F05E",
};

export const V2_ROUTER_ADDRESS: AddressMap = {
	[SupportedChainId.OPTIMISM]: "0x00000000000013adDDC0919642d45f5d9Df09502",
	[SupportedChainId.ARBITRUM_ONE]: "0x4D70D768f5E1e6a7062973aFB0c7FBDa9bBb42b3",
};

export const V2_NORMAL_ROUTER_ADDRESS: AddressMap = {
	[SupportedChainId.OPTIMISM]: "0xE6Df0BB08e5A97b40B21950a0A51b94c4DbA0Ff6",
	[SupportedChainId.ARBITRUM_ONE]: "0x4D70D768f5E1e6a7062973aFB0c7FBDa9bBb42b3",
};

export const AMOUNTS_QUOTER = "0x21fEaddD07BBd3A571d180a0803767D91055d1D9";

export const ILO_ADDRESS: AddressMap = {
	[SupportedChainId.OPTIMISM]: "0x5386C2af1C5901cE4a91C7EF2C8B07be931127B0",
};

/**
 * The oldest V0 governance address
 */
export const GOVERNANCE_ALPHA_V0_ADDRESSES: AddressMap =
	constructSameAddressMap("0x5e4be8Bc9637f0EAA1A755019e06A68ce081D58F");
/**
 * The older V1 governance address
 */
export const GOVERNANCE_ALPHA_V1_ADDRESSES: AddressMap = {
	[SupportedChainId.MAINNET]: "0xC4e172459f1E7939D522503B81AFAaC1014CE6F6",
};
/**
 * The latest governor bravo that is currently admin of timelock
 */
export const GOVERNANCE_BRAVO_ADDRESSES: AddressMap = {
	[SupportedChainId.MAINNET]: "0x408ED6354d4973f66138C91495F2f2FCbd8724C3",
};

export const TIMELOCK_ADDRESS: AddressMap = constructSameAddressMap(
	"0x1a9C8182C09F50C8318d769245beA52c32BE35BC"
);

export const MERKLE_DISTRIBUTOR_ADDRESS: AddressMap = {
	[SupportedChainId.MAINNET]: "0x090D4613473dEE047c3f2706764f49E0821D256e",
};
export const ARGENT_WALLET_DETECTOR_ADDRESS: AddressMap = {
	[SupportedChainId.MAINNET]: "0xeca4B0bDBf7c55E9b7925919d03CbF8Dc82537E8",
};
export const V3_CORE_FACTORY_ADDRESSES: AddressMap = constructSameAddressMap(
	V3_FACTORY_ADDRESS,
	[SupportedChainId.OPTIMISTIC_KOVAN, SupportedChainId.ARBITRUM_RINKEBY]
);
export const QUOTER_ADDRESSES: AddressMap = constructSameAddressMap(
	"0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6",
	[
		SupportedChainId.OPTIMISM,
		SupportedChainId.OPTIMISTIC_KOVAN,
		SupportedChainId.ARBITRUM_ONE,
		SupportedChainId.ARBITRUM_RINKEBY,
	]
);
export const NONFUNGIBLE_POSITION_MANAGER_ADDRESSES: AddressMap =
	constructSameAddressMap("0xC36442b4a4522E871399CD717aBDD847Ab11FE88", [
		SupportedChainId.OPTIMISM,
		SupportedChainId.OPTIMISTIC_KOVAN,
		SupportedChainId.ARBITRUM_ONE,
		SupportedChainId.ARBITRUM_RINKEBY,
	]);
export const ENS_REGISTRAR_ADDRESSES: AddressMap = {
	[SupportedChainId.MAINNET]: "0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e",
	[SupportedChainId.ROPSTEN]: "0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e",
	[SupportedChainId.GOERLI]: "0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e",
	[SupportedChainId.RINKEBY]: "0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e",
};
export const SOCKS_CONTROLLER_ADDRESSES: AddressMap = {
	[SupportedChainId.MAINNET]: "0x65770b5283117639760beA3F867b69b3697a91dd",
};
export const SWAP_ROUTER_ADDRESSES: AddressMap = constructSameAddressMap(
	"0xE592427A0AEce92De3Edee1F18E0157C05861564",
	[
		SupportedChainId.OPTIMISM,
		SupportedChainId.OPTIMISTIC_KOVAN,
		SupportedChainId.ARBITRUM_ONE,
		SupportedChainId.ARBITRUM_RINKEBY,
	]
);
export const V3_MIGRATOR_ADDRESSES: AddressMap = constructSameAddressMap(
	"0xA5644E29708357803b5A882D272c41cC0dF92B34",
	[SupportedChainId.ARBITRUM_ONE, SupportedChainId.ARBITRUM_RINKEBY]
);
