import {
	Currency,
	CurrencyAmount,
	Price,
	Token,
	TradeType,
} from "@uniswap/sdk-core";
import { useMemo } from "react";

import { SupportedChainId } from "../constants/chains";
import { DAI_OPTIMISM, USDC, USDC_ARBITRUM } from "../constants/tokens";
import { useBestV2Trade } from "./useBestV2Trade";
import { useClientSideV3Trade } from "./useClientSideV3Trade";
import { useActiveWeb3React } from "./web3";

// Stablecoin amounts used when calculating spot price for a given currency.
// The amount is large enough to filter low liquidity pairs.
const STABLECOIN_AMOUNT_OUT: { [chainId: number]: CurrencyAmount<Token> } = {
	[SupportedChainId.MAINNET]: CurrencyAmount.fromRawAmount(USDC, 100_000e6),
	[SupportedChainId.ARBITRUM_ONE]: CurrencyAmount.fromRawAmount(
		USDC_ARBITRUM,
		10_000e6
	),
	[SupportedChainId.OPTIMISM]: CurrencyAmount.fromRawAmount(
		DAI_OPTIMISM,
		10_000e18
	),
};

/**
 * Returns the price in USDC of the input currency
 * @param currency currency to compute the USDC price of
 */
export default function useUSDCPrice(
	currency?: Currency
): Price<Currency, Token> | undefined {
	const { chainId } = useActiveWeb3React();

	const amountOut = chainId ? STABLECOIN_AMOUNT_OUT[chainId] : undefined;
	const stablecoin = amountOut?.currency;

	// const v2USDCTrade = useBestV2Trade(
	// 	TradeType.EXACT_OUTPUT,
	// 	amountOut,
	// 	currency,
	// 	{
	// 		maxHops: 0,
	// 	}
	// );
	// const v3USDCTrade = useClientSideV3Trade(
	// 	TradeType.EXACT_OUTPUT,
	// 	amountOut,
	// 	currency
	// );

	const v3USDCTrade: null | any = null;

	const real = useMemo(() => {
		if (!currency || !stablecoin) {
			return undefined;
		}

		// handle usdc
		if (currency?.wrapped.equals(stablecoin)) {
			return new Price(stablecoin, stablecoin, "1", "1");
		}

		// use v2 price if available, v3 as fallback
		// if (v2USDCTrade) {
		// 	const { numerator, denominator } = v2USDCTrade.route.midPrice;
		// 	return new Price(currency, stablecoin, denominator, numerator);
		// } else if (v3USDCTrade && v3USDCTrade.trade) {
		// 	const { numerator, denominator } = v3USDCTrade.trade.route.midPrice;
		// 	return new Price(currency, stablecoin, denominator, numerator);
		// }

		return undefined;
	}, [currency, stablecoin]);

	return undefined;
}

export function useUSDCValue(
	currencyAmount: CurrencyAmount<Currency> | undefined | null
) {
	const price = useUSDCPrice(currencyAmount?.currency);

	return useMemo(() => {
		if (!price || !currencyAmount) return null;
		try {
			return price.quote(currencyAmount);
		} catch (error) {
			return null;
		}
	}, [currencyAmount, price]);
}
