import Row from "components/Row";
import useTheme from "hooks/useTheme";
import { Text } from "rebass";
import styled from "styled-components/macro";
import { version } from "../../../package.json";

const StyledPolling = styled.div`
	position: fixed;
	display: flex;
	align-items: center;
	left: 0;
	bottom: 0;
	padding: 1rem;
	transition: 250ms ease color;

	${({ theme }) => theme.mediaWidth.upToMedium`
    display: none;
  `}
`;

export default function Version() {
	const theme = useTheme();

	return (
		<div>
			<StyledPolling
				style={{
					marginTop: "1rem",
				}}
			>
				<Text fontSize={14} fontWeight={500} color={theme.text3}>
					Version {version}
				</Text>
			</StyledPolling>
		</div>
	);
}
