const AAVE_LIST = "tokenlist.aave.eth";
const BA_LIST =
	"https://raw.githubusercontent.com/The-Blockchain-Association/sec-notice-list/master/ba-sec-list.json";
const CMC_ALL_LIST =
	"https://api.coinmarketcap.com/data-api/v3/zipswap/all.json";
const COINGECKO_LIST = "https://tokens.coingecko.com/zipswap/all.json";
const COMPOUND_LIST =
	"https://raw.githubusercontent.com/compound-finance/token-list/master/compound.tokenlist.json";
const GEMINI_LIST = "https://www.gemini.com/zipswap/manifest.json";
export const ARBITRUM_LIST = "https://bridge.arbitrum.io/token-list-42161.json";
const KLEROS_LIST = "t2crtokens.eth";
export const OPTIMISM_LIST =
	"https://static.optimism.io/optimism.tokenlist.json";
const ROLL_LIST = "https://app.tryroll.com/tokens.json";
const SET_LIST =
	"https://raw.githubusercontent.com/SetProtocol/zipswap-tokenlist/main/set.tokenlist.json";
const WRAPPED_LIST = "wrapped.tokensoft.eth";

export const UNSUPPORTED_LIST_URLS: string[] = [BA_LIST];

// this is the default list of lists that are exposed to users
// lower index == higher priority for token import
const DEFAULT_LIST_OF_LISTS_TO_DISPLAY: string[] = [
	OPTIMISM_LIST,
	ARBITRUM_LIST,
	"https://data.zipswap.fi/tokenlist.json",
];

export const DEFAULT_LIST_OF_LISTS: string[] = [
	...DEFAULT_LIST_OF_LISTS_TO_DISPLAY,
	...UNSUPPORTED_LIST_URLS, // need to load dynamic unsupported tokens as well
];

// default lists to be 'active' aka searched across
export const DEFAULT_ACTIVE_LIST_URLS: string[] = [
	...DEFAULT_LIST_OF_LISTS_TO_DISPLAY,
];
