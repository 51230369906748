import { CurrencyAmount, Token } from "@uniswap/sdk-core";

export const roundCurrency = (
	amount: CurrencyAmount<Token> | undefined,
	decimals: number = 0
) => {
	if (!amount) return "0";

	const text = amount.toExact();
	const d = Math.pow(10, decimals);
	const num = Math.round(parseFloat(text) * d) / d;
	return num.toFixed(decimals).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
