import { gql } from "graphql-tag";
import { blockClient, client } from "./apollo";
import dayjs from "dayjs";
import ApolloClient from "apollo-client";
import { NormalizedCacheObject } from "apollo-cache-inmemory";
import { DocumentNode } from "graphql";

const PairFields = `
  fragment PairFields on Pair {
    id
    txCount
    token0 {
      id
      symbol
      name
      totalLiquidity
      derivedETH
    }
    token1 {
      id
      symbol
      name
      totalLiquidity
      derivedETH
    }
    reserve0
    reserve1
    reserveUSD
    totalSupply
    trackedReserveETH
    reserveETH
    volumeUSD
    token0Price
    token1Price
    createdAtTimestamp
  }
`;

export const PAIRS_BULK = gql`
	${PairFields}
	query pairs($allPairs: [Bytes]!) {
		pairs(
			where: { id_in: $allPairs }
			orderBy: trackedReserveETH
			orderDirection: desc
		) {
			...PairFields
		}
	}
`;

export const GET_BLOCKS = (timestamps: number[]) => {
	let queryString = "query blocks {";

	queryString += timestamps.map((timestamp) => {
		return `t${timestamp}:blocks(first: 1, orderBy: timestamp, orderDirection: desc, where: { timestamp_gt: ${timestamp}, timestamp_lt: ${
			timestamp + 600
		} }) {
      number
    }`;
	});
	queryString += "}";

	return gql`
		${queryString}
	`;
};

export const PAIR_DATA = (pairAddress: string, block: number) => {
	const queryString = gql`
    ${PairFields}
    query pairs {
      pairs(${
				block ? `block: {number: ${block}}` : ``
			} where: { id: "${pairAddress}"} ) {
        ...PairFields
      }
    }`;

	return gql`
		${queryString}
	`;
};

export const PAIRS_HISTORICAL_BULK = (block: number, pairs: string[]) => {
	let pairsString = `[`;
	pairs.map((pair) => {
		return (pairsString += `"${pair}"`);
	});
	pairsString += "]";
	let queryString = `
  query pairs {
    pairs(first: 200, where: {id_in: ${pairsString}}, block: {number: ${block}}, orderBy: trackedReserveETH, orderDirection: desc) {
      id
      reserveUSD
      trackedReserveETH
      volumeUSD
    }
  }
  `;

	return gql`
		${queryString}
	`;
};

export const splitQuery = async (
	query: (vars: any[]) => DocumentNode,
	localClient: ApolloClient<NormalizedCacheObject>,
	_vars: any[],
	list: any[],
	skipCount = 100
) => {
	let fetchedData = {};
	let allFound = false;
	let skip = 0;

	while (!allFound) {
		let end = list.length;

		if (skip + skipCount < list.length) {
			end = skip + skipCount;
		}

		let sliced = list.slice(skip, end);
		let result = await localClient.query({
			query: query(sliced),
			fetchPolicy: "cache-first",
		});

		fetchedData = {
			...fetchedData,
			...result.data,
		};

		if (
			Object.keys(result.data).length < skipCount ||
			skip + skipCount > list.length
		) {
			allFound = true;
		} else {
			skip += skipCount;
		}
	}

	return fetchedData as { [key: string]: any };
};

export const getTimestampsForChanges = () => {
	const utcCurrentTime = dayjs();
	const t1 = utcCurrentTime.subtract(1, "day").startOf("minute").unix();
	const t2 = utcCurrentTime.subtract(2, "day").startOf("minute").unix();
	const tWeek = utcCurrentTime.subtract(1, "week").startOf("minute").unix();
	return [t1, t2, tWeek];
};

export const getBlocksFromTimestamps = async (timestamps: number[]) => {
	if (timestamps?.length === 0) {
		return [];
	}

	const fetchedData = await splitQuery(
		GET_BLOCKS,
		blockClient,
		[],
		timestamps,
		500
	);

	let blocks = [];
	if (fetchedData) {
		for (var t in fetchedData) {
			if (!fetchedData[t][0]) {
				continue;
			}

			blocks.push({
				timestamp: t.split("t")[1],
				number: fetchedData[t][0]["number"],
			});
		}
	}
	return blocks;
};

export const get2DayPercentChange = (
	valueNow: string,
	value24HoursAgo: string,
	value48HoursAgo: string
) => {
	// get volume info for both 24 hour periods
	let currentChange = parseFloat(valueNow) - parseFloat(value24HoursAgo);
	let previousChange =
		parseFloat(value24HoursAgo) - parseFloat(value48HoursAgo);

	const adjustedPercentChange =
		((currentChange - previousChange) / previousChange) * 100;

	if (isNaN(adjustedPercentChange) || !isFinite(adjustedPercentChange)) {
		return [currentChange, 0];
	}
	return [currentChange, adjustedPercentChange];
};

export const getPercentChange = (valueNow: string, value24HoursAgo: string) => {
	const adjustedPercentChange =
		((parseFloat(valueNow) - parseFloat(value24HoursAgo)) /
			parseFloat(value24HoursAgo)) *
		100;
	if (isNaN(adjustedPercentChange) || !isFinite(adjustedPercentChange)) {
		return 0;
	}
	return adjustedPercentChange;
};

const parseData = (
	data: any,
	oneDayData: any,
	twoDayData: any,
	oneWeekData: any,
	ethPrice: any,
	oneDayBlock: any
) => {
	// get volume changes
	const [oneDayVolumeUSD, volumeChangeUSD] = get2DayPercentChange(
		data?.volumeUSD,
		oneDayData?.volumeUSD ? oneDayData.volumeUSD : 0,
		twoDayData?.volumeUSD ? twoDayData.volumeUSD : 0
	);
	const [oneDayVolumeUntracked, volumeChangeUntracked] = get2DayPercentChange(
		data?.untrackedVolumeUSD,
		oneDayData?.untrackedVolumeUSD ? oneDayData?.untrackedVolumeUSD : "0",
		twoDayData?.untrackedVolumeUSD ? twoDayData?.untrackedVolumeUSD : 0
	);
	const oneWeekVolumeUSD = parseFloat(
		oneWeekData ? data?.volumeUSD - oneWeekData?.volumeUSD : data.volumeUSD
	);

	// set volume properties
	data.oneDayVolumeUSD = oneDayVolumeUSD;
	data.oneWeekVolumeUSD = oneWeekVolumeUSD;
	data.volumeChangeUSD = volumeChangeUSD;
	data.oneDayVolumeUntracked = oneDayVolumeUntracked;
	data.volumeChangeUntracked = volumeChangeUntracked;

	// set liquiditry properties
	data.trackedReserveUSD = data.trackedReserveETH * ethPrice;
	data.liquidityChangeUSD = getPercentChange(
		data.reserveUSD,
		oneDayData?.reserveUSD
	);

	// format if pair hasnt existed for a day or a week
	if (!oneDayData && data && data.createdAtBlockNumber > oneDayBlock) {
		data.oneDayVolumeUSD = parseFloat(data.volumeUSD);
	}
	if (!oneDayData && data) {
		data.oneDayVolumeUSD = parseFloat(data.volumeUSD);
	}
	if (!oneWeekData && data) {
		data.oneWeekVolumeUSD = parseFloat(data.volumeUSD);
	}
	if (data?.token0?.id === "0x4200000000000000000000000000000000000006") {
		data.token0.name = "Ether (Wrapped)";
		data.token0.symbol = "ETH";
	}
	if (data?.token1?.id === "0x4200000000000000000000000000000000000006") {
		data.token1.name = "Ether (Wrapped)";
		data.token1.symbol = "ETH";
	}
	return data;
};

export const getBulkPairData = async (pairList: string[]) => {
	const [t1, t2, tWeek] = getTimestampsForChanges();
	let data = await getBlocksFromTimestamps([t1, t2, tWeek]);

	console.log(data);

	if (!data || !data[0] || !data[1] || !data[2]) {
		return [];
	}

	const [{ number: b1 }, { number: b2 }, { number: bWeek }] = data;

	try {
		let ethPriceData = await client.query({
			query: gql`
				{
					bundle(id: "1") {
						ethPrice
					}
				}
			` as any,
			fetchPolicy: "cache-first",
		});

		if (!ethPriceData?.data?.bundle?.ethPrice) {
			throw new Error("No eth price");
		}

		const ethPrice = parseFloat(ethPriceData.data.bundle.ethPrice);

		let current = await client.query({
			query: PAIRS_BULK,
			variables: {
				allPairs: pairList,
			},
			fetchPolicy: "cache-first",
		});

		let [oneDayResult, twoDayResult, oneWeekResult] = await Promise.all(
			[b1, b2, bWeek].map(async (block) => {
				let result = client.query({
					query: PAIRS_HISTORICAL_BULK(block, pairList),
					fetchPolicy: "cache-first",
				});

				return result;
			})
		);

		let oneDayData = oneDayResult?.data?.pairs.reduce((obj: any, cur: any) => {
			return { ...obj, [cur.id]: cur };
		}, {});

		let twoDayData = twoDayResult?.data?.pairs.reduce((obj: any, cur: any) => {
			return { ...obj, [cur.id]: cur };
		}, {});

		let oneWeekData = oneWeekResult?.data?.pairs.reduce(
			(obj: any, cur: any) => {
				return { ...obj, [cur.id]: cur };
			},
			{}
		);

		let pairData = await Promise.all(
			current &&
				current.data.pairs.map(async (pair: any) => {
					let data = pair;
					let oneDayHistory = oneDayData?.[pair.id];

					if (!oneDayHistory) {
						let newData = await client.query({
							query: PAIR_DATA(pair.id, b1),
							fetchPolicy: "cache-first",
						});
						oneDayHistory = newData.data.pairs[0];
					}

					let twoDayHistory = twoDayData?.[pair.id];

					if (!twoDayHistory) {
						let newData = await client.query({
							query: PAIR_DATA(pair.id, b2),
							fetchPolicy: "cache-first",
						});
						twoDayHistory = newData.data.pairs[0];
					}

					let oneWeekHistory = oneWeekData?.[pair.id];

					if (!oneWeekHistory) {
						let newData = await client.query({
							query: PAIR_DATA(pair.id, bWeek),
							fetchPolicy: "cache-first",
						});
						oneWeekHistory = newData.data.pairs[0];
					}

					data = parseData(
						data,
						oneDayHistory,
						twoDayHistory,
						oneWeekHistory,
						ethPrice,
						b1
					);

					return data;
				})
		);
		return pairData;
	} catch (e) {
		console.log(e);
		return [];
	}
};
