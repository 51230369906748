import { transparentize } from "polished";
import { ReactNode, useCallback, useState } from "react";
import styled from "styled-components/macro";

import Popover, { PopoverProps } from "../Popover";

export const TooltipContainer = styled.div`
  width: 256px;
  padding: 0.6rem 1rem;
  font-weight: 400;
  word-break: break-word;

  background: ${({ theme }) => theme.bg0};
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.bg2};
  box-shadow: 0 4px 8px 0 ${({ theme }) => transparentize(0.9, theme.shadow1)};
`;

interface TooltipProps extends Omit<PopoverProps, "content"> {
  text: ReactNode;
}

interface TooltipContentProps extends Omit<PopoverProps, "content"> {
  content: ReactNode;
  onOpen?: () => void;
  // whether to wrap the content in a `TooltipContainer`
  wrap?: boolean;
}

export default function Tooltip({ text, ...rest }: TooltipProps) {
  return (
    <Popover content={<TooltipContainer>{text}</TooltipContainer>} {...rest} />
  );
}

function TooltipContent({
  content,
  wrap = false,
  ...rest
}: TooltipContentProps) {
  return (
    <Popover
      content={wrap ? <TooltipContainer>{content}</TooltipContainer> : content}
      {...rest}
    />
  );
}

export function MouseoverTooltip({
  children,
  ...rest
}: Omit<TooltipProps, "show">) {
  const [show, setShow] = useState(false);
  const open = useCallback(() => setShow(true), [setShow]);
  const close = useCallback(() => setShow(false), [setShow]);
  return (
    <Tooltip {...rest} show={show}>
      <div onMouseEnter={open} onMouseLeave={close}>
        {children}
      </div>
    </Tooltip>
  );
}

export function MouseoverTooltipContent({
  content,
  children,
  onOpen: openCallback = undefined,
  ...rest
}: Omit<TooltipContentProps, "show">) {
  const [show, setShow] = useState(false);
  const open = useCallback(() => {
    setShow(true);
    openCallback?.();
  }, [openCallback]);
  const close = useCallback(() => setShow(false), [setShow]);
  return (
    <TooltipContent {...rest} show={show} content={content}>
      <div
        style={{ display: "inline-block", lineHeight: 0, padding: "0.25rem" }}
        onMouseEnter={open}
        onMouseLeave={close}
      >
        {children}
      </div>
    </TooltipContent>
  );
}
