import { useActiveWeb3React } from "hooks/web3";
import { useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import { getCLS, getFCP, getFID, getLCP, Metric } from "web-vitals";

function reportWebVitals({ name, delta, id }: Metric) {}

// tracks web vitals and pageviews
export default function GoogleAnalyticsReporter({
	location: { pathname, search },
}: RouteComponentProps): null {
	useEffect(() => {
		getFCP(reportWebVitals);
		getFID(reportWebVitals);
		getLCP(reportWebVitals);
		getCLS(reportWebVitals);
	}, []);

	const { chainId } = useActiveWeb3React();
	useEffect(() => {
		// cd1 - custom dimension 1 - chainId
	}, [chainId]);

	useEffect(() => {}, [pathname, search]);
	return null;
}
