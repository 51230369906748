import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { SupportedChainId } from "constants/chains";
import qs from "qs";

import { GetQuoteResult } from "./types";

export const routingApi = createApi({
	reducerPath: "routingApi",
	baseQuery: fetchBaseQuery({
		baseUrl: "https://api.zipswap.fi/v1/",
	}),
	endpoints: (build) => ({
		getQuote: build.query<
			GetQuoteResult,
			{
				tokenInAddress: string;
				tokenInChainId: SupportedChainId;
				tokenOutAddress: string;
				tokenOutChainId: SupportedChainId;
				amount: string;
				type: "exactIn" | "exactOut";
			}
		>({
			query: (args) => `quote?${qs.stringify({ ...args, protocols: "v3" })}`,
		}),
	}),
});

export const { useGetQuoteQuery } = routingApi;
