import { formatUnits, parseUnits } from "@ethersproject/units";
import { Trans } from "lib/trans";
import {
	Currency,
	CurrencyAmount,
	Percent,
	TradeType,
} from "@uniswap/sdk-core";
import { Trade as V2Trade } from "@uniswap/v2-sdk";
import { Trade as V3Trade } from "@uniswap/v3-sdk";
import { LoadingRows } from "components/Loader/styled";
import { roundSlippage } from "pages/AddLiquidityV2/tools";
import { useContext, useMemo } from "react";
import { ThemeContext } from "styled-components/macro";

import { TYPE } from "../../theme";
import { computeRealizedLPFeePercent } from "../../utils/prices";
import { AutoColumn } from "../Column";
import { RowBetween, RowFixed } from "../Row";
import FormattedPriceImpact from "./FormattedPriceImpact";
import { TransactionDetailsLabel } from "./styleds";

interface AdvancedSwapDetailsProps {
	trade?:
		| V2Trade<Currency, Currency, TradeType>
		| V3Trade<Currency, Currency, TradeType>;
	allowedSlippage: Percent;
	enforceSlippage: boolean;
	syncing?: boolean;
}

function TextWithLoadingPlaceholder({
	syncing,
	width,
	children,
}: {
	syncing: boolean;
	width: number;
	children: JSX.Element;
}) {
	return syncing ? (
		<LoadingRows>
			<div style={{ height: "15px", width: `${width}px` }} />
		</LoadingRows>
	) : (
		children
	);
}

const toBN = (amount: CurrencyAmount<Currency>) => {
	const decimals = amount.currency.decimals;
	return parseUnits(amount.toExact(), decimals);
};

export function AdvancedSwapDetails({
	trade,
	allowedSlippage,
	enforceSlippage,
	syncing = false,
}: AdvancedSwapDetailsProps) {
	const theme = useContext(ThemeContext);

	const { realizedLPFee, priceImpact } = useMemo(() => {
		if (!trade) return { realizedLPFee: undefined, priceImpact: undefined };

		const realizedLpFeePercent = computeRealizedLPFeePercent(trade);
		const realizedLPFee = trade.inputAmount.multiply(realizedLpFeePercent);
		const priceImpact = trade.priceImpact.subtract(realizedLpFeePercent);
		return { priceImpact, realizedLPFee };
	}, [trade]);

	return !trade ? null : (
		<AutoColumn gap="8px">
			<TransactionDetailsLabel fontWeight={500} fontSize={14}>
				<Trans>Transaction Details</Trans>
			</TransactionDetailsLabel>
			<RowBetween>
				<RowFixed>
					<TYPE.subHeader color={theme.text1}>
						<Trans>Liquidity Provider Fee</Trans>
					</TYPE.subHeader>
				</RowFixed>
				<TextWithLoadingPlaceholder syncing={syncing} width={65}>
					<TYPE.black textAlign="right" fontSize={14}>
						{realizedLPFee
							? `${realizedLPFee.toSignificant(4)} ${
									realizedLPFee.currency.symbol
							  }`
							: "-"}
					</TYPE.black>
				</TextWithLoadingPlaceholder>
			</RowBetween>

			<RowBetween>
				<RowFixed>
					<TYPE.subHeader color={theme.text1}>
						<Trans>Price Impact</Trans>
					</TYPE.subHeader>
				</RowFixed>
				<TextWithLoadingPlaceholder syncing={syncing} width={50}>
					<TYPE.black textAlign="right" fontSize={14}>
						<FormattedPriceImpact priceImpact={priceImpact} />
					</TYPE.black>
				</TextWithLoadingPlaceholder>
			</RowBetween>

			{enforceSlippage && (
				<RowBetween>
					<RowFixed>
						<TYPE.subHeader color={theme.text1}>
							<Trans>Allowed Slippage</Trans>
						</TYPE.subHeader>
					</RowFixed>
					<TextWithLoadingPlaceholder syncing={syncing} width={45}>
						<TYPE.black textAlign="right" fontSize={14}>
							{allowedSlippage.toFixed(2)}%
						</TYPE.black>
					</TextWithLoadingPlaceholder>
				</RowBetween>
			)}

			{(enforceSlippage || trade.tradeType !== TradeType.EXACT_INPUT) && (
				<RowBetween>
					<RowFixed>
						<TYPE.subHeader color={theme.text1}>
							{trade.tradeType === TradeType.EXACT_INPUT ? (
								<Trans>Minimum received</Trans>
							) : (
								<Trans>Maximum sent</Trans>
							)}
						</TYPE.subHeader>
					</RowFixed>
					<TextWithLoadingPlaceholder syncing={syncing} width={70}>
						<TYPE.black textAlign="right" fontSize={14}>
							{trade.tradeType === TradeType.EXACT_INPUT
								? `${parseFloat(
										formatUnits(
											roundSlippage(
												toBN(trade.outputAmount),
												toBN(trade.minimumAmountOut(allowedSlippage))
											),
											trade.outputAmount.currency.decimals
										)
								  ).toFixed(6)} ${trade.outputAmount.currency.symbol}`
								: `${trade.maximumAmountIn(allowedSlippage).toSignificant(6)} ${
										trade.inputAmount.currency.symbol
								  }`}
						</TYPE.black>
					</TextWithLoadingPlaceholder>
				</RowBetween>
			)}
		</AutoColumn>
	);
}
