import { TransactionResponse } from "@ethersproject/providers";
import { Trans } from "lib/trans";
import { CurrencyAmount, Token } from "@uniswap/sdk-core";
import { Pair } from "@uniswap/v2-sdk";
import { useCallback, useMemo, useState } from "react";
import styled from "styled-components/macro";

import {
	ApprovalState,
	useApproveCallback,
} from "../../hooks/useApproveCallback";
import {
	useStakingContract,
	useV2RouterContract,
} from "../../hooks/useContract";
import { useV2LiquidityTokenPermit } from "../../hooks/useERC20Permit";
import useTransactionDeadline from "../../hooks/useTransactionDeadline";
import { useActiveWeb3React } from "../../hooks/web3";
import { StakingInfo, useDerivedStakeInfo } from "../../state/stake/hooks";
import { TransactionType } from "../../state/transactions/actions";
import { useTransactionAdder } from "../../state/transactions/hooks";
import { CloseIcon, TYPE } from "../../theme";
import { formatCurrencyAmount } from "../../utils/formatCurrencyAmount";
import { maxAmountSpend } from "../../utils/maxAmountSpend";
import { ButtonConfirmed, ButtonError } from "../Button";
import { AutoColumn } from "../Column";
import CurrencyInputPanel from "../CurrencyInputPanel";
import Modal from "../Modal";
import { LoadingView, SubmittedView } from "../ModalViews";
import ProgressCircles from "../ProgressSteps";
import { RowBetween } from "../Row";
import { extraGas } from "utils/gas";
import { orderTokens } from "utils/orderTokens";
import { NoUndefinedVariablesRule } from "graphql";

const HypotheticalRewardRate = styled.div<{ dim: boolean }>`
	display: flex;
	justify-content: space-between;
	padding-right: 20px;
	padding-left: 20px;

	opacity: ${({ dim }) => (dim ? 0.5 : 1)};
`;

const ContentWrapper = styled(AutoColumn)`
	width: 100%;
	padding: 1rem;
`;

interface StakingModalProps {
	isOpen: boolean;
	onDismiss: () => void;
	stakingInfo: StakingInfo;
	userLiquidityUnstaked: CurrencyAmount<Token> | undefined;
	stakingRewardAddress: string;
}

export default function StakingModal({
	isOpen,
	onDismiss,
	stakingInfo,
	userLiquidityUnstaked,
	stakingRewardAddress,
}: StakingModalProps) {
	const { library, account } = useActiveWeb3React();

	// track and parse user input
	const [typedValue, setTypedValue] = useState("");
	const { parsedAmount, error } = useDerivedStakeInfo(
		typedValue,
		stakingInfo.stakedAmount.currency,
		userLiquidityUnstaked
	);
	const parsedAmountWrapped = parsedAmount?.wrapped;

	let hypotheticalRewardRate: CurrencyAmount<Token> =
		CurrencyAmount.fromRawAmount(stakingInfo.rewardRates[0].currency, "0");
	if (parsedAmountWrapped?.greaterThan("0")) {
		hypotheticalRewardRate = stakingInfo.getHypotheticalRewardRate(
			stakingInfo.stakedAmount.add(parsedAmountWrapped),
			stakingInfo.totalStakedAmount.add(parsedAmountWrapped),
			stakingInfo.totalRewardRates[0]
		);
	}

	// state for pending and submitted txn views
	const addTransaction = useTransactionAdder();
	const [attempting, setAttempting] = useState<boolean>(false);
	const [hash, setHash] = useState<string | undefined>();
	const wrappedOnDismiss = useCallback(() => {
		setHash(undefined);
		setAttempting(false);
		onDismiss();
	}, [onDismiss]);

	// pair contract for this token to be staked
	const dummyPair = stakingInfo.tokens
		? new Pair(
				CurrencyAmount.fromRawAmount(stakingInfo.tokens[0], "0"),
				CurrencyAmount.fromRawAmount(stakingInfo.tokens[1], "0")
		  )
		: undefined;

	const ordered = useMemo(
		() => (stakingInfo.tokens ? orderTokens(stakingInfo.tokens) : [null, null]),
		[stakingInfo]
	);

	// approval data for stake
	const deadline = useTransactionDeadline();

	const { signatureData, gatherPermitSignature } = useV2LiquidityTokenPermit(
		parsedAmountWrapped,
		stakingRewardAddress,
		ordered[0] && ordered[1]
			? `${ordered[0].symbol}-${ordered[1].symbol}`
			: stakingInfo.lp.name!,
		ordered[0] && ordered[1]
			? undefined
			: {
					name: stakingInfo.lp.name!,
					type: 1,
					version: "1",
			  }
	);

	const [approval, approveCallback] = useApproveCallback(
		parsedAmount,
		stakingRewardAddress
	);

	const stakingContract = useStakingContract(stakingRewardAddress);

	async function onStake() {
		setAttempting(true);

		if (stakingContract && parsedAmount && deadline) {
			if (approval === ApprovalState.APPROVED) {
				type Params = Parameters<typeof stakingContract.deposit>;

				const params: Params = [
					stakingInfo.pid,
					`0x${parsedAmount.quotient.toString(16)}`,
					account || "",
				];

				return stakingContract.estimateGas
					.deposit(...params)
					.then((gas) => {
						stakingContract
							.deposit(
								...([
									...params,
									{ gasLimit: extraGas(gas) },
								] as unknown as Params)
							)
							.then((response: TransactionResponse) => {
								addTransaction(response, {
									type: TransactionType.DEPOSIT_LIQUIDITY_STAKING,
									lp: stakingInfo.lp.address,
								});
								setHash(response.hash);
							})
							.catch((error: any) => {
								setAttempting(false);
								console.log(error);
							});
					})
					.catch((error: any) => {
						setAttempting(false);
						console.log(error);
					});
			} else if (signatureData) {
				type Params = Parameters<typeof stakingContract.depositWithPermit>;

				const params: Params = [
					stakingInfo.pid,
					`0x${parsedAmount.quotient.toString(16)}`,
					account || "",
					signatureData.deadline,
					signatureData.v,
					signatureData.r,
					signatureData.s,
				];

				return stakingContract.estimateGas
					.depositWithPermit(...params)
					.then((gas) => {
						stakingContract
							.depositWithPermit(
								...([
									...params,
									{ gasLimit: extraGas(gas) },
								] as unknown as Params)
							)
							.then((response: TransactionResponse) => {
								addTransaction(response, {
									type: TransactionType.DEPOSIT_LIQUIDITY_STAKING,
									lp: stakingInfo.lp.address,
								});
								setHash(response.hash);
							})
							.catch((error: any) => {
								setAttempting(false);
								console.log(error);
							});
					})
					.catch((error: any) => {
						setAttempting(false);
						console.log(error);
					});
			} else {
				setAttempting(false);
				throw new Error(
					"Attempting to stake without approval or a signature. Please contact support."
				);
			}
		}
	}

	// wrapped onUserInput to clear signatures
	const onUserInput = useCallback((typedValue: string) => {
		setTypedValue(typedValue);
	}, []);

	// used for max input button
	const maxAmountInput = maxAmountSpend(userLiquidityUnstaked);
	const atMaxAmount = Boolean(
		maxAmountInput && parsedAmount?.equalTo(maxAmountInput)
	);
	const handleMax = useCallback(() => {
		maxAmountInput && onUserInput(maxAmountInput.toExact());
	}, [maxAmountInput, onUserInput]);

	async function onAttemptToApprove() {
		if (!library || !deadline) throw new Error("missing dependencies");
		if (!parsedAmount) throw new Error("missing liquidity amount");

		if (gatherPermitSignature) {
			try {
				await gatherPermitSignature();
			} catch (error) {
				// try to approve if gatherPermitSignature failed for any reason other than the user rejecting it
				if (error?.code !== 4001) {
					await approveCallback();
				}
			}
		} else {
			await approveCallback();
		}
	}

	return (
		<Modal isOpen={isOpen} onDismiss={wrappedOnDismiss} maxHeight={90}>
			{!attempting && !hash && (
				<ContentWrapper gap="lg">
					<RowBetween>
						<TYPE.mediumHeader>
							<Trans>Deposit</Trans>
						</TYPE.mediumHeader>
						<CloseIcon onClick={wrappedOnDismiss} />
					</RowBetween>
					<CurrencyInputPanel
						value={typedValue}
						onUserInput={onUserInput}
						onMax={handleMax}
						showMaxButton={!atMaxAmount}
						currency={stakingInfo.stakedAmount.currency}
						pair={dummyPair}
						label={""}
						renderBalance={(amount) => (
							<Trans>
								Available to deposit: {formatCurrencyAmount(amount, 4)}
							</Trans>
						)}
						id="stake-liquidity-token"
					/>

					<HypotheticalRewardRate
						dim={!hypotheticalRewardRate.greaterThan("0")}
					>
						<div>
							<TYPE.black fontWeight={600}>
								<Trans>Weekly Rewards</Trans>
							</TYPE.black>
						</div>

						<TYPE.black>
							<Trans>
								{hypotheticalRewardRate
									.multiply((60 * 60 * 24 * 7).toString())
									.toSignificant(4, { groupSeparator: "," })}{" "}
								ZIP / week
							</Trans>
						</TYPE.black>
					</HypotheticalRewardRate>

					<RowBetween>
						<ButtonConfirmed
							mr="0.5rem"
							onClick={onAttemptToApprove}
							confirmed={
								approval === ApprovalState.APPROVED || signatureData !== null
							}
							disabled={
								approval !== ApprovalState.NOT_APPROVED ||
								signatureData !== null
							}
						>
							<Trans>Approve</Trans>
						</ButtonConfirmed>
						<ButtonError
							disabled={
								!!error ||
								(signatureData === null && approval !== ApprovalState.APPROVED)
							}
							error={!!error && !!parsedAmount}
							onClick={onStake}
						>
							{error ?? <Trans>Deposit</Trans>}
						</ButtonError>
					</RowBetween>
					<ProgressCircles
						steps={[
							approval === ApprovalState.APPROVED || signatureData !== null,
						]}
						disabled={true}
					/>
				</ContentWrapper>
			)}
			{attempting && !hash && (
				<LoadingView onDismiss={wrappedOnDismiss}>
					<AutoColumn gap="12px" justify={"center"}>
						<TYPE.largeHeader>
							<Trans>Depositing Liquidity</Trans>
						</TYPE.largeHeader>
						<TYPE.body fontSize={20}>
							<Trans>
								{parsedAmount?.toSignificant(4)}{" "}
								{stakingInfo.tokens ? "ZIP-V2" : stakingInfo.lp.symbol}
							</Trans>
						</TYPE.body>
					</AutoColumn>
				</LoadingView>
			)}
			{attempting && hash && (
				<SubmittedView onDismiss={wrappedOnDismiss} hash={hash}>
					<AutoColumn gap="12px" justify={"center"}>
						<TYPE.largeHeader>
							<Trans>Transaction Submitted</Trans>
						</TYPE.largeHeader>
						<TYPE.body fontSize={20}>
							<Trans>
								Deposited {parsedAmount?.toSignificant(4)}{" "}
								{stakingInfo.tokens ? "ZIP-V2" : stakingInfo.lp.symbol}
							</Trans>
						</TYPE.body>
					</AutoColumn>
				</SubmittedView>
			)}
		</Modal>
	);
}
