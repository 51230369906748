import { TransactionResponse } from "@ethersproject/providers";
import {
	Currency,
	CurrencyAmount,
	Percent,
	TradeType,
} from "@uniswap/sdk-core";
import { Trade as V2Trade } from "@uniswap/v2-sdk";
import { Trade as V3Trade } from "@uniswap/v3-sdk";
import { useCallback, useMemo } from "react";
import { BigNumber } from "@ethersproject/bignumber";

import {
	SWAP_ROUTER_ADDRESSES,
	V2_ROUTER_ADDRESS,
} from "../constants/addresses";
import { TransactionType } from "../state/transactions/actions";
import {
	useHasPendingApproval,
	useTransactionAdder,
} from "../state/transactions/hooks";
import { calculateGasMargin } from "../utils/calculateGasMargin";
import { useTokenContract } from "./useContract";
import { useTokenAllowance } from "./useTokenAllowance";
import { useActiveWeb3React } from "./web3";

export enum ApprovalState {
	UNKNOWN = "UNKNOWN",
	NOT_APPROVED = "NOT_APPROVED",
	PENDING = "PENDING",
	APPROVED = "APPROVED",
}

const MaxUint256 = BigNumber.from(2).pow(255);

// returns a variable indicating the state of the approval and a function which approves if necessary or early returns
export function useApproveCallback(
	amountToApprove?: CurrencyAmount<Currency>,
	spender?: string
): [ApprovalState, () => Promise<void>] {
	const { account, chainId } = useActiveWeb3React();
	const token = amountToApprove?.currency?.isToken
		? amountToApprove.currency
		: undefined;
	const currentAllowance = useTokenAllowance(
		token,
		account ?? undefined,
		spender
	);
	const pendingApproval = useHasPendingApproval(token?.address, spender);

	// check the current approval status
	const approvalState: ApprovalState = useMemo(() => {
		if (!amountToApprove || !spender) return ApprovalState.UNKNOWN;
		if (amountToApprove.currency.isNative) return ApprovalState.APPROVED;
		// we might not have enough data to know whether or not we need to approve
		if (!currentAllowance) return ApprovalState.UNKNOWN;

		// amountToApprove will be defined if currentAllowance is
		return currentAllowance.lessThan(amountToApprove)
			? pendingApproval
				? ApprovalState.PENDING
				: ApprovalState.NOT_APPROVED
			: ApprovalState.APPROVED;
	}, [amountToApprove, currentAllowance, pendingApproval, spender]);

	const tokenContract = useTokenContract(token?.address);
	const addTransaction = useTransactionAdder();

	const approve = useCallback(async (): Promise<void> => {
		if (approvalState !== ApprovalState.NOT_APPROVED) {
			console.error("approve was called unnecessarily");
			return;
		}
		if (!chainId) {
			console.error("no chainId");
			return;
		}

		if (!token) {
			console.error("no token");
			return;
		}

		if (!tokenContract) {
			console.error("tokenContract is null");
			return;
		}

		if (!amountToApprove) {
			console.error("missing amount to approve");
			return;
		}

		if (!spender) {
			console.error("no spender");
			return;
		}

		let toApprove = BigNumber.from(amountToApprove.quotient.toString());

		if (toApprove.gt(MaxUint256)) {
			toApprove = MaxUint256;
		}

		let useExact = false;
		const estimatedGas = await tokenContract.estimateGas
			.approve(spender, MaxUint256)
			.catch(() => {
				// general fallback for tokens who restrict approval amounts
				useExact = true;
				return tokenContract.estimateGas.approve(spender, toApprove);
			});

		return tokenContract
			.approve(spender, useExact ? toApprove : MaxUint256, {
				gasLimit: calculateGasMargin(estimatedGas),
			})
			.then((response: TransactionResponse) => {
				addTransaction(response, {
					type: TransactionType.APPROVAL,
					tokenAddress: token.address,
					spender,
				});
			})
			.catch((error: Error) => {
				console.debug("Failed to approve token", error);
				throw error;
			});
	}, [
		approvalState,
		token,
		tokenContract,
		amountToApprove,
		spender,
		addTransaction,
		chainId,
	]);

	return [approvalState, approve];
}

// wraps useApproveCallback in the context of a swap
export function useApproveCallbackFromTrade(
	trade:
		| V2Trade<Currency, Currency, TradeType>
		| V3Trade<Currency, Currency, TradeType>
		| undefined,
	allowedSlippage: Percent
) {
	const { chainId } = useActiveWeb3React();
	const v3SwapRouterAddress = chainId
		? SWAP_ROUTER_ADDRESSES[chainId]
		: undefined;
	const amountToApprove = useMemo(
		() =>
			trade && trade.inputAmount.currency.isToken
				? trade.maximumAmountIn(allowedSlippage)
				: undefined,
		[trade, allowedSlippage]
	);
	return useApproveCallback(
		amountToApprove,
		chainId
			? trade instanceof V2Trade
				? V2_ROUTER_ADDRESS[chainId]
				: trade instanceof V3Trade
				? v3SwapRouterAddress
				: undefined
			: undefined
	);
}
