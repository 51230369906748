import { formatUnits, parseUnits } from "@ethersproject/units";
import { Trans } from "lib/trans";
import {
	Currency,
	CurrencyAmount,
	Percent,
	TradeType,
} from "@uniswap/sdk-core";
import { Trade as V2Trade } from "@uniswap/v2-sdk";
import { Trade as V3Trade } from "@uniswap/v3-sdk";
import { roundSlippage } from "pages/AddLiquidityV2/tools";
import { useContext, useState } from "react";
import { AlertTriangle, ArrowDown } from "react-feather";
import { Text } from "rebass";
import styled, { ThemeContext } from "styled-components/macro";

import { useUSDCValue } from "../../hooks/useUSDCPrice";
import { TYPE } from "../../theme";
import { isAddress, shortenAddress } from "../../utils";
import { computeFiatValuePriceImpact } from "../../utils/computeFiatValuePriceImpact";
import { ButtonPrimary } from "../Button";
import { LightCard } from "../Card";
import { AutoColumn } from "../Column";
import { FiatValue } from "../CurrencyInputPanel/FiatValue";
import CurrencyLogo from "../CurrencyLogo";
import { RowBetween, RowFixed } from "../Row";
import TradePrice from "../swap/TradePrice";
import { AdvancedSwapDetails } from "./AdvancedSwapDetails";
import { SwapShowAcceptChanges, TruncatedText } from "./styleds";

const ArrowWrapper = styled.div`
	padding: 4px;
	border-radius: 12px;
	height: 32px;
	width: 32px;
	position: relative;
	margin-top: -18px;
	margin-bottom: -18px;
	left: calc(50% - 16px);
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: ${({ theme }) => theme.bg1};
	border: 4px solid;
	border-color: ${({ theme }) => theme.bg0};
	z-index: 2;
`;

const toBN = (amount: CurrencyAmount<Currency>) => {
	const decimals = amount.currency.decimals;
	return parseUnits(amount.toExact(), decimals);
};

export default function SwapModalHeader({
	trade,
	allowedSlippage,
	recipient,
	showAcceptChanges,
	onAcceptChanges,
	enforceSlippage,
}: {
	trade:
		| V2Trade<Currency, Currency, TradeType>
		| V3Trade<Currency, Currency, TradeType>;
	allowedSlippage: Percent;
	recipient: string | null;
	showAcceptChanges: boolean;
	onAcceptChanges: () => void;
	enforceSlippage: boolean;
}) {
	const theme = useContext(ThemeContext);

	const [showInverted, setShowInverted] = useState<boolean>(false);

	const fiatValueInput = useUSDCValue(trade.inputAmount);
	const fiatValueOutput = useUSDCValue(trade.outputAmount);

	return (
		<AutoColumn gap={"4px"} style={{ marginTop: "1rem" }}>
			<LightCard padding="0.75rem 1rem">
				<AutoColumn gap={"8px"}>
					<RowBetween>
						<TYPE.body color={theme.text3} fontWeight={500} fontSize={14}>
							<Trans>From</Trans>
						</TYPE.body>
						<FiatValue fiatValue={fiatValueInput} />
					</RowBetween>
					<RowBetween align="center">
						<RowFixed gap={"0px"}>
							<CurrencyLogo
								currency={trade.inputAmount.currency}
								size={"20px"}
								style={{ marginRight: "12px" }}
							/>
							<Text fontSize={20} fontWeight={500}>
								{trade.inputAmount.currency.symbol}
							</Text>
						</RowFixed>
						<RowFixed gap={"0px"}>
							<TruncatedText
								fontSize={24}
								fontWeight={500}
								color={
									showAcceptChanges &&
									trade.tradeType === TradeType.EXACT_OUTPUT
										? theme.primary1
										: ""
								}
							>
								{trade.inputAmount.toSignificant(6)}
							</TruncatedText>
						</RowFixed>
					</RowBetween>
				</AutoColumn>
			</LightCard>
			<ArrowWrapper>
				<ArrowDown size="16" color={theme.text2} />
			</ArrowWrapper>
			<LightCard padding="0.75rem 1rem" style={{ marginBottom: "0.25rem" }}>
				<AutoColumn gap={"8px"}>
					<RowBetween>
						<TYPE.body color={theme.text3} fontWeight={500} fontSize={14}>
							<Trans>To</Trans>
						</TYPE.body>
						<TYPE.body fontSize={14} color={theme.text3}>
							<FiatValue
								fiatValue={fiatValueOutput}
								priceImpact={computeFiatValuePriceImpact(
									fiatValueInput,
									fiatValueOutput
								)}
							/>
						</TYPE.body>
					</RowBetween>
					<RowBetween align="flex-end">
						<RowFixed gap={"0px"}>
							<CurrencyLogo
								currency={trade.outputAmount.currency}
								size={"20px"}
								style={{ marginRight: "12px" }}
							/>
							<Text fontSize={20} fontWeight={500}>
								{trade.outputAmount.currency.symbol}
							</Text>
						</RowFixed>
						<RowFixed gap={"0px"}>
							<TruncatedText fontSize={24} fontWeight={500}>
								{trade.outputAmount.toSignificant(6)}
							</TruncatedText>
						</RowFixed>
					</RowBetween>
				</AutoColumn>
			</LightCard>
			<RowBetween style={{ marginTop: "0.25rem", padding: "0 1rem" }}>
				<TYPE.body color={theme.text2} fontWeight={500} fontSize={14}>
					<Trans>Price</Trans>
				</TYPE.body>
				<TradePrice
					price={trade.executionPrice}
					showInverted={showInverted}
					setShowInverted={setShowInverted}
				/>
			</RowBetween>

			<LightCard style={{ padding: ".75rem", marginTop: "0.5rem" }}>
				<AdvancedSwapDetails
					trade={trade}
					allowedSlippage={allowedSlippage}
					enforceSlippage={enforceSlippage}
				/>
			</LightCard>

			{showAcceptChanges ? (
				<SwapShowAcceptChanges justify="flex-start" gap={"0px"}>
					<RowBetween>
						<RowFixed>
							<AlertTriangle
								size={20}
								style={{ marginRight: "8px", minWidth: 24 }}
							/>
							<TYPE.main color={theme.primary1}>
								<Trans>Price Updated</Trans>
							</TYPE.main>
						</RowFixed>
						<ButtonPrimary
							style={{
								padding: ".5rem",
								width: "fit-content",
								fontSize: "0.825rem",
								borderRadius: "12px",
							}}
							onClick={onAcceptChanges}
						>
							<Trans>Accept</Trans>
						</ButtonPrimary>
					</RowBetween>
				</SwapShowAcceptChanges>
			) : null}

			{(enforceSlippage || trade.tradeType !== TradeType.EXACT_INPUT) && (
				<AutoColumn
					justify="flex-start"
					gap="sm"
					style={{ padding: ".75rem 1rem" }}
				>
					{trade.tradeType === TradeType.EXACT_INPUT ? (
						<>
							{enforceSlippage && (
								<TYPE.italic
									fontWeight={400}
									textAlign="left"
									style={{ width: "100%" }}
								>
									<Trans>
										Output is estimated. You will receive at least{" "}
										<b>
											{parseFloat(
												formatUnits(
													roundSlippage(
														toBN(trade.outputAmount),
														toBN(trade.minimumAmountOut(allowedSlippage))
													),
													trade.outputAmount.currency.decimals
												)
											).toFixed(6)}{" "}
											{trade.outputAmount.currency.symbol}
										</b>{" "}
										or the transaction will revert.
									</Trans>
								</TYPE.italic>
							)}
						</>
					) : (
						<TYPE.italic
							fontWeight={400}
							textAlign="left"
							style={{ width: "100%" }}
						>
							<Trans>
								Input is estimated. You will sell at most{" "}
								<b>
									{trade.maximumAmountIn(allowedSlippage).toSignificant(6)}{" "}
									{trade.inputAmount.currency.symbol}
								</b>{" "}
								or the transaction will revert.
							</Trans>
						</TYPE.italic>
					)}
				</AutoColumn>
			)}

			{recipient !== null ? (
				<AutoColumn
					justify="flex-start"
					gap="sm"
					style={{ padding: "12px 0 0 0px" }}
				>
					<TYPE.main>
						<Trans>
							Output will be sent to{" "}
							<b title={recipient}>
								{isAddress(recipient) ? shortenAddress(recipient) : recipient}
							</b>
						</Trans>
					</TYPE.main>
				</AutoColumn>
			) : null}
		</AutoColumn>
	);
}
