import { useEffect, useState } from "react";

export type KVStore<Value> = {
	[key: string]: Value;
};

type CreateType<Key extends string, Value> = {
	[key in `${Key}`]: Value;
};

export const createObject = <Key extends string, Value>(
	key: Key,
	value: Value
): CreateType<Key, Value> => {
	const val: CreateType<Key, Value> = {} as any;
	(val as any)[key] = value;
	return val;
};

export const copyObject = <
	Value,
	Store extends KVStore<Value>,
	Key extends keyof Store
>(
	kv: Store,
	keys: Key[]
) => {
	const result: Store = {} as any;

	for (const key of keys) {
		result[key] = kv[key];
	}

	return result;
};

export const useKVStore = <Value>(name?: string) => {
	const [store, setStore] = useState<KVStore<Value>>({});

	useEffect(() => {
		if (!name) return;

		try {
			setStore(JSON.parse(localStorage.getItem(name) || ""));
		} catch (e) {}
	}, []);

	const local = (store: KVStore<Value>) => {
		if (name) {
			localStorage.setItem(name, JSON.stringify(store));
		}
	};

	return {
		store,
		set(key: string, value: Value) {
			setStore((curStore) => {
				const newStore = {
					...curStore,
					...createObject(key, value),
				};

				local(newStore);
				return newStore;
			});
		},
		delete(key: string) {
			setStore((curStore) => {
				const newStore = copyObject(
					curStore,
					Object.keys(curStore).filter((sKey) => sKey !== key)
				);

				local(newStore);
				return newStore;
			});
		},
		key(key: string) {
			return store[key] !== undefined;
		},
	};
};
