import { BigNumber } from "@ethersproject/bignumber";
import { Currency, CurrencyAmount, Token } from "@uniswap/sdk-core";

import {
	useMultipleContractSingleData,
	useSingleCallResult,
} from "../state/multicall/hooks";
import { useTokenContract } from "./useContract";
import ERC20_ABI from "abis/erc20.json";
import { utils } from "ethers";
import { useMemo } from "react";

const ERC20Interface = new utils.Interface(ERC20_ABI);

// returns undefined if input token is undefined, or fails to get token contract,
// or contract total supply cannot be fetched
export function useTotalSupply(
	token?: Currency
): CurrencyAmount<Token> | undefined {
	const contract = useTokenContract(
		token?.isToken ? token.address : undefined,
		false
	);

	const totalSupply: BigNumber = useSingleCallResult(contract, "totalSupply")
		?.result?.[0];

	return token?.isToken && totalSupply
		? CurrencyAmount.fromRawAmount(token, totalSupply.toString())
		: undefined;
}

export function useTotalSupplies(
	tokens: Currency[]
): (CurrencyAmount<Token> | undefined)[] {
	/**
   *     token?.isToken ? token.address : undefined,
    false
   */

	const addresses = useMemo(
		() => tokens.map((token) => token.wrapped.address),
		[tokens]
	);

	const totalSupplies = useMultipleContractSingleData(
		addresses,
		ERC20Interface,
		"totalSupply"
	);

	return useMemo(
		() =>
			tokens.map((token, i) => {
				const totalSupply: BigNumber | undefined =
					totalSupplies[i]?.result?.[0];

				return token?.isToken && totalSupply
					? CurrencyAmount.fromRawAmount(token, totalSupply.toString())
					: undefined;
			}),
		[tokens, totalSupplies]
	);
}
