import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";

const base = "https://graph.zipswap.fi/subgraphs/name/nonamefits";

export const client = new ApolloClient({
	link: new HttpLink({
		// uri: `${base}/zipswap`,
		uri: `https://api.thegraph.com/subgraphs/name/nonamefits/zipswap`,
	}),
	cache: new InMemoryCache(),
});

export const blockClient = new ApolloClient({
	link: new HttpLink({
		// uri: `${base}/blocks`,
		uri: `https://api.thegraph.com/subgraphs/name/ianlapham/uni-testing-subgraph`,
	}),
	cache: new InMemoryCache(),
});
