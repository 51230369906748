import { useEffect, useState } from "react";

const toBool = (val: string | undefined | null) => {
	if (val === undefined) return null;
	if (val === null) return null;
	return val === "true";
};

export const useStoredBoolean = (
	name: string,
	def?: boolean
): [boolean, (newVal: boolean) => void] => {
	const read = () => {
		return localStorage.getItem(name);
	};

	const [val, setVal] = useState(toBool(read()) ?? def ?? false);

	useEffect(() => {
		setVal(toBool(read()) ?? def ?? false);
	}, []);

	return [
		val,
		(newVal) => {
			setVal(newVal);
			localStorage.setItem(name, newVal ? "true" : "false");
		},
	];
};
