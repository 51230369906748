import { Trans } from "lib/trans";
import { Token } from "@uniswap/sdk-core";
import { Pair } from "@uniswap/v2-sdk";
import { L2_CHAIN_IDS } from "constants/chains";
import { useFactoryPairs } from "hooks/useFactoryPairs";
import JSBI from "jsbi";
import { useContext, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { Text } from "rebass";
import styled, { ThemeContext } from "styled-components/macro";

import { ButtonPrimary, ButtonSecondary } from "../../components/Button";
import Card from "../../components/Card";
import { AutoColumn } from "../../components/Column";
import { CardSection, DataCard } from "../../components/earn/styled";
import { SwapPoolTabs } from "../../components/NavigationTabs";
import FullPositionCard from "../../components/PositionCard";
import { RowBetween, RowFixed } from "../../components/Row";
import { Dots } from "../../components/swap/styleds";
import { SwitchLocaleLink } from "../../components/SwitchLocaleLink";
import { BIG_INT_ZERO } from "../../constants/misc";
import { useV2Pairs } from "../../hooks/useV2Pairs";
import { useActiveWeb3React } from "../../hooks/web3";
import { useStakingInfo } from "../../state/stake/hooks";
import { toV2LiquidityToken } from "../../state/user/hooks";
import { useTokenBalancesWithLoadingIndicator } from "../../state/wallet/hooks";
import { HideSmall, TYPE } from "../../theme";

const PageWrapper = styled(AutoColumn)`
	max-width: 640px;
	width: 100%;
`;

const VoteCard = styled(DataCard)`
	background: ${({ theme }) => theme.bg0};
	border: 2px solid ${({ theme }) => theme.bg2};
	overflow: hidden;
`;

const TitleRow = styled(RowBetween)`
	${({ theme }) => theme.mediaWidth.upToSmall`
    flex-wrap: wrap;
    gap: 12px;
    width: 100%;
    flex-direction: column-reverse;
  `};
`;

const ButtonRow = styled(RowFixed)`
	gap: 8px;
	${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    flex-direction: row-reverse;
    justify-content: space-between;
  `};
`;

const ResponsiveButtonPrimary = styled(ButtonPrimary)`
	width: fit-content;
	border-radius: 12px;
	${({ theme }) => theme.mediaWidth.upToSmall`
    width: 48%;
  `};
`;

const ResponsiveButtonSecondary = styled(ButtonSecondary)`
	width: fit-content;
	${({ theme }) => theme.mediaWidth.upToSmall`
    width: 48%;
  `};
`;

const EmptyProposals = styled.div`
	padding: 16px 12px;
	border-radius: 12px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

const Layer2Prompt = styled(EmptyProposals)`
	margin-top: 16px;
`;

export type PairInfo = {
	liquidityToken: Token;
	tokens: [Token, Token];
};

export default function Pool() {
	const theme = useContext(ThemeContext);
	const { account, chainId } = useActiveWeb3React();

	// const [importLiquidityOpen, setImportLiquidityOpen] = useState(false);

	// const [currencyA, setCurrencyA] = useState<Currency | null>(null);
	// const [currencyB, setCurrencyB] = useState<Currency | null>(null);

	const factoryPairs = useFactoryPairs();

	const tokenPairsWithLiquidityTokens = useMemo(() => {
		try {
			return factoryPairs
				.map((tokens) => tokens as [Token, Token])
				.map((tokens) => ({
					liquidityToken: toV2LiquidityToken(tokens),
					tokens,
				}))
				.reduce(
					(pv, cv) =>
						pv.findIndex(
							({ liquidityToken: { address } }) =>
								address === cv.liquidityToken.address
						) === -1
							? [...pv, cv]
							: pv,
					[] as PairInfo[]
				);
		} catch (e) {
			return [];
		}
	}, [factoryPairs]);

	const liquidityTokens = useMemo(
		() => tokenPairsWithLiquidityTokens.map((tpwlt) => tpwlt.liquidityToken),
		[tokenPairsWithLiquidityTokens]
	);

	const [v2PairsBalances, fetchingV2PairBalances] =
		useTokenBalancesWithLoadingIndicator(account ?? undefined, liquidityTokens);

	// fetch the reserves for all V2 pools in which the user has a balance
	const liquidityTokensWithBalances = useMemo(
		() =>
			tokenPairsWithLiquidityTokens.filter(({ liquidityToken }) =>
				v2PairsBalances[liquidityToken.address]?.greaterThan("0")
			),
		[tokenPairsWithLiquidityTokens, v2PairsBalances]
	);

	const v2Pairs = useV2Pairs(
		liquidityTokensWithBalances.map(({ tokens }) => tokens)
	);

	const v2IsLoading =
		fetchingV2PairBalances ||
		v2Pairs?.length < liquidityTokensWithBalances.length ||
		v2Pairs?.some((V2Pair) => !V2Pair);

	const allV2PairsWithLiquidity = v2Pairs
		.map(([, pair]) => pair)
		.filter((v2Pair): v2Pair is Pair => Boolean(v2Pair));

	// show liquidity even if its deposited in rewards contract
	const stakingInfo = useStakingInfo();

	const stakingInfosWithBalance = (stakingInfo || []).filter((pool) =>
		JSBI.greaterThan(pool.stakedAmount.quotient, BIG_INT_ZERO)
	);

	const stakingPairs = useV2Pairs(
		(stakingInfosWithBalance || []).map(
			({ tokens }) => tokens || [undefined, undefined]
		)
	);

	// remove any pairs that also are included in pairs with stake in mining pool
	const v2PairsWithoutStakedAmount = allV2PairsWithLiquidity.filter(
		(v2Pair) => {
			return (
				stakingPairs
					?.map((stakingPair) => stakingPair[1])
					.filter(
						(stakingPair) =>
							stakingPair?.liquidityToken.address ===
							v2Pair.liquidityToken.address
					).length === 0
			);
		}
	);

	const ON_L2 = chainId && L2_CHAIN_IDS.includes(chainId);

	return (
		<>
			{/* <ImportLiquidity
				importLiquidityOpen={importLiquidityOpen}
				setImportLiquidityOpen={setImportLiquidityOpen}
				setLiquidity={setLocalToken}
				state={[
					[currencyA, setCurrencyA],
					[currencyB, setCurrencyB],
				]}
				localTokens={localTokens}
			/> */}

			<PageWrapper>
				<SwapPoolTabs active={"pool"} />
				<VoteCard>
					{/* <CardBGImage /> */}
					<CardSection>
						<AutoColumn gap="md">
							<RowBetween>
								<Text fontWeight={600}>Liquidity provider rewards</Text>
							</RowBetween>
							<RowBetween>
								<Text fontSize={14}>
									Liquidity providers earn a 0.3% fee on all trades proportional
									to their share of the pool. Fees are added to the pool, accrue
									in real time and can be claimed by withdrawing your liquidity.
								</Text>
							</RowBetween>
							{/* <ExternalLink
								style={{ color: "white", textDecoration: "underline" }}
								target="_blank"
								href="https://zipswap.fi/docs/v2/core-concepts/pools/"
							>
								<TYPE.white fontSize={14}>
									<Trans>Read more about providing liquidity</Trans>
								</TYPE.white>
							</ExternalLink> */}
						</AutoColumn>
					</CardSection>
					{/* <CardBGImage /> */}
				</VoteCard>

				{ON_L2 ? (
					<AutoColumn gap="lg" justify="center">
						<AutoColumn gap="md" style={{ width: "100%" }}>
							<Layer2Prompt>
								<TYPE.body color={theme.text3} textAlign="center">
									<Trans>
										V2 is not available on Layer 2. Switch to Layer 1 Ethereum.
									</Trans>
								</TYPE.body>
							</Layer2Prompt>
						</AutoColumn>
					</AutoColumn>
				) : (
					<AutoColumn gap="lg" justify="center">
						<AutoColumn gap="md" style={{ width: "100%" }}>
							<TitleRow style={{ marginTop: "1rem" }} padding={"0"}>
								<HideSmall>
									<TYPE.mediumHeader
										style={{ marginTop: "0.5rem", justifySelf: "flex-start" }}
									>
										<Trans>Your liquidity</Trans>
									</TYPE.mediumHeader>
								</HideSmall>
								<ButtonRow>
									{/* <ResponsiveButtonSecondary
										padding="6px 8px"
										onClick={() => {
											setImportLiquidityOpen(true);
											setCurrencyA(null);
											setCurrencyB(null);
										}}
									>
										<Trans>Import Liquidity</Trans>
									</ResponsiveButtonSecondary> */}
									{/* <ResponsiveButtonSecondary
										as={Link}
										padding="6px 8px"
										to="/add/v2/ETH"
									>
										<Trans>Create a pair</Trans>
									</ResponsiveButtonSecondary> */}
									<ResponsiveButtonPrimary
										id="join-pool-button"
										as={Link}
										to="/add/v2/ETH"
										padding="6px 8px"
									>
										<Text fontWeight={500} fontSize={16}>
											<Trans>Add Liquidity</Trans>
										</Text>
									</ResponsiveButtonPrimary>
								</ButtonRow>
							</TitleRow>

							{!account ? (
								<Card padding="40px">
									<TYPE.body color={theme.text3} textAlign="center">
										<Trans>Connect to a wallet to view your liquidity.</Trans>
									</TYPE.body>
								</Card>
							) : v2IsLoading ? (
								<EmptyProposals>
									<TYPE.body color={theme.text3} textAlign="center">
										<Dots>
											<Trans>Loading</Trans>
										</Dots>
									</TYPE.body>
								</EmptyProposals>
							) : allV2PairsWithLiquidity?.length > 0 ||
							  stakingPairs?.length > 0 ? (
								<>
									{/* <ButtonSecondary>
										<RowBetween>
											<Trans>
												<ExternalLink
													href={"https://v2.info.zipswap.fi/account/" + account}
												>
													Account analytics and accrued fees
												</ExternalLink>
												<span> ↗ </span>
											</Trans>
										</RowBetween>
									</ButtonSecondary> */}
									{v2PairsWithoutStakedAmount.map((v2Pair) => (
										<FullPositionCard
											key={v2Pair.liquidityToken.address}
											pair={v2Pair}
										/>
									))}
									{stakingPairs.map(
										(stakingPair, i) =>
											stakingPair[1] && ( // skip pairs that arent loaded
												<FullPositionCard
													key={stakingInfosWithBalance[i].lp.address}
													pair={stakingPair[1]}
													stakedBalance={
														stakingInfosWithBalance[i].stakedAmount
													}
												/>
											)
									)}
									{/* <RowFixed justify="center" style={{ width: "100%" }}>
										<ButtonOutlined
											as={Link}
											to="/migrate/v2"
											id="import-pool-link"
											style={{
												padding: "8px 16px",
												margin: "0 4px",
												borderRadius: "12px",
												width: "fit-content",
												fontSize: "14px",
											}}
										>
											<ChevronsRight size={16} style={{ marginRight: "8px" }} />
											<Trans>Migrate Liquidity to V3</Trans>
										</ButtonOutlined>
									</RowFixed> */}
								</>
							) : (
								<EmptyProposals>
									<TYPE.body color={theme.text3} textAlign="center">
										<Trans>No liquidity found.</Trans>
									</TYPE.body>
								</EmptyProposals>
							)}
						</AutoColumn>
					</AutoColumn>
				)}
			</PageWrapper>
			<SwitchLocaleLink />
		</>
	);
}
