import { Trans } from "lib/trans";
import { CHAIN_INFO, SupportedChainId } from "constants/chains";
import { useActiveWeb3React } from "hooks/web3";
import styled from "styled-components/macro";
import { TYPE } from "theme";

import { BlueCard, LightCard } from "../../components/Card";
import { AutoColumn } from "../../components/Column";
import { ButtonLight, ButtonSecondary } from "components/Button";
import { useWalletModalToggle } from "state/application/hooks";
import { useState } from "react";
import { Flex, Text } from "rebass";

const info: [string, string][] = [
	["Network Name", "Optimistic Ethereum"],
	["RPC URL", "https://mainnet.optimism.io"],
	["Chain ID", "10"],
	["Currency Symbol", "ETH"],
	["Explorer URL", "https://optimistic.etherscan.io"],
];

const PageWrapper = styled(AutoColumn)`
	max-width: 480px;
	width: 100%;
`;

const StyledCard = styled(LightCard)`
	border: none;
	background: ${({ theme }) => theme.bg0};
	border: 2px solid ${({ theme }) => theme.bg2};
	position: relative;
	overflow: hidden;
	width: 100%;
`;

export const useRequireOptimism = (name: string) => {
	const toggleWalletModal = useWalletModalToggle();
	const { account, active, chainId } = useActiveWeb3React();

	const addOpti = async () => {
		const { label, nativeCurrency, rpcUrls, explorer } =
			CHAIN_INFO[SupportedChainId.OPTIMISM];

		await (window.ethereum as any).request({
			method: "wallet_addEthereumChain",
			params: [
				{
					chainId: `0x${SupportedChainId.OPTIMISM.toString(16)}`,
					chainName: label,
					nativeCurrency,
					rpcUrls,
					blockExplorerUrls: [explorer],
				},
			],
		});
	};

	if (!active || !account) {
		return (
			<PageWrapper gap="lg" justify="center">
				<StyledCard>
					<BlueCard>
						<AutoColumn gap="10px">
							<TYPE.link fontWeight={400} color={"primaryText1"}>
								To participate in the <b>{name}</b> you need to connect your
								wallet (on Optimism)!
							</TYPE.link>
						</AutoColumn>
					</BlueCard>

					{window.ethereum && (
						<BlueCard
							marginTop="16px"
							style={{
								display: "grid",
								gap: "0.5rem",
								gridTemplateColumns: "1fr",
							}}
						>
							<Text fontWeight={500} fontSize={20}>
								Network info
							</Text>

							{info.map(([name, content]) => (
								<Flex key={name} style={{ gap: "0.25rem" }}>
									<Text fontWeight={500} fontSize={16}>
										{name}:
									</Text>
									<Text fontWeight={400} fontSize={16}>
										{content}
									</Text>
								</Flex>
							))}
						</BlueCard>
					)}

					<ButtonLight marginTop="16px" onClick={() => addOpti()}>
						Switch to Optimism
					</ButtonLight>

					<ButtonLight marginTop="16px" onClick={toggleWalletModal}>
						<Trans>Connect Wallet</Trans>
					</ButtonLight>
				</StyledCard>
			</PageWrapper>
		);
	}

	if (chainId !== SupportedChainId.OPTIMISM) {
		return (
			<PageWrapper gap="lg" justify="center">
				<StyledCard>
					<BlueCard marginBottom="16px">
						<AutoColumn gap="10px">
							<TYPE.link fontWeight={400} color={"primaryText1"}>
								To participate in the <b>{name}</b> you need to switch to the{" "}
								<b>Optimistic Ethereum Mainnet</b> network!
							</TYPE.link>
						</AutoColumn>
					</BlueCard>

					{window.ethereum && (
						<ButtonLight onClick={() => addOpti()}>
							Switch to Optimism
						</ButtonLight>
					)}
				</StyledCard>
			</PageWrapper>
		);
	}

	return null;
};
