import { ButtonGray, ButtonPrimary } from "components/Button";
import useTheme from "hooks/useTheme";
import { Link } from "react-router-dom";
import { Text } from "rebass";
import styled, { css } from "styled-components";
import { ExternalLink } from "theme";
import { showILO, showWhitepaper } from "utils/env";

const Horizontal = styled.div<{ gap?: boolean }>`
	display: flex;
	width: 100%;
	justify-content: center;

	${({ gap }) =>
		gap &&
		css`
			gap: 1rem;
		`}
`;

const Vertical = styled.div`
	display: flex;
	height: 100%;
	width: 100%;
	flex-direction: column;
	justify-content: center;
	flex-grow: 1;
`;

const Title = styled(Text)`
	font-size: 35px;

	@media (min-width: 560px) {
		font-size: 45px;
	}

	@media (min-width: 800px) {
		font-size: 55px;
	}
`;

const External = styled(ExternalLink)`
	&:hover {
		text-decoration: none;
	}
`;

export default function Index() {
	const theme = useTheme();

	return (
		<Vertical>
			<Horizontal>
				<Title fontWeight={700} textAlign="center" maxWidth={600}>
					Lowest fee swaps on optimistic rollups.
				</Title>
			</Horizontal>

			<Horizontal>
				<Text
					fontSize={20}
					fontWeight={400}
					textAlign="center"
					marginTop="2rem"
					marginBottom="2rem"
					maxWidth={600}
					color={theme.text2}
				>
					Swap, earn, and build on the first optimistic rollup optimized
					decentralized crypto trading protocol.
				</Text>
			</Horizontal>

			<Horizontal gap>
				<ButtonPrimary as={Link} to="/swap" width="150px">
					Trade
				</ButtonPrimary>

				{showWhitepaper && (
					<ButtonGray as={Link} to="/whitepaper" width="150px">
						Whitepaper
					</ButtonGray>
				)}

				<ButtonGray as={External} href="https://info.zipswap.fi/" width="150px">
					Analytics ↗
				</ButtonGray>
			</Horizontal>
		</Vertical>
	);
}
