import { Trans } from "lib/trans";
import useScrollPosition from "@react-hook/window-scroll";
import { darken } from "polished";
import { useRef, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { Flex, Text } from "rebass";
import {
	useShowClaimPopup,
	useToggleSelfClaimModal,
} from "state/application/hooks";
import { useUserHasAvailableClaim } from "state/claim/hooks";
import { useUserHasSubmittedClaim } from "state/transactions/hooks";
import { useETHBalances } from "state/wallet/hooks";
import styled, { css } from "styled-components/macro";

import Logo from "../../assets/svg/logo.png";
import { useActiveWeb3React } from "../../hooks/web3";
import ClaimModal from "../claim/ClaimModal";
import Modal from "../Modal";
import Row from "../Row";
import { Dots } from "../swap/styleds";
import Web3Status from "../Web3Status";
import UniBalanceContent from "./UniBalanceContent";
import { HeaderNavDropdown } from "./HeaderNavDropdown";
import { ContractsModal } from "components/Menu/ContractsModal";
import { Menu, Moon, Sun, Volume2, VolumeX, X } from "react-feather";
import useTheme from "hooks/useTheme";
import { useDarkModeManager } from "state/user/hooks";
import { useOutsideAlerter } from "hooks/useOutsideAlerter";
import { TYPE } from "../../theme";
import { useSettings } from "settings";

const HEADER_MAX = "1399px";

const HeaderFrame = styled.div<{ showBackground: boolean; open?: boolean }>`
	position: relative;
	display: grid;
	grid-template-columns: 0px 1fr 0px;
	align-items: center;
	justify-content: space-between;
	align-items: center;
	flex-direction: row;
	width: 100%;
	top: 0;
	position: relative;
	padding: 1rem;
	z-index: 21;
	position: relative;
	/* Background slide effect on scroll. */
	border-bottom: 2px solid ${({ theme }) => theme.bg2};

	background: ${({ theme }) => theme.bg1}ED;
	backdrop-filter: blur(4px);

	@media (max-width: ${HEADER_MAX}) {
		grid-template-columns: auto auto;

		& :before {
			display: ${({ open }) => (open ? "block" : "none")};
			opacity: ${({ open }) => (open ? "1" : "0")};
			content: "";
			position: fixed;
			left: 0;
			top: 0;
			width: 100vw;
			height: 100vh;
			background: ${({ theme }) => theme.modalBG};
			transition-property: opacity;
		}
	}
`;

const Title = styled.a`
	display: flex;
	align-items: center;
	pointer-events: auto;
	justify-self: flex-start;
	margin-right: 12px;

	color: ${({ theme }) => theme.text1};
	font-weight: 600;
	font-size: 18px;
	text-decoration: none;

	:hover {
		cursor: pointer;

		& > div {
			transform: rotate(-5deg);
		}
	}

	@media (max-width: ${HEADER_MAX}) {
		& > span {
			display: none;
		}
	}
`;

const UniIcon = styled.div<{ sidebar?: boolean }>`
	transition: transform 0.3s ease;
	margin-right: 0.25rem;
	margin-bottom: -4px;

	@media (max-width: ${HEADER_MAX}) {
		${({ sidebar }) =>
			!sidebar &&
			css`
				margin-right: 0;
			`}
	}
`;

const BurgerIcon = styled(Menu)`
	margin-right: 0.5rem;
	cursor: pointer;
	display: none;

	@media (max-width: ${HEADER_MAX}) {
		display: block;
	}
`;

const BurgerFlex = styled(Flex)`
	display: none !important;

	@media (max-width: ${HEADER_MAX}) {
		display: flex !important;
		justify-content: space-between;
		width: 100%;
		padding: 0 1rem 0 0.5rem;
		box-sizing: border-box;
	}
`;

const HeaderControls = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-self: flex-end;
`;

const HeaderElement = styled.div`
	display: flex;
	align-items: center;

	&:not(:first-child) {
		margin-left: 0.5em;
	}

	/* addresses safari's lack of support for "gap" */
	& > *:not(:first-child) {
		margin-left: 8px;
	}
`;

const HeaderLinks = styled(Row)<{ open?: boolean }>`
	justify-self: center;
	width: fit-content;
	padding: 4px;
	border-radius: 16px;
	display: grid;
	grid-auto-flow: column;
	grid-gap: 10px;
	/* overflow: auto; */
	align-items: center;

	@media (max-width: ${HEADER_MAX}) {
		position: fixed;
		border-radius: 0px;

		transition-property: left;
		transition-duration: 175ms;
		transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

		padding: 0 1rem;
		padding-top: 2rem;

		top: 0;
		left: ${({ open }) => (open ? "0" : "-300")}px;
		height: 100vh;
		width: 300px;

		display: flex;
		flex-direction: column;
		gap: 1rem;
		align-items: start;

		background: ${({ theme }) => theme.bg0};
		z-index: 99999;
	}
`;

const AccountElement = styled.div<{ active: boolean }>`
	display: flex;
	flex-direction: row;
	align-items: center;

	overflow: hidden;

	& button {
		border-radius: 0;

		&:hover,
		&:active,
		&:focus {
			border-color: transparent;
		}
	}

	border: 2px solid ${({ theme }) => theme.bg2};

	border-radius: 12px;
	white-space: nowrap;
	width: 100%;

	:focus {
		border: 1px solid blue;
	}
`;

const UNIAmount = styled(AccountElement)`
	color: white;
	padding: 4px 8px;
	height: 36px;
	font-weight: 500;
	background-color: ${({ theme }) => theme.bg3};
	background: radial-gradient(
			174.47% 188.91% at 1.84% 0%,
			#ff007a 0%,
			#2172e5 100%
		),
		#edeef2;
`;

const UNIWrapper = styled.span`
	width: fit-content;
	position: relative;
	cursor: pointer;

	:hover {
		opacity: 0.8;
	}

	:active {
		opacity: 0.9;
	}
`;

const BalanceText = styled(Text)`
	${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `};
`;

const StyledNavLink = styled(NavLink)`
	${({ theme }) => theme.flexRowNoWrap}
	align-items: left;
	border-radius: 3rem;
	outline: none;
	cursor: pointer;
	text-decoration: none;
	color: ${({ theme }) => theme.text2};
	font-size: 1rem;
	font-weight: 500;
	padding: 8px 12px;
	word-break: break-word;
	overflow: hidden;
	white-space: nowrap;

	:hover,
	:focus {
		color: ${({ theme }) => darken(0.15, theme.text1)};
	}
`;

const EmptyA = styled.a``;

const StyledMenuButton = styled.button`
	border: none;
	background-color: transparent;
	margin: 0;
	padding: 0;

	width: 38px;
	height: 38px;

	background-color: ${({ theme }) => theme.bg1};

	padding: 0.5rem;
	border-radius: 12px;

	:hover,
	:focus {
		cursor: pointer;
		outline: none;
	}

	:hover {
		background-color: ${({ theme }) => darken(0.05, theme.bg1)};
	}

	svg {
		margin-top: 2px;
	}
`;

export default function Header() {
	const { account } = useActiveWeb3React();

	const userEthBalance = useETHBalances(account ? [account] : [])?.[
		account ?? ""
	];

	const [contractsOpen, setContractsOpen] = useState(false);

	const toggleClaimModal = useToggleSelfClaimModal();

	const availableClaim: boolean = useUserHasAvailableClaim(account);

	const { claimTxn } = useUserHasSubmittedClaim(account ?? undefined);

	const [showUniBalanceModal, setShowUniBalanceModal] = useState(false);
	const showClaimPopup = useShowClaimPopup();

	const [darkMode, toggleDarkMode] = useDarkModeManager();
	const { audio, setAudio } = useSettings(({ audio, setAudio }) => ({
		audio,
		setAudio,
	}));

	const scrollY = useScrollPosition();
	const location = useLocation();

	const theme = useTheme();

	const [sidebarOpen, setSidebarOpen] = useState(false);
	const sidebarRef = useRef<HTMLElement | null>(null);

	useOutsideAlerter(sidebarRef, () => {
		setSidebarOpen(false);
	});

	const close = () => setSidebarOpen(false);

	const ThemeIcon = darkMode ? Moon : Sun;
	const AudioIcon = audio ? Volume2 : VolumeX;

	return (
		<HeaderFrame showBackground={scrollY > 45 || true} open={sidebarOpen}>
			<ClaimModal />

			<Modal
				isOpen={showUniBalanceModal}
				onDismiss={() => setShowUniBalanceModal(false)}
			>
				<UniBalanceContent setShowUniBalanceModal={setShowUniBalanceModal} />
			</Modal>

			<ContractsModal state={[contractsOpen, setContractsOpen]} />

			<Flex>
				<div>
					<BurgerIcon
						size={24}
						strokeWidth={2}
						color={theme.text1}
						onClick={() => setSidebarOpen(true)}
					/>
				</div>

				<Title as={Link} to="/">
					<UniIcon>
						<img
							src={Logo}
							style={{
								width: "24px",
								height: "100%",
							}}
							alt="Logo"
						/>
					</UniIcon>
					<span>ZipSwap</span>
				</Title>
			</Flex>

			<HeaderLinks open={sidebarOpen} ref={sidebarRef}>
				<BurgerFlex>
					<Text as={Flex} fontWeight={600} fontSize={20} lineHeight="24px">
						<UniIcon sidebar>
							<img
								src={Logo}
								style={{
									width: "24px",
									height: "24px",
								}}
								alt="Logo"
							/>
						</UniIcon>
						<span>ZipSwap</span>
					</Text>

					<div>
						<X
							style={{
								margin: "2px 0",
							}}
							size={20}
							strokeWidth={2}
							color={theme.text1}
							onClick={() => setSidebarOpen(false)}
						/>
					</div>
				</BurgerFlex>

				<StyledNavLink id={`trade-nav-link`} to={"/swap"} onClick={close}>
					Trade
				</StyledNavLink>

				<StyledNavLink
					id={`opti-farm-nav-link`}
					to={"/farm/0x167dc49c498729223D1565dF3207771B4Ee19853"}
					onClick={close}
				>
					<img
						style={{
							width: 22,
							height: 22,
							marginRight: 10,
							marginBottom: -1,
							paddingTop: 1,
						}}
						src="https://static.optimism.io/logos/OP.png"
						alt=""
					/>

					<div
						style={{
							marginTop: 2,
						}}
					>
						ETH-OP Mining
					</div>
				</StyledNavLink>

				<HeaderNavDropdown
					name="Pool"
					items={[
						{
							name: "Browse",
							internal: "/pool",
						},
						{
							name: "Add",
							internal: "/add/ETH",
						},
					]}
					id={`pool-nav-link`}
					close={close}
				/>

				<HeaderNavDropdown
					name="Earn"
					items={[
						{
							name: "Farm",
							internal: "/farm",
						},
						{
							name: "ILO",
							internal: "/ilo",
						},
					]}
					close={close}
				/>

				<HeaderNavDropdown
					name="Links"
					items={[
						{
							name: "Whitepaper",
							internal: "/whitepaper",
						},
						{
							name: "Bridge",
							internal: "/bridge",
						},
						{
							name: "Contracts",
							click: () => setContractsOpen(true),
						},
						{
							name: "Discord",
							external: "https://discord.gg/FF55U7BjD4",
						},
						{
							name: "Twitter",
							external: "https://twitter.com/Zip_swap",
						},
					]}
					id={`tools-nav-link`}
					close={close}
				/>

				<StyledNavLink
					id={`trade-nav-link`}
					as={EmptyA}
					target="_blank"
					href="https://info.zipswap.fi/"
					onClick={() => close()}
				>
					Analytics ↗
				</StyledNavLink>
			</HeaderLinks>

			<HeaderControls>
				<HeaderElement>
					{availableClaim && !showClaimPopup && (
						<UNIWrapper onClick={toggleClaimModal}>
							<UNIAmount
								active={!!account && !availableClaim}
								style={{ pointerEvents: "auto" }}
							>
								<TYPE.white padding="0 2px">
									{claimTxn && !claimTxn?.receipt ? (
										<Dots>
											<Trans>Claiming ZIP</Trans>
										</Dots>
									) : (
										<Trans>Claim ZIP</Trans>
									)}
								</TYPE.white>
							</UNIAmount>
						</UNIWrapper>
					)}

					<AccountElement active={!!account}>
						{account && userEthBalance ? (
							<>
								<BalanceText
									style={{ flexShrink: 0, userSelect: "none" }}
									pl="0.75rem"
									pr="0.5rem"
									fontWeight={500}
								>
									<Trans>{userEthBalance?.toSignificant(3)} ETH</Trans>
								</BalanceText>
								<div style={{ width: "0.35rem", minWidth: "0.35rem" }} />
							</>
						) : null}
						<Web3Status />
					</AccountElement>
				</HeaderElement>

				<HeaderElement>
					<StyledMenuButton onClick={() => toggleDarkMode()}>
						<ThemeIcon size={16} color={theme.text1} strokeWidth={2} />
					</StyledMenuButton>
				</HeaderElement>

				<HeaderElement>
					<StyledMenuButton onClick={() => setAudio(!audio)}>
						<AudioIcon size={16} color={theme.text1} strokeWidth={2} />
					</StyledMenuButton>
				</HeaderElement>
			</HeaderControls>
		</HeaderFrame>
	);
}
