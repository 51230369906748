import { BigNumber } from "@ethersproject/bignumber";
import { ethers, providers, utils } from "ethers";
import { abi as erc20Abi } from "abis/swap/IERC20.json";
import { abi as factoryAbi } from "abis/swap/UniswapV2Factory.json";
import { abi as pairAbi } from "abis/swap/UniswapV2Pair.json";
import { V2_FACTORY_ADDRESSES } from "constants/addresses";
import { SupportedChainId } from "constants/chains";
import { NETWORK_URLS } from "connectors";
import { WETH9_EXTENDED } from "constants/tokens";
import { Web3Provider } from "@ethersproject/providers";

const has_to_flag = 0x80;
const has_deadline_flag = 0x40;
const has_slippage_flag = 0x20;
const max_amount_flag = 0x10;
export const zeroAddress = "0x0000000000000000000000000000000000000000";

const ZERO_BN = BigNumber.from(0);
const ONE_BN = BigNumber.from(1);
const TWO_BN = BigNumber.from(2);

function getNeededBytes(n: BigNumber) {
	let bytes = ONE_BN;

	while (n.gte(BigNumber.from(256).pow(bytes))) {
		bytes = bytes.add(1);
	}

	return bytes;
}

//var assert = typeof(assert) === undefined ? console.assert : assert;
function assert(cond: boolean) {
	if (!cond) {
		console.warn("assert failure");
	}
}

export function encode_amount(_amount: BigNumber) {
	let amount = _amount;

	if (amount.eq(0)) {
		return [ZERO_BN, ZERO_BN];
	}

	let zero_decimals_count = ZERO_BN;

	while (amount.mod(10).eq(0)) {
		zero_decimals_count = zero_decimals_count.add(1);
		amount = amount.div(10);
	}

	//basic case
	assert(zero_decimals_count.lt(32));

	let needed_bytes = getNeededBytes(amount);
	let first_case = false;
	let first_byte = ZERO_BN;

	if (
		needed_bytes.lt(8) ||
		(needed_bytes.eq(8) && zero_decimals_count.lt(16))
	) {
		first_byte = needed_bytes.sub(1).shl(5).or(zero_decimals_count);
		first_case = true;
	} else {
		amount = _amount;
		needed_bytes = getNeededBytes(amount);
		assert(needed_bytes.lte(16));
		first_byte = BigNumber.from(0xf0).or(needed_bytes.sub(1));
	}

	let r = [];
	while (!amount.eq(0)) {
		r.push(amount.and(0xff));
		amount = amount.shr(8);
	}

	r.reverse();
	r.unshift(first_byte);

	return r;
}

export function decode_amount(r: BigNumber[]) {
	let first_byte = r[0];
	let decimals = first_byte.and(0x1f);
	let amount_bytes = first_byte.and(0xe0).shr(5);
	let amount = ZERO_BN;

	if (amount_bytes.eq(7) && decimals.and(0x10).eq(0x10)) {
		amount_bytes = first_byte.and(0x0f);
		decimals = ZERO_BN;
	}

	for (let i = ZERO_BN; i.lt(amount_bytes.add(1)); i = i.add(1)) {
		amount = amount.shl(8);
		amount = amount.add(r[i.add(1).toNumber()]);
	}

	return amount.mul(BigNumber.from(10).pow(decimals));
}

export function encode_pool_id_with_token_id(
	pool_id: BigNumber,
	token_id: BigNumber
) {
	assert(token_id.eq(0) || token_id.eq(1));
	assert(pool_id.gte(0));

	let first_byte = token_id.shl(6);

	if (pool_id.lt(TWO_BN.pow(6))) {
		//fills fully into first byte
		first_byte = first_byte.or(pool_id);
	} else {
		first_byte = first_byte.or(0x80); //set flag for next byte
		first_byte = first_byte.or(pool_id.and(0x3f));
	}

	pool_id = pool_id.shr(6);

	let r = [first_byte];
	while (!pool_id.eq(0)) {
		let b = pool_id.and(0x7f);
		pool_id = pool_id.shr(7);

		if (!pool_id.eq(0)) {
			b = b.or(0x80); //flag that next byte exists
		}

		r.push(b);
	}

	return r;
}

export function decode_pool_id_with_token_id(b: BigNumber[]) {
	let x = b[0];
	let pool_id = x.and(0x3f);
	let token_id = b[0].and(0x40).shr(6);

	if (x.and(0x80).gt(0)) {
		let last_byte = b[1];
		pool_id = pool_id.add(last_byte.and(0x7f).shr(6));

		let i = ONE_BN;
		while (!last_byte.and(0x80).eq(0)) {
			last_byte = b[i.add(1).toNumber()];
			pool_id = pool_id.add(
				last_byte.add(0x7f).shl(i.mul(7).add(6).toNumber())
			);
			i = i.add(1);
		}
	}

	return [token_id, pool_id];
}

export function encode_pool_id_without_token_id(pool_id: BigNumber) {
	if (pool_id.eq(0)) {
		return [ZERO_BN];
	}

	let r = [];
	while (!pool_id.eq(0)) {
		r.push(pool_id.and(0x7f));
		pool_id = pool_id.shr(7);
	}

	r.reverse();

	for (let i = 0; i < r.length - 1; i++) {
		r[i] = r[i].or(0x80);
	}

	return r;
}

export function decode_pool_id_without_token_id(pool_id_bytes: BigNumber[]) {
	let x = pool_id_bytes[0];
	let pool_id = x.and(0x7f);
	let i = 1;

	while (!x.and(0x80).eq(0)) {
		pool_id = pool_id.shl(7);
		x = pool_id_bytes[i];
		pool_id = pool_id.add(x.and(0x7f));
		i++;
	}

	return pool_id;
}

export const addLiquidityETH = async (
	token: string,
	amountTokenDesired: BigNumber,
	amountTokenMin: BigNumber,
	amountEthMin: BigNumber,
	maxToken: boolean,
	provider: Web3Provider
) => {
	let functionSelector = 1;
	//if amountTokenMin and amountEthMin === 0 then no slippage flag
	//if amountTokenDesired === token balance then set max flag

	let calldata = [];

	let factoryContract = new ethers.Contract(
		V2_FACTORY_ADDRESSES[provider.network.chainId],
		factoryAbi,
		provider
	);

	//check if pool exists
	let [poolAddress, _poolType, poolId] = await factoryContract.getPool(
		token,
		WETH9_EXTENDED[provider.network.chainId].address
	);

	if (poolAddress !== zeroAddress) {
		functionSelector = 3;
		let encodedPoolId = encode_pool_id_without_token_id(poolId);
		calldata.push(...encodedPoolId);
	} else {
		//address to byte array
		let token_arg = Array.from(utils.arrayify(token));
		calldata.push(...token_arg);
	}

	if (maxToken) {
		//set max flag
		functionSelector |= max_amount_flag;
	} else {
		calldata.push(...encode_amount(amountTokenDesired));
	}

	if (!amountTokenMin.eq(0) || !amountEthMin.eq(0)) {
		functionSelector |= has_slippage_flag;
		calldata.push(...encode_amount(amountTokenMin));
		calldata.push(...encode_amount(amountEthMin));
	}

	calldata.unshift(functionSelector);

	let hexlifiedCalldata = utils.hexlify(calldata.map(Number));

	return hexlifiedCalldata;
};

//all token addresses must be in lower case
export const addLiquidityTokens = async (
	tokenA: string,
	tokenB: string,
	amountADesired: BigNumber,
	amountBDesired: BigNumber,
	amountAMin: BigNumber,
	amountBMin: BigNumber,
	maxTokenA: boolean,
	maxTokenB: boolean,
	provider: Web3Provider
) => {
	let functionSelector = 0;
	//if amountAMin and amountBMin === 0 then no slippage flag
	//if amountADesired === token balance then set max flag
	//if amountBDesired === balance in tokens then set 0 as amountBDesired

	let calldata = [];

	let factoryContract = new ethers.Contract(
		V2_FACTORY_ADDRESSES[provider.network.chainId],
		factoryAbi,
		provider
	);

	//check if pool exists
	let [poolAddress, _poolType, poolId] = await factoryContract.getPool(
		tokenA,
		tokenB
	);

	if (poolAddress !== zeroAddress) {
		functionSelector = 2;
		let encodedPoolId = encode_pool_id_without_token_id(poolId);
		calldata.push(...encodedPoolId);

		//check order of pool tokens and swap amounts if needed - order must be equal to pool's token order
		let poolContract = new ethers.Contract(poolAddress, pairAbi, provider);

		let poolToken0 = await poolContract.token0();

		if (poolToken0.toLowerCase() === tokenB) {
			[amountADesired, amountBDesired, amountAMin, amountBMin] = [
				amountBDesired,
				amountADesired,
				amountBMin,
				amountAMin,
			];
		}
	} else {
		//address to byte array
		let tokenA_arg = Array.from(ethers.utils.arrayify(tokenA));
		calldata.push(...tokenA_arg);
		let tokenB_arg = Array.from(ethers.utils.arrayify(tokenB));
		calldata.push(...tokenB_arg);
	}

	if (maxTokenA) {
		//set max flag
		functionSelector |= max_amount_flag;
	} else {
		calldata.push(...encode_amount(amountADesired));
	}

	//max amount for B is encoded as 0
	if (maxTokenB) {
		calldata.push(...encode_amount(ZERO_BN));
	} else {
		calldata.push(...encode_amount(amountBDesired));
	}

	if (!amountAMin.eq(0) || !amountBMin.eq(0)) {
		functionSelector |= has_slippage_flag;
		calldata.push(...encode_amount(amountAMin));
		calldata.push(...encode_amount(amountBMin));
	}

	calldata.unshift(functionSelector);

	let hexlifiedCalldata = ethers.utils.hexlify(calldata.map(Number));

	return hexlifiedCalldata;
};

export const removeLiquidity = async (
	tokenA: string,
	tokenB: string,
	liquidity: BigNumber,
	amountAMin: BigNumber,
	amountBMin: BigNumber,
	maxLiquidity: boolean,
	provider: Web3Provider
) => {
	let functionSelector = 4;
	//if amountAMin and amountBMin === 0 then no slippage flag
	//if liquidity === lp token balance then set max flag

	let calldata = [];

	let factoryContract = new ethers.Contract(
		V2_FACTORY_ADDRESSES[provider.network.chainId],
		factoryAbi,
		provider
	);

	//check if pool exists
	let [poolAddress, _poolType, poolId] = await factoryContract.getPool(
		tokenA,
		tokenB
	);

	if (poolAddress !== zeroAddress) {
		let encodedPoolId = encode_pool_id_without_token_id(poolId);
		calldata.push(...encodedPoolId);

		//check order of pool tokens and swap amounts if needed - order must be equal to pool's token order
		let poolContract = new ethers.Contract(poolAddress, pairAbi, provider);

		let poolToken0 = await poolContract.token0();

		if (poolToken0.toLowerCase() === tokenB) {
			[amountAMin, amountBMin] = [amountBMin, amountAMin];
		}

		if (maxLiquidity) {
			//set max flag
			functionSelector |= max_amount_flag;
		} else {
			calldata.push(...encode_amount(liquidity));
		}

		if (!amountAMin.eq(0) || !amountBMin.eq(0)) {
			functionSelector |= has_slippage_flag;
			calldata.push(...encode_amount(amountAMin));
			calldata.push(...encode_amount(amountBMin));
		}

		calldata.unshift(functionSelector);
		let hexlifiedCalldata = ethers.utils.hexlify(calldata.map(Number));

		return hexlifiedCalldata;
	}

	return "";
};

export const removeLiquidityEth = async (
	token: string,
	liquidity: BigNumber,
	amountTokenMin: BigNumber,
	amountEthMin: BigNumber,
	maxLiquidity: boolean,
	provider: Web3Provider
) => {
	let functionSelector = 5;
	let calldata = [];

	let factoryContract = new ethers.Contract(
		V2_FACTORY_ADDRESSES[provider.network.chainId],
		factoryAbi,
		provider
	);

	//check if pool exists
	let [poolAddress, _poolType, poolId] = await factoryContract.getPool(
		WETH9_EXTENDED[provider.network.chainId].address,
		token
	);

	if (poolAddress !== zeroAddress) {
		let encodedPoolId = encode_pool_id_without_token_id(poolId);
		calldata.push(...encodedPoolId);

		if (maxLiquidity) {
			functionSelector |= max_amount_flag;
		} else {
			calldata.push(...encode_amount(liquidity));
		}

		if (!amountTokenMin.eq(0) || !amountEthMin.eq(0)) {
			functionSelector |= has_slippage_flag;
			calldata.push(...encode_amount(amountTokenMin));
			calldata.push(...encode_amount(amountEthMin));
		}

		calldata.unshift(functionSelector);
		let hexlifiedCalldata = ethers.utils.hexlify(calldata.map(Number));

		return hexlifiedCalldata;
	}

	return "";
};

export const generatePath = async (
	tokens: string[],
	provider: Web3Provider,
	noIdIfWethFirst: boolean = false,
	noIdIfWethLastAndSingle: boolean = false,
	getPoolAround = async (token0 : string, token1 : string, standardFn : any) => await standardFn(token0, token1)) => {
	let [token0, token1] = [tokens[0], tokens[1]];

	const wethAddress = WETH9_EXTENDED[provider.network.chainId].address;

	let factoryContract = new ethers.Contract(
		V2_FACTORY_ADDRESSES[provider.network.chainId],
		factoryAbi,
		provider
	);

	//check if pool exists
	const standardFn = async (token0 : string, token1 : string) => await factoryContract.getPool(token0, token1);
	let [poolAddress, _poolType, poolId] = await getPoolAround(token0, token1, standardFn); //await factoryContract.getPool(token0, token1);

	let bigIntPoolId = poolId;

	if (poolAddress !== zeroAddress) {
		let path = [];

		//Eth -> token functions; eth is always first for the first pool, so no direction bit
		if (token0 === wethAddress && noIdIfWethFirst) {
			path.push(...encode_pool_id_without_token_id(bigIntPoolId));
		}
		//Token -> eth functions; path for two tokens, no direction bit needed
		else if (
			token1 === wethAddress &&
			noIdIfWethLastAndSingle &&
			tokens.length === 2
		) {
			path.push(...encode_pool_id_without_token_id(bigIntPoolId));
		}
		//all other cases - direction bit needed
		else {
			//check direction - tokenId is the index of the first token in the pair
			let poolContract = new ethers.Contract(poolAddress, pairAbi, provider);
			let poolToken0 = await poolContract.token0();
			let tokenId = poolToken0.toLowerCase() === token0.toLowerCase() ? 0 : 1;
			path.push(
				...encode_pool_id_with_token_id(bigIntPoolId, BigNumber.from(tokenId))
			);
		}

		let previous = token1;

		for (let token of tokens.splice(2)) {
			let [poolAddress, _poolType, poolId] = await getPoolAround(previous, token, standardFn);
			/*let [poolAddress, _poolType, poolId] = await factoryContract.getPool(
				previous,
				token
			);*/

			if (poolAddress === zeroAddress) {
				return BigNumber.from(0); //raise exception?
			}

			//direction bit isn't needed because first token is determined by the previous pair
			//(A -> B) -> (C,B); second swap must be B -> C
			path.push(...encode_pool_id_without_token_id(poolId));
			previous = token;
		}

		return path;
	}

	return BigNumber.from(0);
};

export const swapExactTokensForTokens = (
	amountIn: BigNumber,
	amountOutMin: BigNumber,
	maxAmountIn: boolean,
	path: BigNumber[]
) => {
	let functionSelector = 7;
	let calldata = [];

	if (maxAmountIn) {
		functionSelector |= max_amount_flag;
	} else {
		calldata.push(...encode_amount(amountIn));
	}

	if (!amountOutMin.eq(0)) {
		functionSelector |= has_slippage_flag;
		calldata.push(...encode_amount(amountOutMin));
	}

	calldata.push(...path);
	calldata.unshift(functionSelector);

	let hexlifiedCalldata = ethers.utils.hexlify(calldata.map(Number));
	return hexlifiedCalldata;
};

export const swapTokensForExactTokens = (
	amountOut: BigNumber,
	amountInMax: BigNumber,
	maxAmountIn: boolean,
	path: BigNumber[]
) => {
	let functionSelector = 8;
	let calldata = [];

	calldata.push(...encode_amount(amountOut));

	if (!maxAmountIn) {
		functionSelector |= has_slippage_flag;
		calldata.push(...encode_amount(amountInMax));
	}

	calldata.push(...path);
	calldata.unshift(functionSelector);

	let hexlifiedCalldata = ethers.utils.hexlify(calldata.map(Number));
	return hexlifiedCalldata;
};

//needs msg.value
export const swapExactETHForTokens = (
	amountOutMin: BigNumber,
	path: BigNumber[]
) => {
	let functionSelector = 9;
	let calldata = [];

	if (!amountOutMin.eq(0)) {
		functionSelector |= has_slippage_flag;
		calldata.push(...encode_amount(amountOutMin));
	}

	calldata.push(...path);
	calldata.unshift(functionSelector);

	let hexlifiedCalldata = ethers.utils.hexlify(calldata.map(Number));
	return hexlifiedCalldata;
};

export const swapTokensForExactETH = (
	amountOut: BigNumber,
	amountInMax: BigNumber,
	maxAmountIn: boolean,
	path: BigNumber[]
) => {
	let functionSelector = 10;
	let calldata = [];

	calldata.push(...encode_amount(amountOut));

	if (!maxAmountIn) {
		functionSelector |= has_slippage_flag;
		calldata.push(...encode_amount(amountInMax));
	}

	calldata.push(...path);
	calldata.unshift(functionSelector);

	let hexlifiedCalldata = ethers.utils.hexlify(calldata.map(Number));
	return hexlifiedCalldata;
};

export const swapExactTokensForETH = (
	amountIn: BigNumber,
	amountOutMin: BigNumber,
	maxAmountIn: boolean,
	path: BigNumber[]
) => {
	let functionSelector = 11;
	let calldata = [];

	if (maxAmountIn) {
		functionSelector |= max_amount_flag;
	} else {
		calldata.push(...encode_amount(amountIn));
	}

	if (!amountOutMin.eq(0)) {
		functionSelector |= has_slippage_flag;
		calldata.push(...encode_amount(amountOutMin));
	}

	calldata.push(...path);
	calldata.unshift(functionSelector);

	let hexlifiedCalldata = ethers.utils.hexlify(calldata.map(Number));
	return hexlifiedCalldata;
};

//needs msg.value
export const swapETHForExactTokens = (
	amountOut: BigNumber,
	path: BigNumber[]
) => {
	let functionSelector = 12;
	let calldata = [];

	calldata.push(functionSelector);
	calldata.push(...encode_amount(amountOut));
	calldata.push(...path);

	let hexlifiedCalldata = ethers.utils.hexlify(calldata.map(Number));
	return hexlifiedCalldata;
};

export const swapExactTokensForTokensSupportingFeeOnTransferTokens = (
	amountIn: BigNumber,
	amountOutMin: BigNumber,
	maxAmountIn: boolean,
	path: BigNumber[]
) => {
	let functionSelector = 13;
	let calldata = [];

	if (maxAmountIn) {
		functionSelector |= max_amount_flag;
	} else {
		calldata.push(...encode_amount(amountIn));
	}

	if (!amountOutMin.eq(0)) {
		functionSelector |= has_slippage_flag;
		calldata.push(...encode_amount(amountOutMin));
	}

	calldata.push(...path);
	calldata.unshift(functionSelector);

	let hexlifiedCalldata = ethers.utils.hexlify(calldata.map(Number));
	return hexlifiedCalldata;
};

//needs msg.value
export const swapExactETHForTokensSupportingFeeOnTransferTokens = (
	amountOutMin: BigNumber,
	path: BigNumber[]
) => {
	let functionSelector = 14;
	let calldata = [];

	if (!amountOutMin.eq(0)) {
		functionSelector |= has_slippage_flag;
		calldata.push(...encode_amount(amountOutMin));
	}

	calldata.push(...path);
	calldata.unshift(functionSelector);

	let hexlifiedCalldata = ethers.utils.hexlify(calldata.map(Number));
	return hexlifiedCalldata;
};

export const swapExactTokensForETHSupportingFeeOnTransferTokens = (
	amountIn: BigNumber,
	amountOutMin: BigNumber,
	maxAmountIn: boolean,
	path: BigNumber[]
) => {
	let functionSelector = 15;
	let calldata = [];

	if (maxAmountIn) {
		functionSelector |= max_amount_flag;
	} else {
		calldata.push(...encode_amount(amountIn));
	}

	if (!amountOutMin.eq(0)) {
		functionSelector |= has_slippage_flag;
		calldata.push(...encode_amount(amountOutMin));
	}

	calldata.push(...path);
	calldata.unshift(functionSelector);

	let hexlifiedCalldata = ethers.utils.hexlify(calldata.map(Number));
	return hexlifiedCalldata;
};

/*amountOutMin is rounded with a 4% deviation to increase number of zeros at the end
 */
export const roundSlippage = (
	amountOut: BigNumber,
	amountOutMin: BigNumber
) => {
	const abs = (n: BigNumber) => (n.lt(0) ? n.mul(-1) : n);

	const slippageDiff = amountOut.sub(amountOutMin);

	/*maximum error is 1/100n * 8 / 2
	8 because 1 in decimalFilter can remove 9
	/2 because closer number from up and down rounding is taken
	*/
	const decimalFilter = slippageDiff.div(100);
	const decimalFilterLength = decimalFilter.toString().length;

	const roundUpFiltered = BigNumber.from(
		amountOutMin.add(decimalFilter).toString().slice(0, -decimalFilterLength) +
			"0".repeat(decimalFilterLength)
	);

	const roundDownFiltered = BigNumber.from(
		amountOutMin.toString().slice(0, -decimalFilterLength) +
			"0".repeat(decimalFilterLength)
	);

	if (
		abs(amountOutMin.sub(roundUpFiltered)) <
		abs(amountOutMin.sub(roundDownFiltered))
	) {
		return roundUpFiltered;
	} else {
		return roundDownFiltered;
	}
};
