import { darken } from "polished";
import { ReactNode, useEffect, useState } from "react";
import { ChevronDown } from "react-feather";
import { NavLink } from "react-router-dom";
import styled, { css } from "styled-components";

const HEADER_MAX = "1099px";

const NavContainer = styled.div<{ open: boolean }>`
	position: relative;

	${({ open }) =>
		open &&
		css`
			margin-bottom: -0.5rem;
			padding-bottom: 0.5rem;
		`}
`;

const NavText = styled.a`
	${({ theme }) => theme.flexRowNoWrap}

	align-items: left;
	border-radius: 3rem;
	outline: none;
	cursor: pointer;
	text-decoration: none;
	color: ${({ theme }) => theme.text2};
	font-size: 1rem;
	font-weight: 500;
	padding: 8px 12px;
	word-break: break-word;
	overflow: hidden;
	white-space: nowrap;

	& * {
		color: ${({ theme }) => theme.text2};
	}

	:hover,
	:focus {
		color: ${({ theme }) => theme.text1};

		& * {
			color: ${({ theme }) => theme.text1};
		}
	}
`;

const Chevron = styled(ChevronDown)`
	margin-left: 0.5rem;
	height: 18px;
	width: 18px;
	margin-top: 1px;
`;

const NavContent = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	gap: 0.5rem;

	background: ${({ theme }) => theme.bg0};

	border-radius: 0.75rem;
	border: 1px solid ${({ theme }) => theme.bg2};
	padding: 0.375rem;
	box-sizing: border-box;
	position: absolute;
	left: -50%;
	top: 2.5rem;
	z-index: 22;
	min-width: 175px;

	@media (max-width: ${HEADER_MAX}) {
		left: 0;
	}
`;

const NavButton = styled.a`
	border: none;
	border-radius: 0.5rem;
	background: transparent;
	color: ${({ theme }) => theme.text2};
	text-decoration: none;
	padding: 0.5rem 0.675rem;
	font-weight: 500;
	cursor: pointer;

	&:focus,
	&:active,
	&:hover {
		outline: none;
	}

	&:hover {
		background: ${({ theme }) => darken(0.05, theme.bg1)};
		color: ${({ theme }) => theme.text1};
	}
`;

export const HeaderNavDropdown = ({
	name,
	items,
	id,
	close,
}: {
	name: ReactNode;
	items: {
		name: string;
		external?: string;
		internal?: string;
		click?: () => any;
	}[];
	id?: string;
	close?: () => any;
}) => {
	const [open, setOpen] = useState(false);

	const enter = () => setOpen(true);
	const leave = () => setOpen(false);

	return (
		<NavContainer id={id} onMouseEnter={enter} onMouseLeave={leave} open={open}>
			{open && (
				<NavContent>
					{items.map(({ name, external, internal, click }) => (
						<NavButton
							key={name}
							as={external ? undefined : internal ? NavLink : undefined}
							to={internal ?? "#"}
							href={external ?? undefined}
							target={external ? "_blank" : undefined}
							onClick={() => {
								leave();
								setOpen(false);
								click && click();
								close && close();
							}}
						>
							{name}
							{external ? " ↗" : ""}
						</NavButton>
					))}
				</NavContent>
			)}

			<NavText>
				{name} <Chevron />
			</NavText>
		</NavContainer>
	);
};
