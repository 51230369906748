import { Web3Provider } from "@ethersproject/providers";
import { SafeAppConnector } from "@gnosis.pm/safe-apps-web3-react";
import { InjectedConnector } from "@web3-react/injected-connector";
import { PortisConnector } from "@web3-react/portis-connector";
import { WalletConnectConnector } from "./walletconnect";
import { WalletLinkConnector } from "@web3-react/walletlink-connector";

import ZIPSWAP_LOGO_URL from "../assets/svg/logo.svg";
import { ALL_SUPPORTED_CHAIN_IDS, SupportedChainId } from "../constants/chains";
import getLibrary from "../utils/getLibrary";
import { FortmaticConnector } from "./Fortmatic";
import { NetworkConnector } from "./NetworkConnector";

const INFURA_KEY = "";
const FORMATIC_KEY = process.env.REACT_APP_FORTMATIC_KEY;
const PORTIS_ID = process.env.REACT_APP_PORTIS_ID;

export const NETWORK_URLS: { [key in SupportedChainId]: string } = {
	[SupportedChainId.OPTIMISM]: process.env.REACT_APP_OPT_RPC ?? "",
	[SupportedChainId.ARBITRUM_ONE]: process.env.REACT_APP_ARB_RPC ?? "",

	[SupportedChainId.MAINNET]: `https://mainnet.infura.io/v3/${INFURA_KEY}`,
	[SupportedChainId.RINKEBY]: `https://rinkeby.infura.io/v3/${INFURA_KEY}`,
	[SupportedChainId.ROPSTEN]: `https://ropsten.infura.io/v3/${INFURA_KEY}`,
	[SupportedChainId.GOERLI]: `https://goerli.infura.io/v3/${INFURA_KEY}`,
	[SupportedChainId.KOVAN]: `https://kovan.infura.io/v3/${INFURA_KEY}`,
	[SupportedChainId.OPTIMISTIC_KOVAN]: `https://optimism-kovan.infura.io/v3/${INFURA_KEY}`,
	[SupportedChainId.ARBITRUM_RINKEBY]: `https://arbitrum-rinkeby.infura.io/v3/${INFURA_KEY}`,
};

export const network = new NetworkConnector({
	urls: NETWORK_URLS,
	defaultChainId: SupportedChainId.OPTIMISM,
});

let networkLibrary: Web3Provider | undefined;
export function getNetworkLibrary(): Web3Provider {
	return (networkLibrary = networkLibrary ?? getLibrary(network.provider));
}

export const injected = new InjectedConnector({
	supportedChainIds: ALL_SUPPORTED_CHAIN_IDS,
});

export const gnosisSafe = new SafeAppConnector();

export const walletconnect = new WalletConnectConnector({
	supportedChainIds: [SupportedChainId.OPTIMISM],
	rpc: NETWORK_URLS,
	qrcode: true,
});

// mainnet only
export const fortmatic = new FortmaticConnector({
	apiKey: FORMATIC_KEY ?? "",
	chainId: 1,
});

// mainnet only
export const portis = new PortisConnector({
	dAppId: PORTIS_ID ?? "",
	networks: [1],
});

// mainnet only
export const walletlink = new WalletLinkConnector({
	url: NETWORK_URLS[SupportedChainId.MAINNET],
	appName: "ZipSwap",
	appLogoUrl: ZIPSWAP_LOGO_URL,
});
