import { BigNumber } from "@ethersproject/bignumber";
import { TransactionResponse } from "@ethersproject/providers";
import { Trans } from "lib/trans";
import { Currency, CurrencyAmount, Percent, Token } from "@uniswap/sdk-core";
import UnsupportedCurrencyFooter from "components/swap/UnsupportedCurrencyFooter";
import { SwitchLocaleLink } from "components/SwitchLocaleLink";
import Toggle from "components/Toggle";
import { getKey } from "pages/Pool/ImportLiquidity";
import { useCallback, useContext, useState } from "react";
import { Plus } from "react-feather";
import { RouteComponentProps } from "react-router-dom";
import { Text } from "rebass";
import { ThemeContext } from "styled-components/macro";
import { extraGas } from "utils/gas";
import { useKVStore } from "utils/kvstore";

import {
	ButtonError,
	ButtonLight,
	ButtonPrimary,
} from "../../components/Button";
import { BlueCard, LightCard } from "../../components/Card";
import { AutoColumn, ColumnCenter } from "../../components/Column";
import CurrencyInputPanel from "../../components/CurrencyInputPanel";
import DoubleCurrencyLogo from "../../components/DoubleLogo";
import { AddRemoveTabs } from "../../components/NavigationTabs";
import { MinimalPositionCard } from "../../components/PositionCard";
import Row, { RowBetween, RowFlat } from "../../components/Row";
import TransactionConfirmationModal, {
	ConfirmationModalContent,
} from "../../components/TransactionConfirmationModal";
import { ZERO_PERCENT } from "../../constants/misc";
import { WETH9_EXTENDED } from "../../constants/tokens";
import { useCurrency } from "../../hooks/Tokens";
import {
	ApprovalState,
	useApproveCallback,
} from "../../hooks/useApproveCallback";
import { useV2RouterContract } from "../../hooks/useContract";
import { useIsSwapUnsupported } from "../../hooks/useIsSwapUnsupported";
import useTransactionDeadline from "../../hooks/useTransactionDeadline";
import { PairState } from "../../hooks/useV2Pairs";
import { useActiveWeb3React } from "../../hooks/web3";
import { useWalletModalToggle } from "../../state/application/hooks";
import { Field } from "../../state/mint/actions";
import {
	useDerivedMintInfo,
	useMintActionHandlers,
	useMintState,
} from "../../state/mint/hooks";
import { TransactionType } from "../../state/transactions/actions";
import { useTransactionAdder } from "../../state/transactions/hooks";
import {
	useIsExpertMode,
	useUserSlippageToleranceWithDefault,
} from "../../state/user/hooks";
import { TYPE } from "../../theme";
import { calculateGasMargin } from "../../utils/calculateGasMargin";
import { calculateSlippageAmount } from "../../utils/calculateSlippageAmount";
import { currencyId } from "../../utils/currencyId";
import { maxAmountSpend } from "../../utils/maxAmountSpend";
import AppBody from "../AppBody";
import { Dots, Wrapper } from "../Pool/styleds";
import { ConfirmAddModalBottom } from "./ConfirmAddModalBottom";
import { PoolPriceBar } from "./PoolPriceBar";
import { addLiquidityETH, addLiquidityTokens } from "./tools";
import { utils } from "ethers";

const DEFAULT_ADD_V2_SLIPPAGE_TOLERANCE = new Percent(50, 10_000);

export default function AddLiquidity({
	match: {
		params: { currencyIdA, currencyIdB },
	},
	history,
}: RouteComponentProps<{ currencyIdA?: string; currencyIdB?: string }>) {
	const { account, chainId, library } = useActiveWeb3React();
	const theme = useContext(ThemeContext);

	const [enforceSlippage, setEnforceSlippage] = useState(false);

	const currencyA = useCurrency(currencyIdA);
	const currencyB = useCurrency(currencyIdB);

	const oneCurrencyIsWETH = Boolean(
		chainId &&
			((currencyA && currencyA.equals(WETH9_EXTENDED[chainId])) ||
				(currencyB && currencyB.equals(WETH9_EXTENDED[chainId])))
	);

	const toggleWalletModal = useWalletModalToggle(); // toggle wallet when disconnected

	const expertMode = useIsExpertMode();

	// mint state
	const { independentField, typedValue, otherTypedValue } = useMintState();
	const {
		dependentField,
		currencies,
		pair,
		pairState,
		currencyBalances,
		parsedAmounts,
		price,
		noLiquidity,
		liquidityMinted,
		poolTokenPercentage,
		error,
	} = useDerivedMintInfo(currencyA ?? undefined, currencyB ?? undefined);

	const { set: setLocalToken } = useKVStore<[Token, Token]>(
		"localLiquidityTokens"
	);

	const { onFieldAInput, onFieldBInput } = useMintActionHandlers(noLiquidity);

	const isValid = !error;

	// modal and loading
	const [showConfirm, setShowConfirm] = useState<boolean>(false);
	const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false); // clicked confirm

	// txn values
	const deadline = useTransactionDeadline(); // custom from users settings
	const allowedSlippage = useUserSlippageToleranceWithDefault(
		DEFAULT_ADD_V2_SLIPPAGE_TOLERANCE
	); // custom from users
	const [txHash, setTxHash] = useState<string>("");

	// get formatted amounts
	const formattedAmounts = {
		[independentField]: typedValue,
		[dependentField]: noLiquidity
			? otherTypedValue
			: parsedAmounts[dependentField]?.toSignificant(6) ?? "",
	};

	// get the max amounts user can add
	const maxAmounts: { [field in Field]?: CurrencyAmount<Currency> } = [
		Field.CURRENCY_A,
		Field.CURRENCY_B,
	].reduce((accumulator, field) => {
		return {
			...accumulator,
			[field]: maxAmountSpend(currencyBalances[field]),
		};
	}, {});

	const atMaxAmounts: { [field in Field]?: CurrencyAmount<Currency> } = [
		Field.CURRENCY_A,
		Field.CURRENCY_B,
	].reduce((accumulator, field) => {
		return {
			...accumulator,
			[field]: maxAmounts[field]?.equalTo(parsedAmounts[field] ?? "0"),
		};
	}, {});

	const router = useV2RouterContract();

	// check whether the user has approved the router on the tokens
	const [approvalA, approveACallback] = useApproveCallback(
		parsedAmounts[Field.CURRENCY_A],
		router?.address
	);
	const [approvalB, approveBCallback] = useApproveCallback(
		parsedAmounts[Field.CURRENCY_B],
		router?.address
	);

	const addTransaction = useTransactionAdder();

	async function onAdd() {
		if (!chainId || !library || !account || !router) return;

		const {
			[Field.CURRENCY_A]: parsedAmountA,
			[Field.CURRENCY_B]: parsedAmountB,
		} = parsedAmounts;
		if (
			!parsedAmountA ||
			!parsedAmountB ||
			!currencyA ||
			!currencyB ||
			!deadline
		) {
			return;
		}

		const amountsMin = {
			[Field.CURRENCY_A]: calculateSlippageAmount(
				parsedAmountA,
				allowedSlippage
			)[0],
			[Field.CURRENCY_B]: calculateSlippageAmount(
				parsedAmountB,
				allowedSlippage
			)[0],
		};

		let estimate;
		let method: (...args: any) => Promise<TransactionResponse>;
		let args: string;
		let value: BigNumber | null;

		if (currencyA.isNative || currencyB.isNative) {
			const tokenBIsETH = currencyB.isNative;

			args = await addLiquidityETH(
				(tokenBIsETH ? currencyA : currencyB)?.wrapped?.address ?? "",
				BigNumber.from(
					(tokenBIsETH ? parsedAmountA : parsedAmountB).quotient.toString()
				),
				BigNumber.from(
					enforceSlippage
						? amountsMin[
								tokenBIsETH ? Field.CURRENCY_A : Field.CURRENCY_B
						  ].toString()
						: 0
				),
				BigNumber.from(
					enforceSlippage
						? amountsMin[
								tokenBIsETH ? Field.CURRENCY_B : Field.CURRENCY_A
						  ].toString()
						: 0
				),
				maxAmounts[tokenBIsETH ? Field.CURRENCY_A : Field.CURRENCY_B]?.equalTo(
					parsedAmounts[tokenBIsETH ? Field.CURRENCY_A : Field.CURRENCY_B] ??
						"0"
				) || false,
				library
			);

			// account,
			// deadline.toHexString(),

			value = BigNumber.from(
				(tokenBIsETH ? parsedAmountB : parsedAmountA).quotient.toString()
			);
		} else {
			args = await addLiquidityTokens(
				currencyA?.wrapped?.address ?? "",
				currencyB?.wrapped?.address ?? "",
				BigNumber.from(parsedAmountA.quotient.toString()),
				BigNumber.from(parsedAmountB.quotient.toString()),
				BigNumber.from(
					enforceSlippage ? amountsMin[Field.CURRENCY_A].toString() : 0
				),
				BigNumber.from(
					enforceSlippage ? amountsMin[Field.CURRENCY_B].toString() : 0
				),
				maxAmounts[Field.CURRENCY_A]?.equalTo(
					parsedAmounts[Field.CURRENCY_A] ?? "0"
				) || false,
				maxAmounts[Field.CURRENCY_B]?.equalTo(
					parsedAmounts[Field.CURRENCY_B] ?? "0"
				) || false,
				library
			);
			value = null;
		}

		setAttemptingTxn(true);

		library
			.estimateGas({
				from: account,
				to: router.address,
				data: args,
				...(value ? { value } : {}),
			})
			.then((gas) => {
				router
					.fallback({
						data: args,
						...(value ? { value } : {}),
						gasLimit: extraGas(gas),
					})
					.then((response) => {
						setAttemptingTxn(false);

						addTransaction(response, {
							type: TransactionType.ADD_LIQUIDITY_V2_POOL,
							baseCurrencyId: currencyId(currencyA),
							expectedAmountBaseRaw:
								parsedAmounts[Field.CURRENCY_A]?.quotient.toString() ?? "0",
							quoteCurrencyId: currencyId(currencyB),
							expectedAmountQuoteRaw:
								parsedAmounts[Field.CURRENCY_B]?.quotient.toString() ?? "0",
						});

						setTxHash(response.hash);

						setLocalToken(getKey(currencyA, currencyB), [
							currencyA.wrapped,
							currencyB.wrapped,
						]);
					})
					.catch((e) => {
						setAttemptingTxn(false);
						// we only care if the error is something _other_ than the user rejected the tx
						if (e?.code !== 4001) {
							console.error(error);
						}
					});
			})
			.catch((e) => {
				setAttemptingTxn(false);
				// we only care if the error is something _other_ than the user rejected the tx
				if (e?.code !== 4001) {
					console.error(error);
				}
			});

		/*
		await estimate(args, value ? { value } : {})
			.then((estimatedGasLimit) =>

			)
			.catch((error) => {
				setAttemptingTxn(false);
				// we only care if the error is something _other_ than the user rejected the tx
				if (error?.code !== 4001) {
					console.error(error);
				}
			});*/
	}

	const modalHeader = () => {
		return noLiquidity ? (
			<AutoColumn gap="20px">
				<LightCard mt="20px" $borderRadius="20px">
					<RowFlat>
						<Text
							fontSize="32px"
							fontWeight={500}
							lineHeight="30px"
							marginRight={22}
						>
							{currencies[Field.CURRENCY_A]?.symbol +
								"/" +
								currencies[Field.CURRENCY_B]?.symbol}
						</Text>
						<DoubleCurrencyLogo
							currency0={currencies[Field.CURRENCY_A]}
							currency1={currencies[Field.CURRENCY_B]}
							size={30}
						/>
					</RowFlat>
				</LightCard>
			</AutoColumn>
		) : (
			<AutoColumn gap="20px">
				<RowFlat style={{ marginTop: "20px" }}>
					<Text
						fontSize="32px"
						fontWeight={500}
						lineHeight="30px"
						marginRight={22}
					>
						{liquidityMinted?.toSignificant(6)}
					</Text>
					<DoubleCurrencyLogo
						currency0={currencies[Field.CURRENCY_A]}
						currency1={currencies[Field.CURRENCY_B]}
						size={30}
					/>
				</RowFlat>
				<Row>
					<Text fontSize="24px">
						{currencies[Field.CURRENCY_A]?.symbol +
							"/" +
							currencies[Field.CURRENCY_B]?.symbol +
							" Pool Tokens"}
					</Text>
				</Row>
				{enforceSlippage && (
					<TYPE.italic fontSize={12} textAlign="left" padding={"8px 0 0 0 "}>
						<Trans>
							Output is estimated. If the price changes by more than{" "}
							{allowedSlippage.toSignificant(4)}% your transaction will revert.
						</Trans>
					</TYPE.italic>
				)}
			</AutoColumn>
		);
	};

	const modalBottom = () => {
		return (
			<ConfirmAddModalBottom
				price={price}
				currencies={currencies}
				parsedAmounts={parsedAmounts}
				noLiquidity={noLiquidity}
				onAdd={onAdd}
				poolTokenPercentage={poolTokenPercentage}
			/>
		);
	};

	const pendingText = (
		<Trans>
			Supplying {parsedAmounts[Field.CURRENCY_A]?.toSignificant(6)}{" "}
			{currencies[Field.CURRENCY_A]?.symbol} and{" "}
			{parsedAmounts[Field.CURRENCY_B]?.toSignificant(6)}{" "}
			{currencies[Field.CURRENCY_B]?.symbol}
		</Trans>
	);

	const handleCurrencyASelect = useCallback(
		(currencyA: Currency) => {
			const newCurrencyIdA = currencyId(currencyA);
			if (newCurrencyIdA === currencyIdB) {
				history.push(`/add/v2/${currencyIdB}/${currencyIdA}`);
			} else {
				history.push(`/add/v2/${newCurrencyIdA}/${currencyIdB}`);
			}
		},
		[currencyIdB, history, currencyIdA]
	);

	const handleCurrencyBSelect = useCallback(
		(currencyB: Currency) => {
			const newCurrencyIdB = currencyId(currencyB);
			if (currencyIdA === newCurrencyIdB) {
				if (currencyIdB) {
					history.push(`/add/v2/${currencyIdB}/${newCurrencyIdB}`);
				} else {
					history.push(`/add/v2/${newCurrencyIdB}`);
				}
			} else {
				history.push(
					`/add/v2/${currencyIdA ? currencyIdA : "ETH"}/${newCurrencyIdB}`
				);
			}
		},
		[currencyIdA, history, currencyIdB]
	);

	const handleDismissConfirmation = useCallback(() => {
		setShowConfirm(false);
		// if there was a tx hash, we want to clear the input
		if (txHash) {
			onFieldAInput("");
		}
		setTxHash("");
	}, [onFieldAInput, txHash]);

	const isCreate = history.location.pathname.includes("/create");

	const addIsUnsupported = useIsSwapUnsupported(
		currencies?.CURRENCY_A,
		currencies?.CURRENCY_B
	);

	return (
		<>
			<AppBody>
				<AddRemoveTabs
					creating={isCreate}
					adding={true}
					defaultSlippage={DEFAULT_ADD_V2_SLIPPAGE_TOLERANCE}
				/>
				<Wrapper>
					<TransactionConfirmationModal
						isOpen={showConfirm}
						onDismiss={handleDismissConfirmation}
						attemptingTxn={attemptingTxn}
						hash={txHash}
						content={() => (
							<ConfirmationModalContent
								title={
									noLiquidity ? (
										<Trans>You are creating a pool</Trans>
									) : (
										<Trans>You will receive</Trans>
									)
								}
								onDismiss={handleDismissConfirmation}
								topContent={modalHeader}
								bottomContent={modalBottom}
							/>
						)}
						pendingText={pendingText}
						currencyToAdd={pair?.liquidityToken}
					/>
					<AutoColumn gap="20px">
						{noLiquidity ||
							(isCreate ? (
								<ColumnCenter>
									<BlueCard>
										<AutoColumn gap="10px">
											<TYPE.link fontWeight={600} color={"primaryText1"}>
												<Trans>You are the first liquidity provider.</Trans>
											</TYPE.link>
											<TYPE.link fontWeight={400} color={"primaryText1"}>
												<Trans>
													The ratio of tokens you add will set the price of this
													pool.
												</Trans>
											</TYPE.link>
											<TYPE.link fontWeight={400} color={"primaryText1"}>
												<Trans>
													Once you are happy with the rate click supply to
													review.
												</Trans>
											</TYPE.link>
										</AutoColumn>
									</BlueCard>
								</ColumnCenter>
							) : (
								<ColumnCenter>
									<BlueCard>
										<AutoColumn gap="10px">
											<TYPE.link fontWeight={400} color={"primaryText1"}>
												<Trans>
													<b>
														<Trans>Tip:</Trans>
													</b>{" "}
													When you add liquidity, you will receive pool tokens
													representing your position. These tokens automatically
													earn fees proportional to your share of the pool, and
													can be redeemed at any time.
												</Trans>
											</TYPE.link>
										</AutoColumn>
									</BlueCard>
								</ColumnCenter>
							))}
						<CurrencyInputPanel
							value={formattedAmounts[Field.CURRENCY_A]}
							onUserInput={onFieldAInput}
							onMax={() => {
								onFieldAInput(maxAmounts[Field.CURRENCY_A]?.toExact() ?? "");
							}}
							onCurrencySelect={handleCurrencyASelect}
							showMaxButton={!atMaxAmounts[Field.CURRENCY_A]}
							currency={currencies[Field.CURRENCY_A] ?? null}
							id="add-liquidity-input-tokena"
							showCommonBases
						/>
						<ColumnCenter>
							<Plus size="16" color={theme.text2} />
						</ColumnCenter>
						<CurrencyInputPanel
							value={formattedAmounts[Field.CURRENCY_B]}
							onUserInput={onFieldBInput}
							onCurrencySelect={handleCurrencyBSelect}
							onMax={() => {
								onFieldBInput(maxAmounts[Field.CURRENCY_B]?.toExact() ?? "");
							}}
							showMaxButton={!atMaxAmounts[Field.CURRENCY_B]}
							currency={currencies[Field.CURRENCY_B] ?? null}
							id="add-liquidity-input-tokenb"
							showCommonBases
						/>
						{currencies[Field.CURRENCY_A] &&
							currencies[Field.CURRENCY_B] &&
							pairState !== PairState.INVALID && (
								<>
									<LightCard padding="0px" $borderRadius={"20px"}>
										<RowBetween padding="1rem">
											<TYPE.subHeader fontWeight={500} fontSize={14}>
												{noLiquidity ? (
													<Trans>Initial prices and pool share</Trans>
												) : (
													<Trans>Prices and pool share</Trans>
												)}
											</TYPE.subHeader>
										</RowBetween>{" "}
										<LightCard padding="1rem" $borderRadius={"20px"} noBorder>
											<PoolPriceBar
												currencies={currencies}
												poolTokenPercentage={poolTokenPercentage}
												noLiquidity={noLiquidity}
												price={price}
											/>
										</LightCard>
									</LightCard>
								</>
							)}

						<div style={{ margin: "0 0.5rem" }}>
							<RowBetween>
								<Text fontSize={14} fontWeight={400}>
									Enforce slippage (~$0.075 Fee)
								</Text>

								<Toggle
									isActive={enforceSlippage}
									toggle={() => setEnforceSlippage(!enforceSlippage)}
								/>
							</RowBetween>
						</div>

						{addIsUnsupported ? (
							<ButtonPrimary disabled={true}>
								<TYPE.main mb="4px">
									<Trans>Unsupported Asset</Trans>
								</TYPE.main>
							</ButtonPrimary>
						) : !account ? (
							<ButtonLight onClick={toggleWalletModal}>
								<Trans>Connect Wallet</Trans>
							</ButtonLight>
						) : (
							<AutoColumn gap={"md"}>
								{(approvalA === ApprovalState.NOT_APPROVED ||
									approvalA === ApprovalState.PENDING ||
									approvalB === ApprovalState.NOT_APPROVED ||
									approvalB === ApprovalState.PENDING) &&
									isValid && (
										<RowBetween>
											{approvalA !== ApprovalState.APPROVED && (
												<ButtonPrimary
													onClick={approveACallback}
													disabled={approvalA === ApprovalState.PENDING}
													width={
														approvalB !== ApprovalState.APPROVED
															? "48%"
															: "100%"
													}
												>
													{approvalA === ApprovalState.PENDING ? (
														<Dots>
															<Trans>
																Approving {currencies[Field.CURRENCY_A]?.symbol}
															</Trans>
														</Dots>
													) : (
														<Trans>
															Approve {currencies[Field.CURRENCY_A]?.symbol}
														</Trans>
													)}
												</ButtonPrimary>
											)}
											{approvalB !== ApprovalState.APPROVED && (
												<ButtonPrimary
													onClick={approveBCallback}
													disabled={approvalB === ApprovalState.PENDING}
													width={
														approvalA !== ApprovalState.APPROVED
															? "48%"
															: "100%"
													}
												>
													{approvalB === ApprovalState.PENDING ? (
														<Dots>
															<Trans>
																Approving {currencies[Field.CURRENCY_B]?.symbol}
															</Trans>
														</Dots>
													) : (
														<Trans>
															Approve {currencies[Field.CURRENCY_B]?.symbol}
														</Trans>
													)}
												</ButtonPrimary>
											)}
										</RowBetween>
									)}
								<ButtonError
									onClick={() => {
										expertMode ? onAdd() : setShowConfirm(true);
									}}
									disabled={
										!isValid ||
										approvalA !== ApprovalState.APPROVED ||
										approvalB !== ApprovalState.APPROVED
									}
									error={
										!isValid &&
										!!parsedAmounts[Field.CURRENCY_A] &&
										!!parsedAmounts[Field.CURRENCY_B]
									}
								>
									<Text fontSize={20} fontWeight={500}>
										{error ?? <Trans>Supply</Trans>}
									</Text>
								</ButtonError>
							</AutoColumn>
						)}
					</AutoColumn>
				</Wrapper>
			</AppBody>
			<SwitchLocaleLink />

			{!addIsUnsupported ? (
				pair && !noLiquidity && pairState !== PairState.INVALID ? (
					<AutoColumn
						style={{
							minWidth: "20rem",
							width: "100%",
							maxWidth: "400px",
							marginTop: "1rem",
						}}
					>
						<MinimalPositionCard
							showUnwrapped={oneCurrencyIsWETH}
							pair={pair}
						/>
					</AutoColumn>
				) : null
			) : (
				<UnsupportedCurrencyFooter
					show={addIsUnsupported}
					currencies={[currencies.CURRENCY_A, currencies.CURRENCY_B]}
				/>
			)}
		</>
	);
}
