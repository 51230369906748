import useSWR from "swr";

const fetcher = (...args: [any, any | undefined]) =>
	fetch(...args).then((res) => res.json());

export const useCoinGecko = (
	path: string,
	params?: {
		[key: string]: string | number | boolean;
	}
) =>
	useSWR(
		`https://api.coingecko.com/api/v3${path}${
			params
				? `?${Object.keys(params)
						.map((key) => `${key}=${params[key]}`)
						.join("&")}`
				: ""
		}`,
		fetcher,
		{
			refreshInterval: 15 * 1000,
			revalidateOnFocus: true,
		}
	);
