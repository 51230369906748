import { PropsWithChildren, useEffect, useMemo, useRef, useState } from "react";
import { Text } from "rebass";
import styled, { css } from "styled-components";
import { ExternalLink } from "theme";

import zipswap from "../../assets/whitepaper/zipswap.png";
import uniswap2 from "../../assets/whitepaper/uniswap2.png";
import ethTransfer from "../../assets/whitepaper/eth_transfer.png";
import Modal from "components/Modal";
import Card from "components/Card";
import useTheme from "hooks/useTheme";
import { RowBetween } from "components/Row";
import { ButtonSecondary, ButtonPrimary } from "components/Button";
import { useOutsideAlerter } from "hooks/useOutsideAlerter";
import { HashLink as Link } from "react-router-hash-link";
import { ArrowUp, Menu } from "react-feather";
import { getAllChildrenFlat } from "utils/getAllChildrenFlat";

const headerOffset = 88;
const accepted = "abcdefghijklmnopqrstuvwxyz0123456789-".split("");

const convert = new Map([
	[".", "-"],
	[" ", "-"],
]);

const toId = (str: string) =>
	str
		.toLowerCase()
		.split("")
		.map((a) => (convert.has(a) ? convert.get(a)! : a) || "")
		.filter((a) => accepted.includes(a))
		.join("")
		.split("---")
		.join("-")
		.split("--")
		.join("-");

const B = () => (
	<>
		<br />
		<br />
	</>
);

const scroll = (el: HTMLElement) => {
	const elementPosition = el.getBoundingClientRect().top;
	const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

	window.scrollTo({
		top: offsetPosition,
		behavior: "smooth",
	});
};

const PageWrapper = styled.div`
	max-width: 780px;
	width: 100%;

	${({ theme }) => theme.mediaWidth.upToMedium`
    max-width: 480px;
  `};
`;

const Image = styled.img<{ noMargin?: boolean }>`
	width: 100%;
	margin-bottom: ${({ noMargin }) => (noMargin ? 0 : 0.25)}rem;
	padding: ${({ noMargin }) => (noMargin ? "" : "1rem")};
	background: ${({ theme, noMargin }) => (noMargin ? "" : "white")};
	border-radius: 1rem;
	box-shadow: ${({ noMargin }) =>
		noMargin
			? ""
			: `0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04),
		0px 16px 24px rgba(0, 0, 0, 0.04), 0px 24px 32px rgba(0, 0, 0, 0.01)`};
`;

const Title = ({ title, major }: { title: string; major?: boolean }) => {
	const id = toId(title);
	const theme = useTheme();

	return (
		<Text
			fontSize={major ? 24 : 20}
			fontWeight={600}
			marginBottom={2}
			style={{
				cursor: "pointer",
				textDecoration: "none",
			}}
			id={id}
		>
			<Link
				to={`/whitepaper#${id}`}
				smooth
				scroll={scroll}
				style={{
					color: theme.text1,
					textDecoration: "none",
				}}
			>
				{title}
			</Link>
		</Text>
	);
};

const Paragraph = ({
	title,
	major,
	children,
}: PropsWithChildren<{ title: string; major?: boolean }>) => (
	<>
		<Title title={title} major={major} />

		<Text fontSize={16} fontWeight={400} marginBottom={4}>
			{children}
		</Text>
	</>
);

const ImageWithLink = ({
	title,
	link,
	linkName,
	image,
}: {
	title: string;
	link: string;
	linkName: string;
	image: string;
}) => {
	const modalRef = useRef(null);
	const [open, setOpen] = useState(false);
	const theme = useTheme();

	useOutsideAlerter(modalRef, () => {
		setOpen(false);
	});

	return (
		<>
			<Modal isOpen={open} onDismiss={() => {}} card={false}>
				<ModalContainer>
					<ModalVertical>
						<ModalContent>
							<ImageHolder>
								<Card bg={theme.bg0} ref={modalRef}>
									<RowBetween marginBottom={3}>
										<Text fontSize={24} fontWeight={600}>
											{title}
										</Text>

										<ButtonSecondary
											width="auto"
											onClick={() => {
												setOpen(false);
											}}
										>
											Close
										</ButtonSecondary>
									</RowBetween>

									<Image src={image} noMargin />
								</Card>
							</ImageHolder>
						</ModalContent>
					</ModalVertical>
				</ModalContainer>
			</Modal>

			<Text fontSize={18} fontWeight={600} marginBottom={2}>
				{title}
			</Text>

			<Image
				src={image}
				onClick={() => {
					setOpen(true);
				}}
				style={{
					cursor: "zoom-in",
				}}
			/>

			<ExternalLink style={{ textDecoration: "underline" }} href={link}>
				<Text fontSize={14} fontWeight={400} marginBottom={4}>
					{linkName}
				</Text>
			</ExternalLink>
		</>
	);
};

const ModalContainer = styled.div`
	position: fixed;
	inset: 0px;
`;

const ImageHolder = styled.div`
	max-width: 800px;

	@media (min-width: 1200px) {
		max-width: 1000px;
	}

	@media (min-width: 1600px) {
		max-width: 1400px;
	}

	padding: 1rem;
`;

const ModalContent = styled.div`
	display: flex;
	justify-content: center;
`;

const ModalVertical = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100vw;
	height: 100vh;
`;

const LiquidityTable = styled.table`
	border-radius: 1rem;
	background: ${({ theme }) => theme.bg0};
	padding: 1rem;
	border-collapse: separate;
	border-spacing: 0.5rem;
	box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04),
		0px 16px 24px rgba(0, 0, 0, 0.04), 0px 24px 32px rgba(0, 0, 0, 0.01);
`;

const TopButton = styled(ButtonPrimary)`
	display: none;
	position: fixed;
	bottom: 3rem;
	right: 1rem;
	z-index: 99;
	border-radius: 99999px;
	padding: 0rem;
	width: 50px;
	height: 50px;

	background: ${({ theme }) => theme.bg4};
	box-shadow: none;

	&:hover,
	&:active,
	&:focus {
		background: ${({ theme }) => theme.bg5};
		box-shadow: none;
	}
`;

const PageContentWrapper = styled.div<{ open: boolean }>`
	position: fixed;
	width: 300px;

	@media (min-width: 1550px) {
		left: calc((100vw - 780px) / 2 + 780px + 50px);
		opacity: 0.7;

		padding: 0.75rem;
		padding-right: 0;
		padding-left: 0.75rem;
		border-left: 2px solid ${({ theme }) => theme.text3};

		& #menu-button {
			display: none;
		}
	}

	@media (max-width: 1549px) {
		bottom: 7rem;
		left: 1rem;

		@media (max-width: 960px) {
			bottom: 5.5rem;
		}

		${({ open }) =>
			open &&
			css`
				padding: 1rem;
				border-radius: 0.75rem;
				background: ${({ theme }) => theme.bg0};
				box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01),
					0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
					0px 24px 32px rgba(0, 0, 0, 0.01);
			`}

		& * {
			display: ${({ open }) => (open ? "block" : "none")};
		}

		& #menu-button {
			display: block;
			position: absolute;
			bottom: -3.5rem;
			left: 0;

			width: 46px;
			height: 46px;

			& * {
				display: block;
			}
		}
	}
`;

const PageContentText = styled(Text)<{ major: boolean }>`
	margin-left: ${({ major }) => (major ? "0" : "1")}rem;
	font-size: ${({ major }) => (major ? "16" : "15")}px;
	font-weight: ${({ major }) => (major ? "500" : "500")};
	color: ${({ major, theme }) => (major ? theme.text1 : theme.text2)};
	cursor: pointer;
`;

const PageContent = ({ titles }: { titles: [string, boolean][] }) => {
	const [open, setOpen] = useState(false);

	const theme = useTheme();

	const ids = useMemo(() => titles.map(([title]) => toId(title)), [titles]);

	return (
		<PageContentWrapper open={open}>
			<ButtonSecondary
				id="menu-button"
				width="auto"
				onClick={() => {
					setOpen(!open);
				}}
			>
				<Menu color={theme.text1} />
			</ButtonSecondary>

			{titles.map(([title, major], i) => (
				<PageContentText
					major={major}
					key={title}
					marginTop={(i === 0 && "0") || (major ? "0.75rem" : "0.2rem")}
				>
					<Link
						to={`/whitepaper#${ids[i]}`}
						smooth
						scroll={scroll}
						style={{
							color: major ? theme.text1 : theme.text2,
							textDecoration: "none",
						}}
					>
						{title}
					</Link>
				</PageContentText>
			))}
		</PageContentWrapper>
	);
};

export default function Whitepaper() {
	useEffect(() => {
		const topButton = document.getElementById("toTop");

		const onLoad = () => {
			const hashes = window.location.href.split("#");

			if (hashes.length < 3) return;

			const hash = hashes[hashes.length - 1];

			let offsetPosition = 0;

			if (hash !== "top") {
				const el = document.getElementById(hash);

				if (!el) return;

				const elementPosition = el.getBoundingClientRect().top;
				offsetPosition = elementPosition + window.pageYOffset - headerOffset;
			}

			window.scrollTo({
				top: offsetPosition,
				behavior: "smooth",
			});
		};

		const onScroll = () => {
			if (!topButton) return;

			if (
				document.body.scrollTop > 20 ||
				document.documentElement.scrollTop > 20
			) {
				topButton.style.display = "block";
			} else {
				topButton.style.display = "none";
			}
		};

		window.addEventListener("load", onLoad);
		window.addEventListener("scroll", onScroll);

		return () => {
			window.removeEventListener("load", onLoad);
			window.removeEventListener("scroll", onScroll);
		};
	});

	const content = useMemo(
		() => (
			<>
				<Paragraph title="1. What's ZipSwap?" major>
					Zipswap is a more gas efficient, optimistic rollup native swap. Based
					on a modified UniswapV2 codebase, the goal of ZipSwap is to provide
					users with lowest fee possible on optimistic rollups.
				</Paragraph>

				<Paragraph title="2. Technical explanation" major>
					All modifications were designed to reduce the byte size of users'
					transactions by compressing needed data. Optimistic rollups scale
					ethereum by putting entire transactions on-chain, but not executing
					them except in the case of fraud, making size the most important fee
					determining factor. As of now, each non-zero byte costs 16 gas, while
					a zero costs 4. At 150 gwei and $4300 ETH, that means each non-zero
					byte costs about $0.01. Example swap for same amount of USDC to ETH:
					<div style={{ height: "1.5rem" }} />
					<ImageWithLink
						title="ZipSwap"
						link="https://optimistic.etherscan.io/tx/0xe58ebaf55a2b0da9507eb5afcecee024ed68d376390b6cad87646d6ea14c8e21"
						linkName="Optimistic Etherscan"
						image={zipswap}
					/>
					<ImageWithLink
						title="UniswapV2/Sushi"
						link="https://optimistic.etherscan.io/tx/0xe5062bd05609a0fcbbeae2090f04220e9ebcf8c959713aec0fd6a128fbbfac31"
						linkName="Optimistic Etherscan"
						image={uniswap2}
					/>
					ZipSwap used 2 bytes: 1 non-zero and 1 zero, for the total cost of 20
					gas. Default UniswapV2 call used 260 bytes: 51 non-zero and 209 zero,
					for the total cost of 1652 gas. Therefore, in this case the argument
					part itself is cheaper 82.6x times.
					<B /> Real fee savings are smaller - as transactions also include
					signatures, origin address, nonce and other standard fields. There's
					also an additional fee overhead that depends on a particular rollup
					implementation and negligible computation fee. At the end, the total
					fee for a standard swap is $2.34, while fee for the ZipSwap version is
					$1.65, a difference of 29.5%. This scales with the current ethereum
					gas price, and increases for more complex swaps. <B /> At the same gas
					price, fee for a direct ETH transfer was $1.69 - which is more than
					the swap fee. ZipSwap achieved maximum gas efficiency possible on
					optimistic rollups.
					<div style={{ height: "1.5rem" }} />
					<ImageWithLink
						title="Normal ETH transfer"
						link="https://optimistic.etherscan.io/tx/0x41d26b2557ac316cbdd6e989e20c501decda3d223a260aecfb807bbde6c5a50d"
						linkName="Optimistic Etherscan"
						image={ethTransfer}
					/>
					If EIP-4488 passes, relative fee advantage (for the calldata portion)
					of ZipSwap over the standard implementation should increase
					significantly - because EIP-4488 sets calldata cost to a fixed 3 gas
					per byte, whether zero or non-zero. As can be seen on the screenshot,
					most of bytes in the uncompressed swap data are zeros - increasing gas
					savings of the argument part itself to 65x.
				</Paragraph>

				<Paragraph title="2.1. How was this achieved?">
					More detailed technical details for those curious how this works.
					<B />
					Standard Solidity ABI uses 4 bytes for the function signature. As
					there are only 16 needed functions, this was reduced to 4 bits. For
					the relatively common case of selling max balance of a token, instead
					of encoding the full amount, a single bit with the meaning of 'use
					maximum balance' is used. Because there's currently no frontrunning on
					Arbitrum and Optimism, slippage can be made optional - which in the
					case of no slippage only takes one bit. Additionally, 'to' field (a
					very rarely used option that allows to send the output tokens/etc to
					another address) and 'deadline' field (not necessary on rollups due to
					instant execution) were made optional, taking two bits. In the maximum
					case, this replaces 132 bytes (4+32*4) with just 1 byte. In the
					previous image, that's the meaning of the 0x0B byte.
					<B />
					The path part (ie. what tokens are swapped and in what order), which
					by default uses token addresses for each swap step, is instead encoded
					as run-length encoded pool ids. In the screenshoted transaction,
					that's the last byte - 0, for the first pool (ETH-USDC). Amounts are
					run-length encoded while optimized for manual input: a human is more
					likely to input a nice round decimal number, like 0.1, which are
					encoded with maximum efficiency - that's the meaning of "0501" bytes.
					The most likely source of non-round inputs is selling the entire
					balance - which is covered by the one-bit isMax flag. Another
					optimization which saves fees was made by preapproving pool tokens for
					the standard router - removing the need for pointless token approvals
					(or calldata expensive signed permits) while removing liquidity.
				</Paragraph>

				<Title title="3. Development plans" major />

				<div style={{ height: "0.5rem" }} />

				<Paragraph title="3.1. Stablecoin pools">
					Low slippage and low fee pool type suitable for swaps of stablecoins
					and other mutually pegged tokens.
				</Paragraph>

				<Paragraph
					title={`3.2. Guaranteed liquidity pool (see section "4.4 Token value guarantee")`}
				>
					It will provide buy-side liquidity and a guaranteed price minimum
					without the need for a paired liquidity token. Protocol-owned ZIP-ETH
					pool will be moved to this type, once available. All protocol-owned
					unsold ZIP tokens in the liquidity pair are going to be burned.
				</Paragraph>

				<Paragraph title="3.3. Potential additions">
					Liquidity ranges, known from UniswapV3. Due to complexity, feasibility
					to be determined after adding two previous pool types. <B />
					In order for these upgrades to be possible without requiring liquidity
					migration, the main router contract will be placed on a 7 day
					timelock. Once done, the upgrade possibility can be disabled forever.
				</Paragraph>

				<Title title="4. The ZIP Token" major />

				<div style={{ height: "0.5rem" }} />

				<Paragraph title="4.1. ZIP token supply">
					<LiquidityTable>
						<tr>
							<td>Initial supply (circulating+vesting):</td>
							<td> 100M</td>
						</tr>
						<tr>
							<td>Dev share</td>
							<td>
								14M (1 month cliff, vested linearly for 5 months after that)
							</td>
						</tr>
						<tr>
							<td>Presale</td>
							<td>
								1M for marketing and other initial expenses (1 month cliff,
								vested linearly for 2 months after that)
							</td>
						</tr>
						<tr>
							<td>Treasury</td>
							<td>20M</td>
						</tr>
						<tr>
							<td>Farming rewards to NYAN</td>
							<td>10M (farming contract on Optimism over 3 months)</td>
						</tr>
						<tr>
							<td>Tokens distributed to initial liquidity providers</td>
							<td>55M</td>
						</tr>
					</LiquidityTable>
					<br />
					Liquidity incentives will come from the Treasury portion according to
					the "minimum necessary" principle. A technical possibility of
					inflation will remain (to be controlled by the DAO).
					<B />
					Clarification: there was an additional 52,185,413.12 ZIP minted for
					the ZIP-ETH pool. In the future a new pool type will be added that
					only provides buy support at the introduction price, and integrates
					the minimum price guarantee directly (see section 4.3 and 4.4). When
					it's introduced, unsold ZIPs in the liquidity pair will be burned. If
					price during the update is higher than the initial price,
					protocol-owned ZIP-ETH will have less ZIP tokens than during the
					minting, therefore, less than 52M ZIP will be burned. If price ever
					drops back to the initial they will be burned by the pool, but if that
					never happens, the circulating supply after the upgrade may remain
					higher than 100M.
				</Paragraph>

				<Paragraph title="4.2. Value accrual">
					After the initial liquidity acquisition period, 1/6 of trading fees
					from standard Uniswap2 pools will be collected by the protocol. Other
					pool types may have different fee tiers. <B />
					There are two possible value capture models. The choice between the
					two will be determined by vote of ZIP holders.
				</Paragraph>

				<Paragraph title="4.2.1. veZIP - Locked token model">
					Fees are distributed to locked ZIP tokens - veZIP. veZIP are created
					after locking ZIP for a set period of time. Users locking ZIP for
					longer periods of time will receive more veZIP tokens. veZIP holders
					are the only ones with a voting power in the DAO - governing pool
					rewards, treasury, and other decisions.
				</Paragraph>

				<Paragraph title="4.2.2. Token burn model">
					Fees are periodically sold for ZIP, the bought ZIP is burned. Along
					with the minimum redemption value mechanism this guarantees a
					perpetually rising price floor (in the absence of inflation). This
					model also has potential tax advantages - as in many countries getting
					staking income creates a tax event. <B />
					The DAO mechanism will be added some time after the launch.
				</Paragraph>

				<Paragraph title="4.3. Distribution method">
					Zipswap token is going to be distributed using an Initial Liquidity
					Provision mechanic, with a redemption option at a minimum guaranteed
					price. After the distribution ends and the ZIP-ETH liquidity pool is
					funded, ZipSwap liquidity incentives will start. <B />
					<strong>
						Initial Liquidity Provision starts January 11, 2022 at 10:00 EST and
						ends January 18, 2022 at 10:00 EST.
					</strong>
					<br />
					Tokens will be distributed to initial liquidity providers after the
					ILO ends.
					<B />
					Early liquidity providers get a bonus incentive of 10%, decaying
					linearly from start to finish. Providing 1 ETH at the beginning is
					equivalent to providing 1.1 ETH at the end.
					<B />
					Initial Liquidity Provision will be done exclusively in ETH. All
					acquired ETH will be used to fund the protocol owned ZIP-ETH pool on
					ZipSwap, at the highest individual ZIP price achieved during the ILO -
					one set by the last ILO participant.
					<B />
					Due to the logic of AMM, this ZIP supply can only enter circulation if
					market price of ZIP is higher than the initial price. This is
					currently the best way to provide liquidity due to the logic of UniV2
					pools, however, in the future a special pool type may be added that
					only provides buy support without the token liquidity portion. Once
					the update is done, the unsold portion of ZIP tokens owned by the
					liquidity pool will be burned. <B />
					Minimum total collected amount: 250 ETH. In the case this value isn't
					met, received eth will be returned to would-be initial liquidity
					providers and token distribution won't take place.
				</Paragraph>

				<Paragraph title="4.4. Token value guarantee">
					Any ZIP token holder will be able to redeem ZIP token at the minimum
					guaranteed price, calculated using the total supply. In comparison to
					a simpler locked liquidity model without redemption, it significantly
					reduces risk - as pure AMM function ensures a significant fraction of
					ETH locked in the pair can't ever be transferred out - becoming
					functionally useless. Immediately after the ILO, the minimum
					guaranteed price is going to be between 44.4% and 55.3%, relative to
					the individual ILO price of participants.
					<br />
					Due to the 10% time bonus, the individual percentage depends on the
					relation between the ZIP price paid by the individual relative to
					others - with those in before others getting a slightly better price.
				</Paragraph>

				<Paragraph title="4.5. Details on how token value guarantee works">
					Minimum guaranteed price is calculated as if enough ZIP tokens were
					sold to bring the ZIP token balance of the pool to half of the entire
					supply, including the liquidity portion.
					<B />
					Example numbers - without the 10% time bonus.
					<br />
					Total ZIP supply = 100M outside of the pool + 55M in the pool. <br />
					Pool contains 55 ETH, ie. 1M ZIP = 1 ETH. <br />
					Simulate sale of 155M/2-55M = 22.5M (using getAmountOut function for
					UniswapV2 pools) - amount at which external token supply equals supply
					in the pool. <br />
					A virtual sale would yield 15.9337 ETH, leaving 55 ETH-15.9337 ETH =
					39.066 ETH in the pool, along with 77.5M ZIP. <br />
					As exactly the same ZIP balance exists externally to the pool, it's
					possible to redeem all external tokens at this fixed price point:
					<br />
					1. withdraw all protocol owned liquidity (39.066 ETH and 77.5M ZIP)
					<br />
					2. burn the liquidity portion (77.5M ZIP). <br />
					3. exchange 39.066ETH for external 77.5M ZIP. <br />
					4. burn external 77.5M ZIP. <B />
					Same logic works for redeeming smaller amounts - it's not possible for
					the minimum price to ever go lower. <br />
					This means the maximum theoretically possibe loss for initial
					liquidity providers is 50.4%: <br />
					Initial price per 1M ZIP: 1 ETH <br />
					Eth remaining in the pool when supply in the pool equals supply
					outside of the pool: 39.066 ETH. <br />
					Price during minimum redemption per 1M ZIP: 39.066 ETH/77.5M ZIP =
					0.50408 ETH <br />
					A loss of 0.49592 ETH per 1 ETH. <B />
					Note that this is an external mechanism to the DEX logic itself, so in
					the first pool version, it's possible for someone to sell ZIP too low
					- the contracts themselves won't stop it. However, it means it becomes
					profitable to rebalance the pool and burn excess tokens:
					<br />
					1. same initial pool: 55 ETH-55M ZIP.
					<br />
					2. someone sells 60M ZIP into the pool, getting 28.6544 ETH.
					<br />
					Pool now contains 115M ZIP and 26.3455 ETH. Total external supply is
					155M ZIP - 115M ZIP = 40M ZIP.
					<br />
					3. liquidity is withdrawn. 115M-40M = 75M ZIP is burned.
					<br />
					4. liquidity is provided again: remaining 40M ZIP and 26.3455 ETH.
					<br />
					It's now possible to redeem the remaining external 40M ZIP at the
					price of 0.6586ETH per 1M ZIP - which is a gain compared to the
					previous example.
					<br />
					Therefore, the minimum guaranteed price for a rational user is 50.4%.{" "}
					<B />
					For a comparison, a simple locked liquidity model is only able to
					guarantee a minimum price of 12.57% (for the last sold ZIP). After
					selling the entire ZIP supply into the pool completely useless 19.5539
					ETH would still remain. Capital efficiency of initial liquidity is
					thus greatly increased.
				</Paragraph>
			</>
		),
		[]
	);

	const children = useMemo(() => getAllChildrenFlat(content), [content]);

	const titles = useMemo(
		() =>
			children
				.filter(({ props }) => Boolean(props.title))
				.map(({ props }) => props.title! as string)
				.filter((title) => /^([0-9]{1,})/.test(title))
				.map<[string, boolean]>((title) => [title, /^([0-9]. )/.test(title)]),
		[children]
	);

	return (
		<>
			<TopButton
				id="toTop"
				onClick={() => {
					window.scrollTo({
						top: 0,
						behavior: "smooth",
					});
				}}
				width="auto"
			>
				<ArrowUp color="white" size={24} />
			</TopButton>

			<PageWrapper>
				<PageContent titles={titles} />

				{content}
			</PageWrapper>
		</>
	);
}
