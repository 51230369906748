export const getAllChildrenFlat = (elem: JSX.Element): JSX.Element[] => {
	return [
		elem,
		...[elem.props?.children ?? []]
			.flat(2)
			.map(getAllChildrenFlat)
			.flat(1)
			.filter((item) => typeof item !== "string"),
	];
};
