import styled from "styled-components";

import CBridgeLogo from "assets/bridges/cbridge.png";
import LayerSwapLogo from "assets/bridges/layerswap.png";
import XPollinateLogo from "assets/bridges/xpollinate.png";
import HopLogo from "assets/bridges/hop.png";
import OptimismLogo from "assets/bridges/optimism.png";
import AcrossLogo from "assets/bridges/across.png";
import TeleportrLogo from "assets/bridges/teleportr.png";
import SynapseLogo from "assets/bridges/synapse.png";
import Card from "components/Card";
import useTheme from "hooks/useTheme";
import { Flex, Text } from "rebass";

const bridges = [
	{
		name: "Celer Bridge",
		url: "https://cbridge.celer.network/#/transfer",
		logo: CBridgeLogo,
		description:
			"You can use Celer's bridge to transfer assets between Optimism and other Ethereum networks (L1, other rollups, and side chains).",
	},
	{
		name: "Hop Exchange",
		url: "https://app.hop.exchange/send?sourceNetwork=ethereum&destNetwork=optimism&token=ETH",
		logo: HopLogo,
		description:
			"Hop allows you to quickly bridge assets between L1 and various L2 solutions.",
	},
	{
		name: "Synapse",
		url: "https://synapseprotocol.com/?inputCurrency=ETH&outputCurrency=ETH&outputChain=10",
		logo: SynapseLogo,
		description:
			"Synapse is a cross-chain layer ∞ protocol powering frictionless interoperability between blockchains.",
	},
	{
		name: "XPollinate",
		url: "https://xpollinate.io/",
		logo: XPollinateLogo,
		description: "Transfer token between chains using NXTP.",
	},
	{
		name: "Optimism Bridge",
		url: "https://app.optimism.io/bridge",
		logo: OptimismLogo,
		description:
			"The Optimism Bridge allows you to transfer assets between L1 and Optimistic Ethereum.",
	},
	{
		name: "Across",
		url: "https://across.to/?referrer=0x8602EE2f8AaEb671E409b26d48E36DD8Cc3B7ED7",
		logo: AcrossLogo,
		description: "Instantly Send Assets from Layer 2 Rollups to Ethereum.",
	},
	{
		name: "LayerSwap",
		url: "https://layerswap.io/",
		logo: LayerSwapLogo,
		description:
			"Save 10x on fees when moving crypto from Coinbase, Binance, Huobi or FTX US to Optimism.",
		centralized: true,
	},
	{
		name: "Teleportr",
		url: "https://portr.xyz/",
		logo: TeleportrLogo,
		description:
			"Teleport ETH to Optimism with Teleportr for a fraction of the price.",
		centralized: true,
		ethOnly: true,
	},
];

const PageWrapper = styled.div`
	max-width: 900px;
	width: 100%;

	@media (max-width: 1150px) {
		max-width: calc(600px - 1rem);
	}

	@media (max-width: 650px) {
		max-width: 400px;
	}
`;

const GridHolder = styled.div`
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	gap: 1rem;

	@media (max-width: 1150px) {
		grid-template-columns: 1fr 1fr;
	}

	@media (max-width: 650px) {
		grid-template-columns: 1fr;
	}
`;

const BridgeCard = styled(Card)`
	transition-property: transform, border;
	transition-duration: 0.175s;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	border-size: border-box;
	border: 2px solid ${({ theme }) => theme.bg2};

	cursor: pointer;
	user-select: none;

	& * {
		cursor: pointer;
		user-select: none;
	}

	&:hover {
		transform: translateY(-0.5rem);
		border: 2px solid ${({ theme }) => theme.primary2};
	}
`;

const BridgeLogoHolder = styled.div<{ logo: string }>`
	width: 2.5rem;
	height: 2.5rem;
	margin-right: 0.75rem;
	border-radius: 0.75rem;
	background-image: url(${({ logo }) => logo});
	background-position-x: 0px;
	background-position-y: 0px;
	background-size: cover;
`;

const Tag = styled(Text)`
	padding: 0.2rem 0.4rem;
	background: ${({ theme }) => theme.primary2};
	border-radius: 99999px;
`;

export default function Bridge() {
	const theme = useTheme();

	return (
		<PageWrapper>
			<Text
				fontSize={40}
				fontWeight={600}
				textAlign="center"
				marginBottom="2rem"
			>
				Available Optimism Bridges
			</Text>

			<GridHolder>
				{bridges.map(
					({ name, logo, url, description, centralized, ethOnly }) => (
						<BridgeCard
							key={name}
							backgroundColor={theme.bg0}
							padding="1rem"
							onClick={() => {
								window.open(url);
							}}
						>
							<Flex>
								<BridgeLogoHolder logo={logo} />

								<div>
									<Text fontSize={18} fontWeight={600}>
										{name}
									</Text>

									<Text fontSize={15} fontWeight={400} color={theme.primary3}>
										{url.split("/")[2]}
									</Text>
								</div>
							</Flex>

							<Text fontSize={15} fontWeight={500} marginTop="0.75rem">
								{description}
							</Text>

							{(centralized || ethOnly) && (
								<Flex marginTop="0.75rem" style={{ gap: "0.5rem" }}>
									{[centralized && "Centralized", ethOnly && "Only ETH"].map(
										(item) =>
											item && (
												<Tag fontSize={14} fontWeight={500} color="white">
													{item}
												</Tag>
											)
									)}
								</Flex>
							)}
						</BridgeCard>
					)
				)}
			</GridHolder>
		</PageWrapper>
	);
}
